import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { IncomeTypeDataModel } from '../../common/income-type-node/data-model';
import { IncomeTypeNodeImpl } from '../../common/income-type-node/element';
import { incomeTypePredicate } from '../../common/income-type-node/predicate';

export const purchaseIncomeTypeNodeId = 'primary-income-type';

export const PurchaseIncomeTypeNode = () => {
  const props = useNodeProperties<IncomeTypeDataModel>(
    purchaseIncomeTypeNodeId,
    incomeTypePredicate
  );
  return (
    <Loader>
      <IncomeTypeNodeImpl {...props} />
    </Loader>
  );
};
