import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { infoModalOpenedAction } from '../../../../store/events/actions';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Feedback } from '../../../shared/typography/feedback';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { OccupancyTypeDataModel } from './data-model';
import { OccupancyTypeHint } from './hint';
import { getOccupancyFeedback } from './occupancy-type-node-helper';

export interface OccupancyTypeNodeProps extends NodeProperties<OccupancyTypeDataModel> {
  subheaderText: string;
}

export const OccupancyTypeNodeImpl = ({
  dataModel: {
    read: { occupancyTypes },
    write: { occupancyType }
  },
  subheaderText,
  onChangeFactory,
  dispatch,
  questionnaireType,
  splitClient,
  ...props
}: OccupancyTypeNodeProps) => {
  const changeWrapper = onChangeFactory<string>('occupancyType', props.nodeId);
  const handleOccupancyTypeChange = useCallback(
    (_: any, newValue?: string) => changeWrapper(newValue, undefined),
    [changeWrapper]
  );

  const occupancyOptions = useMemo(
    () =>
      occupancyTypes.map(({ label, value }) => (
        <InputWrapper key={value} size="single">
          <OwnUpRadioButton value={value}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [occupancyTypes]
  );

  const renderLightbulbContainer = useMemo(() => {
    return !!occupancyType ? <Feedback>{getOccupancyFeedback(occupancyType)}</Feedback> : null;
  }, [occupancyType]);

  const clickInfoModal = useCallback(() => {
    dispatch(infoModalOpenedAction({ nodeId: props.nodeId }));
  }, [dispatch, props.nodeId]);

  return (
    <NodeContainer {...props}>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question hint={<OccupancyTypeHint onClick={clickInfoModal} />}>{subheaderText}</Question>
        <OwnUpRadioGroup
          name="Occupancy Type"
          value={occupancyType}
          onChange={handleOccupancyTypeChange}
        >
          <InputGroupWrapper>
            <StandaloneErrorWrapper predicateResult={radioButtonPredicate(occupancyType)} />
            {occupancyOptions}
          </InputGroupWrapper>
        </OwnUpRadioGroup>
        {renderLightbulbContainer}
      </NodeForm>
    </NodeContainer>
  );
};
