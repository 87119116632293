import { acceptedOfferConsultationNextStepsNodeId } from './consultation-next-steps-node';
import { acceptedOfferNextStepsNodeId } from './next-steps-node';

/**
 * List of nodes that won't display the sidebar.
 */
export const acceptedOfferFullWidthNodes = [
  acceptedOfferNextStepsNodeId,
  acceptedOfferConsultationNextStepsNodeId
];
