import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { PurchasePriceDataModel } from '../../common/purchase-price-node/data-model';
import { PurchasePriceNodeImpl } from '../../common/purchase-price-node/element';
import { purchasePricePredicate } from '../../common/purchase-price-node/predicate';

export const acceptedOfferPurchasePriceNodeId = 'purchase-price';

export const AcceptedOfferPurchasePriceNode = () => {
  const props = useNodeProperties<PurchasePriceDataModel>(
    acceptedOfferPurchasePriceNodeId,
    purchasePricePredicate
  );

  return (
    <Loader>
      <PurchasePriceNodeImpl {...props} />
    </Loader>
  );
};
