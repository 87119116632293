import {
  OwnUpGridContainer,
  OwnUpGridContainerProps,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import { useCombinedClassName } from '@rategravity/own-up-component-library/hooks/use-combined-class-name';
import React from 'react';
import styled from 'styled-components';

/**
 * Wrapper around {@see OwnUpGridContainerProps}
 */
export interface InputGroupWrapperProps
  extends Omit<OwnUpGridContainerProps, 'spacing' | 'variant'> {}

const InputGroupWrapperImpl = ({ className, ...props }: InputGroupWrapperProps) => {
  const fullClassName = useCombinedClassName('input-group', className);
  return <OwnUpGridContainer variant="slim" spacing={0} className={fullClassName} {...props} />;
};

/**
 * 6px padding results in 12px padding around each item
 */
export const INPUT_GROUP_ITEM_PADDING = '0.375rem';

/**
 * Wrapper around {@see OwnUpGridContainer} that will provide dynamic
 *   margins based on its surrounding context.
 *
 * @param props - {@see InputWrapperProps}
 */
export const InputGroupWrapper = styled(InputGroupWrapperImpl)<PropsWithTheme<{}>>`
  & {
    /* Negative margins are applied to this wrapper to offset the padding around
     *  each grid item. This mimics Mui's built-in grid functionality which we
     *  can't exactly leverage here due to the sizing not conforming to the
     *  presets Mui has built-in (all integer spacing values.) */
    margin: -${INPUT_GROUP_ITEM_PADDING};
    width: calc(100% + (${INPUT_GROUP_ITEM_PADDING} * 2));

    /* 32px minus the needed offset */
    margin-top: calc(2rem - ${INPUT_GROUP_ITEM_PADDING});

    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
      /* 24px minus the needed offset */
      margin-top: calc(1.5rem - ${INPUT_GROUP_ITEM_PADDING});
    }

    .MuiInputBase-root {
      /* Remove built-in margins */
      margin: 0;
    }

    /* Manually set padding for grid items. This would normally be done
     *   with the spacing attribute but because the desired value is not
     *   a whole number Mui doesn't have presets for it. */
    & > .MuiGrid-item {
      padding: ${INPUT_GROUP_ITEM_PADDING};
    }

    .MuiFormControlLabel-root {
      width: 100%;
      max-width: unset;
    }
  }
`;
