import * as Sentry from '@sentry/react';
import { sendTo1pt } from './1pt';

export type JornayaResponse = {
  /**
   * The Id if one is found.
   */
  id: string | undefined;
  /**
   * If the id has changed from previously recorded session values.
   */
  updated: boolean;
};

const JORNAYA_LEAD_ID_KEY = 'OU_JORNAYA_LEAD_ID';
const JORNAYA_CALLBACK_FN = 'OU_JORNAYA_CALLBACK_FN';

/**
 * @returns the Jornaya lead id after mounting the jornaya script
 */
const mountJornayaScript = (): Promise<string> =>
  new Promise((res) => {
    (window as any)[JORNAYA_CALLBACK_FN] = (token: string) => {
      res(token);
      // cleanup after load
      delete (window as any)[JORNAYA_CALLBACK_FN];
    };

    // Add Jornaya to the page
    const jornayaScript = document.createElement('script');
    jornayaScript.id = 'LeadiDscript_campaign';
    jornayaScript.type = 'text/javascript';
    jornayaScript.async = true;
    jornayaScript.src = `//create.lidstatic.com/campaign/f2500bdd-6f4c-3e5f-d7a6-e61a3728b6de.js?snippet_version=2&callback=${JORNAYA_CALLBACK_FN}`;
    document.body.appendChild(jornayaScript);
  });

/**
 * Gets the Jornaya Lead Id
 * from Jornaya and attaches the Jornaya script to the page
 */
export const getJornayaId = async (): Promise<JornayaResponse> => {
  const existingId = sessionStorage.getItem(JORNAYA_LEAD_ID_KEY);
  const id = await Promise.race([
    mountJornayaScript(),
    new Promise<string | null>((res) => {
      const t = setTimeout(() => {
        res(existingId);
        clearTimeout(t);
      }, 5000);
    })
  ]);
  const updated = id === existingId;
  if (id != null) {
    sessionStorage.setItem(JORNAYA_LEAD_ID_KEY, id);
    sendTo1pt({ method: 'track', event: 'Onboarding_JornayaId_Set' });
  } else {
    Sentry.captureException(new Error('Failed to find Jornaya id'));
    sendTo1pt({ method: 'track', event: 'Onboarding_Jornaya_Failed' });
  }

  return {
    id: id == null ? undefined : id,
    updated
  };
};
