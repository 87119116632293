import { createAction, createSlice } from '@reduxjs/toolkit';
import { defaultDisplayState } from './state';

/**
 * Indicate that we've successfully created the questionnaire.
 */
export const showErrorAction = createAction<{
  headline?: string;
  message?: string;
  backButtonUrl?: string;
  backButtonText?: string;
}>('display/showError');

export type ShowErrorAction = ReturnType<typeof showErrorAction>;

/**
 * Indicates whether or not the user has an incomplete questionnaire.
 */
export const showIncompleteQuestionnairePageAction = createAction<{
  oldQuestionnaireType?: string;
}>('display/showIncompleteQuestionnairePage');

export type ShowIncompleteQuestionnairePageAction = ReturnType<
  typeof showIncompleteQuestionnairePageAction
>;

/**
 * Handles the display domain. It exposes actions and a reducer for handling
 * toggle summary overlay actions.
 */
const displaySlice = createSlice({
  name: 'display',
  initialState: defaultDisplayState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(showErrorAction, (state, { payload }: ShowErrorAction) => ({
        ...state,
        error: { ...payload }
      }))
      .addCase(
        showIncompleteQuestionnairePageAction,
        (state, { payload }: ShowIncompleteQuestionnairePageAction) => ({
          ...state,
          hasIncompleteQuestionnaire: true,
          oldQuestionnaireType: payload.oldQuestionnaireType
        })
      );
  }
});

export const { reducer: displayReducer } = displaySlice;
