import React, { useCallback, useMemo, useRef, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { AdornedTextInput } from '../../../components/shared/inputs/adorned-text-input';
import { AddressModel, formattedPlace, FullAddressProps } from '../../../modules/address-helpers';
import { googleMapsApiKey } from '../../../modules/api-keys';
import { InputGroupWrapper } from '../form/inputs/input-group-wrapper';
import { City, State, Zip } from './address-components';

// Address component that includes street name(address), unit #, city, state and zip.
export const FullAddress = ({
  addressModel = {},
  id,
  states,
  onCityChange,
  onStateChange,
  onAddressChange,
  onUnitChange,
  onZipChange,
  onAutocompleteChange,
  ...props
}: FullAddressProps) => {
  const stateOptions = useMemo(
    () => states.map(({ label, value }) => ({ display: label, value })),
    [states]
  );

  // state variable used to track if the address form is currently being autofilled
  const [autofilling, setAutofilling] = useState(false);

  const handlePropertyFullAddressChange = useCallback(
    (newValue: AddressModel) => {
      // set 'autofilling' to true since address form is currently being autofilled
      setAutofilling(true);

      onAutocompleteChange?.(newValue);

      // set 'autofilling' to false after a slight delay so that if anymore changes
      // are made to the address, we know that the user made the changes manually
      setTimeout(() => setAutofilling(false), 500);
    },
    [onAutocompleteChange]
  );

  /* This function will call the change handler that is passed to it and it will also
     blank out the county value when the field associated with the change handler is updated manually 
  */
  const handlePropertyFieldChange = useCallback(
    (field: string, originalFunction?: (newValue: string | undefined) => void) =>
      (newValue: string | undefined) => {
        const { county } = addressModel;
        // only update the county if it's not already blank, otherwise the application state doesn't update correctly
        if (!autofilling && county !== '') {
          // passing empty strings or undefined doesn't seem to update the model properly,
          // so instead of using empty strings, the values are set to a string that contains
          // a single space as a (hacky) workaround
          onAutocompleteChange({
            ...addressModel,
            [field]: newValue || ' ',
            county: ''
          });
        } else {
          originalFunction?.(newValue);
        }
      },
    [autofilling, addressModel, onAutocompleteChange]
  );

  const onCityCurry = handlePropertyFieldChange('city', onCityChange);
  const onStateCurry = handlePropertyFieldChange('state', onStateChange);
  const onZipCurry = handlePropertyFieldChange('zip', onZipChange);

  // The callback used in onPlaceSelected below won't be updated, so need to
  // pass in a ref object update its current instance with the memoized onAutocomplete callback
  const onAutocompleteRef = useRef((_: AddressModel) => {
    return;
  });
  const onAutocomplete = useCallback(
    (address: AddressModel) => {
      const selectedStateObj = stateOptions.find((option) => option.value === address.state);
      const selectedState = selectedStateObj ? selectedStateObj.value : undefined;
      handlePropertyFullAddressChange({ ...address, state: selectedState });
    },
    [handlePropertyFullAddressChange, stateOptions]
  );
  onAutocompleteRef.current = onAutocomplete;

  const { ref: materialRef } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place: google.maps.places.PlaceResult) => {
      if (place.address_components) {
        const address = formattedPlace(place);
        onAutocompleteRef.current(address);
      }
    },
    inputAutocompleteValue: 'country',
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' }
    }
  });

  const { showAddressError, showCityError, showStateError, showZipError } = useMemo(
    () => ({
      showAddressError: addressModel.address !== undefined,
      showCityError: addressModel.city !== undefined && addressModel.city?.trim(),
      showStateError: addressModel.state !== undefined,
      showZipError: addressModel.zip !== undefined && addressModel.zip.trim().length === 5
    }),
    [addressModel]
  );
  return (
    <InputGroupWrapper>
      <AdornedTextInput
        wrapperSize="half"
        label="Street address"
        className="fs-mask private"
        value={addressModel.address}
        id={`${id}Address`}
        textInputPredicate={showAddressError}
        errorText={'Please enter an address.'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onAddressChange?.(e.target.value)}
        inputRef={materialRef}
        placeholder=""
        {...props}
      />
      <AdornedTextInput
        wrapperSize="half"
        label="Unit #"
        value={addressModel.unit}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onUnitChange?.(e.target.value)}
        {...props}
      />
      <City model={addressModel} error={!!showCityError} onChange={onCityCurry} {...props} />
      <State
        states={stateOptions}
        model={addressModel}
        error={showStateError}
        onChange={onStateCurry}
        {...props}
      />
      <Zip model={addressModel} error={showZipError} onChange={onZipCurry} {...props} />
    </InputGroupWrapper>
  );
};
