import { createAction } from '@reduxjs/toolkit';
import { ErrorPayload } from '../../modules/segment/types';

/**
 * Dispatch a request to the backend to receive the user's
 *   questionnaire data.
 *
 * UserId and QuestionnaireId will be pulled from redux store.
 */
export const fetchQuestionnaireRequestAction = createAction<{ nodeId?: string }>(
  'questionnaire/fetchRequest'
);

export type FetchQuestionnaireRequestAction = ReturnType<typeof fetchQuestionnaireRequestAction>;

/**
 * Indicate that we've successfully fetched the questionnaire.
 */
export const fetchQuestionnaireSuccessAction = createAction<{
  path: string[];
  dataModels?: Record<string, Record<string, unknown>>;
  percentComplete?: number;
  isComplete?: boolean;
}>('questionnaire/fetchSuccess');

export type FetchQuestionnaireSuccessAction = ReturnType<typeof fetchQuestionnaireSuccessAction>;

export const fetchQuestionnaireFailureAction = createAction<ErrorPayload>(
  'questionnaire/fetchFailure'
);
export type FetchQuestionnaireFailureAction = ReturnType<typeof fetchQuestionnaireFailureAction>;
