import { createSelector } from 'reselect';
import { AppState } from '..';

/**
 * Get the Questionnaire State from the root state
 */
export const questionnaireStateSelector = (state: AppState) => state?.questionnaire;

/**
 * Get the loading state from the questionnaire
 */
export const questionnaireLoadingSelector = createSelector(
  questionnaireStateSelector,
  (model) => model.loading
);

/**
 * Get the User Id from the Questionnaire Model
 */
export const userIdSelector = createSelector(questionnaireStateSelector, (model) => model?.userId);

/**
 * Get the Questionnaire Id from the Questionnaire Model
 */
export const questionnaireIdSelector = createSelector(
  questionnaireStateSelector,
  (model) => model?.questionnaireId
);

/**
 * Get the Questionnaire Type from the Questionnaire Model
 */
export const questionnaireTypeSelector = createSelector(
  questionnaireStateSelector,
  (model) => model?.questionnaireType
);

/**
 * Get the percent complete for the questionnaire
 */
export const percentCompleteSelector = createSelector(
  questionnaireStateSelector,
  (model) => model?.percentComplete
);

/**
 * Get Node Complete from questionnaire
 */
export const nodeCompleteSelector = createSelector(
  questionnaireStateSelector,
  (model) => model?.isComplete
);
