import {
  emailPredicate,
  namePredicate,
  phonePredicate
} from '../../../../modules/shared-predicates';
import { ConfirmContactInfoDataModel } from './data-model';

export const confirmContactInfoPredicate = (model: ConfirmContactInfoDataModel) =>
  emailPredicate(model) &&
  phonePredicate(model.write.phoneNumber as string) &&
  namePredicate(model.write.name as string);
