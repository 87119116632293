const getHexId = (length: number): string => {
  const bytes = new Uint8Array(length);
  crypto.getRandomValues(bytes);
  let hex = '';
  for (let i = 0; i < bytes.length; i++) {
    hex += bytes[i].toString(16);
  }
  return hex.substring(0, length);
};

const getHexTime = (): string => Math.round(new Date().getTime() / 1000).toString(16);

// Generate a trace ID that is compatible with both Sentry and AWS X-Ray
export const SENTRY_TRACE_ID = getHexTime() + getHexId(24);
export const XRAY_TRACE_ID = `1-${SENTRY_TRACE_ID.slice(0, 8)}-${SENTRY_TRACE_ID.slice(8)}`;
