import React, { useCallback } from 'react';
import {
  emailPredicate,
  namePredicate,
  phonePredicate
} from '../../../../modules/shared-predicates';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { AdornedNumberInput } from '../../../shared/inputs/adorned-number-input';
import { AdornedTextInput } from '../../../shared/inputs/adorned-text-input';
import { Headline } from '../../../shared/typography/headline';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { ConfirmContactInfoDataModel } from './data-model';

interface ConfirmContactInfoNodeProps extends NodeProperties<ConfirmContactInfoDataModel> {}

export const ConfirmContactInfoNodeImpl = ({
  dataModel: {
    write: { name, email, phoneNumber }
  },
  onChangeFactory,
  onSubmit,
  dispatch,
  nodeId,
  questionnaireType,
  ...props
}: ConfirmContactInfoNodeProps) => {
  const handleChange = useCallback(
    (field: 'email' | 'phoneNumber' | 'name') => (value: string) => {
      onChangeFactory<string>(field, nodeId)(value, undefined);
    },
    [onChangeFactory, nodeId]
  );

  return (
    <NodeContainer {...props}>
      <NodeForm nodeId={nodeId} onSubmit={onSubmit} {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Headline>Please double check your information.</Headline>
        <Question>
          It looks like something might not be right. Please double check your information and try
          again.
        </Question>
        <InputGroupWrapper>
          <AdornedTextInput
            wrapperSize="single"
            label="First and last name"
            className="fs-mask private"
            value={name}
            textInputPredicate={namePredicate(name as string)}
            errorText="Please enter a valid name."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange('name')(e.target.value)
            }
          />
          <AdornedTextInput
            wrapperSize="single"
            type="email"
            label="Email address"
            className="fs-mask private"
            value={email}
            textInputPredicate={emailPredicate({ write: { email } })}
            errorText="Please enter a valid email address."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange('email')(e.target.value)
            }
          />
          <AdornedNumberInput
            wrapperSize="single"
            type="tel"
            label="Phone number"
            className="fs-mask private"
            value={phoneNumber as string | undefined}
            numberInputPredicate={phonePredicate(phoneNumber as string)}
            errorText="Please provide a valid phone number."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange('phoneNumber')(e.target.value);
            }}
            placeholder="(___) ___-____"
            allowNegative={false}
            decimalScale={0}
            allowLeadingZeros={true}
            mask="_"
            format="###-###-####"
          />
        </InputGroupWrapper>
      </NodeForm>
    </NodeContainer>
  );
};
