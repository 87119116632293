import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { FthbDataModel } from '../../common/fthb-node/data-model';
import { FthbNodeImpl } from '../../common/fthb-node/element';
import { fthbPredicate } from '../../common/fthb-node/predicate';

export const purchasePrimaryBorrowerFthbNodeId = 'primary-borrower-fthb';

export const PurchasePrimaryBorrowerFthbNode = () => {
  const props = useNodeProperties<FthbDataModel>(purchasePrimaryBorrowerFthbNodeId, fthbPredicate);

  return (
    <Loader>
      <FthbNodeImpl firstPerson={true} {...props} />
    </Loader>
  );
};
