export interface DisplayState {
  error?: {
    headline?: string;
    message?: string;
    backButtonUrl?: string;
    backButtonText?: string;
  };
  hasIncompleteQuestionnaire?: boolean;
  oldQuestionnaireType?: string;
}

export const defaultDisplayState: DisplayState = {
  error: undefined,
  hasIncompleteQuestionnaire: false,
  oldQuestionnaireType: undefined
};
