import { preapprovalConsultationNextStepsNodeId } from './consultation-next-steps-node';
import { preapprovalNextStepsNodeId } from './next-steps-node';

/**
 * List of nodes that won't display the sidebar.
 */
export const preapprovalFullWidthNodes = [
  preapprovalNextStepsNodeId,
  preapprovalConsultationNextStepsNodeId
];
