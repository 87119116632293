import { useEffect, useState } from 'react';
import { DecodedToken, getDecodedLoginToken } from '../modules/authentication';

/**
 * Note: the login token may not be set immediately on page load, this
 * should be checked after the page has loaded.
 */
export const useDeconstructedLoginToken = () => {
  const [decodedToken, setDecodedToken] = useState<DecodedToken>({});
  useEffect(() => {
    void (async () => {
      const decoded = await getDecodedLoginToken();
      setDecodedToken(decoded);
    })();
  }, []);
  return decodedToken;
};
