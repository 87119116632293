import { currency } from '@rategravity/frontend/modules/numbers';
import {
  OwnUpBody,
  OwnUpIcon,
  OwnUpRadioButton,
  OwnUpRadioGroup
} from '@rategravity/own-up-component-library';
import * as colors from '@rategravity/own-up-component-library/colors';
import { EducationIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/education';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import styled from 'styled-components';
import {
  downPaymentInputPredicate,
  radioButtonPredicate
} from '../../../../modules/shared-predicates';
import { ONBOARDING_RANGES_TEST } from '../../../../modules/split';
import { infoModalOpenedAction, sendToTrackingEventAction } from '../../../../store/events/actions';
import { NodeContainer } from '../../../shared/container';
import { NodeIndent } from '../../../shared/containers/node-indent';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { DownPaymentInput } from '../../../shared/inputs/down-payment-input';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { DownPaymentDataModel } from './data-model';
import {
  getDownPayment,
  getMinDownPaymentLabelMap,
  getMinDownPaymentPercent
} from './helper-functions';
import { DownPaymentHint } from './hint';

const EducationWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

interface IsGiftComponentProps {
  isDownPaymentGift?: boolean | null;
  downpaymentGiftOptions: Array<{ display: string; value: boolean }>;
  isDownPaymentGiftChangeHandler: (_: any, newValue?: string) => void;
}

export const downPaymentPercentageCalculator = (downPayment?: number, purchasePrice?: number) =>
  purchasePrice && downPayment ? +((downPayment / purchasePrice) * 100).toFixed(2) : undefined;

const IsGiftComponent = (props: IsGiftComponentProps) => {
  const { isDownPaymentGift, isDownPaymentGiftChangeHandler } = props;
  return (
    <NodeIndent>
      <Question>Is any of the down payment coming from a gift?</Question>
      <OwnUpRadioGroup value={isDownPaymentGift} onChange={isDownPaymentGiftChangeHandler}>
        <InputGroupWrapper>
          <StandaloneErrorWrapper predicateResult={radioButtonPredicate(isDownPaymentGift)} />
          <InputWrapper size="single">
            <OwnUpRadioButton value={true}>Yes</OwnUpRadioButton>
          </InputWrapper>
          <InputWrapper size="single">
            <OwnUpRadioButton value={false}>No</OwnUpRadioButton>
          </InputWrapper>
        </InputGroupWrapper>
      </OwnUpRadioGroup>
    </NodeIndent>
  );
};

export interface DownPaymentNodeProps extends NodeProperties<DownPaymentDataModel> {
  sliderOverride?: boolean;
}

export const DownPaymentNodeImpl = ({
  dataModel: {
    write: { downPayment, isDownPaymentGift },
    read: { occupancyType, purchasePrice, minReqDownPaymentPercentage, options }
  },
  onChangeFactory,
  dispatch,
  splitClient,
  questionnaireType,
  onSubmit,
  sliderOverride,
  ...props
}: DownPaymentNodeProps) => {
  const { [ONBOARDING_RANGES_TEST]: sliderTreatment } = splitClient.getTreatments(
    [ONBOARDING_RANGES_TEST],
    {
      questionnaireType: questionnaireType || 'undefined'
    }
  );
  const useSliderTreatment = sliderOverride || sliderTreatment === 'on';

  const useRangeConfig = questionnaireType !== 'accepted-offer';
  const [downPaymentChanged, setDownPaymentChanged] = useState(false);
  const [downPaymentPercentage, setPercentValue] = useState<number | undefined>(
    downPaymentPercentageCalculator(downPayment, purchasePrice)
  );
  const [lastInputChanged, setLastInputChanged] = useState<'numeric' | 'percent' | undefined>(
    undefined
  );

  const percentRef = useRef<HTMLInputElement>(null);
  const numericRef = useRef<HTMLInputElement>(null);
  const separatedPercentRef = useRef<HTMLInputElement>(null);
  const separatedNumericRef = useRef<HTMLInputElement>(null);

  const downPaymentChangeDispatcher = onChangeFactory<number>('downPayment', props.nodeId);
  const downPaymentMinChangeDispatcher = onChangeFactory<number | undefined>(
    'downPaymentMin',
    props.nodeId
  );
  const downPaymentMaxChangeDispatcher = onChangeFactory<number | undefined>(
    'downPaymentMax',
    props.nodeId
  );

  const isDownPaymentGiftChangeDispatcher = onChangeFactory<boolean>(
    'isDownPaymentGift',
    props.nodeId
  );

  const handleIsDownPaymentGiftChange = useCallback(
    (_: any, newValue?: string) => {
      isDownPaymentGiftChangeDispatcher(newValue === 'true', undefined);
    },
    [isDownPaymentGiftChangeDispatcher]
  );

  const isDownpaymentGiftOptions = useMemo(
    () => options.map(({ label, value }) => ({ display: label, value })),
    [options]
  );

  const selectedIsDownPaymentGiftOption = useMemo(
    () => (typeof isDownPaymentGift === 'boolean' ? isDownPaymentGift : null),
    [isDownPaymentGift]
  );

  const renderIsDownPaymentGiftQuestion = useMemo(() => {
    if (occupancyType === 'InvestmentProperty') {
      return (
        <IsGiftComponent
          isDownPaymentGift={selectedIsDownPaymentGiftOption}
          downpaymentGiftOptions={isDownpaymentGiftOptions}
          isDownPaymentGiftChangeHandler={handleIsDownPaymentGiftChange}
        />
      );
    }
  }, [
    occupancyType,
    isDownpaymentGiftOptions,
    handleIsDownPaymentGiftChange,
    selectedIsDownPaymentGiftOption
  ]);

  const mainText = useMemo(
    () =>
      `How much do you plan to put down on your ${
        purchasePrice && !useSliderTreatment ? currency(purchasePrice, 0) : ''
      } property?`,
    [purchasePrice, useSliderTreatment]
  );

  const numericValueChangeFn = useCallback(
    (floatValue?: number) => {
      const percentValue =
        floatValue === 0 ? 0 : downPaymentPercentageCalculator(floatValue, purchasePrice);
      const newDownPayment = !!percentValue && percentValue > 100 ? purchasePrice : floatValue;
      const newPercentValue = !!percentValue && percentValue > 100 ? 100 : percentValue;
      if (!downPaymentChanged) {
        setDownPaymentChanged(true);
      }
      setPercentValue(Number(newPercentValue));
      downPaymentChangeDispatcher(Number(newDownPayment), undefined);
      // min and max are the same for numeric input (no ranges)
      downPaymentMinChangeDispatcher(Number(newDownPayment), undefined);
      downPaymentMaxChangeDispatcher(Number(newDownPayment), undefined);
    },
    [
      purchasePrice,
      downPaymentChangeDispatcher,
      downPaymentMinChangeDispatcher,
      downPaymentMaxChangeDispatcher,
      setDownPaymentChanged,
      downPaymentChanged,
      setPercentValue
    ]
  );

  const onNumericValueChange = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      if (document.activeElement === numericRef?.current) {
        numericValueChangeFn(floatValue);
        setLastInputChanged('numeric');
      }
    },
    [numericValueChangeFn, setLastInputChanged]
  );

  const onSeparatedNumericValueChange = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      if (document.activeElement === separatedNumericRef?.current) {
        numericValueChangeFn(floatValue);
        setLastInputChanged('numeric');
      }
    },
    [numericValueChangeFn, setLastInputChanged]
  );

  const percentValueChangeFn = useCallback(
    (floatValue?: number, range?: boolean) => {
      const newPercentValue = floatValue && floatValue > 100 ? 100 : floatValue;
      let newDownPayment = undefined;
      if (newPercentValue === 0) {
        // Force zero(0) down payment
        newDownPayment = 0;
      } else if (purchasePrice && newPercentValue && newPercentValue > 0) {
        newDownPayment = getDownPayment(purchasePrice, newPercentValue);
      }
      if (!downPaymentChanged) {
        setDownPaymentChanged(true);
      }
      setPercentValue(Number(newPercentValue));
      downPaymentChangeDispatcher(Number(newDownPayment), undefined);
      downPaymentMinChangeDispatcher(
        range && purchasePrice && newPercentValue !== undefined
          ? getDownPayment(
              purchasePrice,
              getMinDownPaymentPercent(newPercentValue, minReqDownPaymentPercentage)
            )
          : Number(newDownPayment), // if no range, min and max are the same
        undefined
      );
      downPaymentMaxChangeDispatcher(Number(newDownPayment), undefined);
    },
    [
      purchasePrice,
      downPaymentChangeDispatcher,
      downPaymentMinChangeDispatcher,
      downPaymentMaxChangeDispatcher,
      setDownPaymentChanged,
      downPaymentChanged,
      minReqDownPaymentPercentage
    ]
  );

  const onPercentValueChange = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      if (document.activeElement === percentRef?.current) {
        percentValueChangeFn(floatValue);
        setLastInputChanged('percent');
      }
    },
    [percentValueChangeFn, setLastInputChanged]
  );

  const onPercentRangeChange = (_: any, newValue?: string) => {
    if (newValue) {
      percentValueChangeFn(Number(newValue) * 100, true);
    }
  };

  const onSeparatedPercentValueChange = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      if (document.activeElement === separatedPercentRef?.current) {
        percentValueChangeFn(floatValue);
        setLastInputChanged('percent');
      }
    },
    [percentValueChangeFn, setLastInputChanged]
  );

  const errorText = useMemo(() => {
    if (
      downPayment &&
      minReqDownPaymentPercentage > 0 &&
      downPayment < Math.floor(minReqDownPaymentPercentage * (purchasePrice || 0))
    ) {
      return 'Please enter a down payment that meets the minimum requirement.';
    } else {
      return 'Please enter a valid down payment.';
    }
  }, [downPayment, minReqDownPaymentPercentage, purchasePrice]);

  const minDownPayment = useMemo(() => {
    if (purchasePrice) {
      return minReqDownPaymentPercentage * purchasePrice;
    } else {
      return undefined;
    }
  }, [purchasePrice, minReqDownPaymentPercentage]);

  const clickInfoModal = useCallback(() => {
    dispatch(infoModalOpenedAction({ nodeId: props.nodeId }));
  }, [dispatch, props.nodeId]);

  const onSubmitInvalidDownPayment = useCallback(() => {
    if (Math.floor(minDownPayment || 0) > (downPayment || 0)) {
      dispatch(
        sendToTrackingEventAction({
          method: 'track',
          event: 'user_tried_to_continue_with_invalid_down_payment',
          properties: {
            questionnaireType,
            minDownPayment,
            downPayment
          }
        })
      );
    }
    dispatch(
      sendToTrackingEventAction({
        method: 'track',
        event: `user_last_interact_${lastInputChanged}_input`,
        properties: {
          questionnaireType
        }
      })
    );
    onSubmit();
  }, [dispatch, downPayment, questionnaireType, minDownPayment, lastInputChanged, onSubmit]);

  const minDownPaymentLabelMap = useMemo(
    () => getMinDownPaymentLabelMap({ minReqDownPaymentPercentage, purchasePrice }),
    [purchasePrice, minReqDownPaymentPercentage]
  );

  const radioButtonOptions = minDownPaymentLabelMap.map(({ value, label }, i) => (
    <InputWrapper size="single" key={i}>
      <OwnUpRadioButton value={value}>{label}</OwnUpRadioButton>
    </InputWrapper>
  ));

  return (
    <NodeContainer>
      <NodeForm {...props} onSubmit={onSubmitInvalidDownPayment}>
        <Overline>{props.sectionTitle}</Overline>
        <Question hint={<DownPaymentHint onClick={clickInfoModal} />}>{mainText}</Question>
        {minReqDownPaymentPercentage > 0 ? (
          <EducationWrapper>
            <OwnUpIcon
              height="1.5rem"
              color={colors.ALOE_100}
              icon={<EducationIcon />}
              style={{ marginRight: '1rem' }}
            />
            <OwnUpBody variant="body1">
              For your scenario, the minimum down payment is{' '}
              {minDownPayment ? `${currency(minDownPayment, 0)} or ` : ''}
              {+(minReqDownPaymentPercentage * 100).toFixed(1)}%.{' '}
            </OwnUpBody>
          </EducationWrapper>
        ) : null}
        {useRangeConfig ? (
          <OwnUpRadioGroup
            name="Down payment ranges"
            value={(downPaymentPercentage || 0) / 100}
            onChange={onPercentRangeChange}
          >
            <InputGroupWrapper>
              <StandaloneErrorWrapper predicateResult={radioButtonPredicate(downPayment)} />
              {radioButtonOptions}
            </InputGroupWrapper>
          </OwnUpRadioGroup>
        ) : (
          <DownPaymentInput
            id="Down payment input"
            errorText={errorText}
            downPaymentInputPredicate={downPaymentInputPredicate(
              downPayment,
              downPaymentPercentage,
              minDownPayment
            )}
            onNumericChange={onNumericValueChange}
            onPercentChange={onPercentValueChange}
            onSeparatedPercentChange={onSeparatedPercentValueChange}
            onSeparatedNumericChange={onSeparatedNumericValueChange}
            numericValue={downPayment?.toString()}
            percentValue={downPaymentPercentage?.toString()}
            numericRef={numericRef}
            percentRef={percentRef}
            separatedPercentRef={separatedPercentRef}
            separatedNumericRef={separatedNumericRef}
          />
        )}
        {renderIsDownPaymentGiftQuestion}
      </NodeForm>
    </NodeContainer>
  );
};
