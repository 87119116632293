import { ApproximateCreditScoreDataModel } from './data-model';

export const approximateCreditScorePredicate = ({
  read: { hasCoborrower },
  write: { primaryBorrowerApproximateCreditScore, coborrowerApproximateCreditScore }
}: ApproximateCreditScoreDataModel) => {
  return (
    !!primaryBorrowerApproximateCreditScore &&
    (!hasCoborrower || coborrowerApproximateCreditScore !== undefined)
  );
};
