import { OwnUpFillButtonPrimary, OwnUpLineButton } from '@rategravity/own-up-component-library';
import React, { Dispatch, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RouteParams } from '../../modules/path-helpers';
import { sendToTrackingEventAction } from '../../store/events/actions';
import { NodeContainer } from '../shared/container';
import { ButtonContainer, DesktopButtons, MobileButtons } from '../shared/form/node-form';
import { Description } from '../shared/typography/description';
import { Headline } from '../shared/typography/headline';
import { Overline } from '../shared/typography/overline';

export const START_NEW_QUESTIONNAIRE_TOKEN = 'START_NEW_QUESTIONNAIRE_TOKEN';

export const IncompleteQuestionnairePageImpl = ({
  oldQuestionnaireType,
  newQuestionnaireType,
  dispatch
}: {
  oldQuestionnaireType?: string;
  newQuestionnaireType?: string;
  dispatch: Dispatch<any>;
}) => {
  useEffect(() => {
    dispatch(
      sendToTrackingEventAction({
        method: 'track',
        event: 'onboarding_incomplete_warning',
        properties: {
          inProgressQuestionnaireType: oldQuestionnaireType,
          newQuestionnaireType
        }
      })
    );
  });

  const startOverButton = useMemo(
    () => (
      <OwnUpLineButton
        onClick={() => {
          // Indicate the user wants to start a new questionnaire and refresh the page
          localStorage.setItem(START_NEW_QUESTIONNAIRE_TOKEN, 'true');
          dispatch(
            sendToTrackingEventAction({
              method: 'track',
              event: 'onboarding_incomplete_restart',
              properties: {
                newQuestionnaireType
              }
            })
          );
          setTimeout(() => window.location.reload(), 1000);
        }}
      >
        Start over
      </OwnUpLineButton>
    ),
    [newQuestionnaireType, dispatch]
  );
  const continueButton = useMemo(() => {
    const url = window.location.href;
    const continueOldQuestionnaireUrl =
      url.substring(0, url.lastIndexOf('/') + 1) + oldQuestionnaireType;
    return (
      <OwnUpFillButtonPrimary
        onClick={() => {
          dispatch(
            sendToTrackingEventAction({
              method: 'track',
              event: 'onboarding_incomplete_continue',
              properties: {
                incompleteQuestionnaireType: oldQuestionnaireType
              }
            })
          );
          setTimeout(() => window.location.replace(continueOldQuestionnaireUrl), 1000);
        }}
      >
        Continue
      </OwnUpFillButtonPrimary>
    );
  }, [oldQuestionnaireType, dispatch]);
  return (
    <NodeContainer>
      <Overline>Welcome back</Overline>
      <Headline>{`You have a${oldQuestionnaireType === 'accepted-offer' ? 'n' : ''} ${
        oldQuestionnaireType ? oldQuestionnaireType : 'different'
      } questionnaire in progress!`}</Headline>
      <Description>Would you like to pick up where you left off?</Description>
      <ButtonContainer>
        <DesktopButtons>
          {startOverButton}
          {continueButton}
        </DesktopButtons>
        <MobileButtons>
          {continueButton}
          {startOverButton}
        </MobileButtons>
      </ButtonContainer>
    </NodeContainer>
  );
};

export const IncompleteQuestionnairePage = ({
  oldQuestionnaireType
}: {
  oldQuestionnaireType?: string;
}) => {
  const dispatch = useDispatch();
  const { questionnaireType } = useParams<RouteParams>();
  return (
    <IncompleteQuestionnairePageImpl
      oldQuestionnaireType={oldQuestionnaireType}
      newQuestionnaireType={questionnaireType}
      dispatch={dispatch}
    />
  );
};
