import React, { useCallback } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { DollarInput } from '../../../../components/shared/inputs/dollar-input';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { ONBOARDING_RANGES_TEST } from '../../../../modules/split';
import { DataModel } from '../../../../store/data-models/slice';
import { Loader, NodeContainer } from '../../../shared/container';
import { NodeForm } from '../../../shared/form/node-form';
import { SliderComponent } from '../../../shared/inputs/slider';
import { Description } from '../../../shared/typography/description';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';

export interface PropertyValueDataModel extends DataModel {
  write: {
    propertyValue?: number;
    propertyValueMin?: number;
    propertyValueMax?: number;
  };
}

export interface PropertyValueNodeProperties extends NodeProperties<PropertyValueDataModel> {
  sliderOverride?: boolean;
}

export const propertyValuePredicate = (dataModel: PropertyValueDataModel) =>
  !!dataModel.write.propertyValue && dataModel.write.propertyValue > 0;

export const propertyValueNodeId = 'property-value';
export const PropertyValueNodeImpl = ({
  dataModel: {
    write: { propertyValue }
  },
  onChangeFactory,
  questionnaireType,
  splitClient,
  sliderOverride,
  ...props
}: PropertyValueNodeProperties) => {
  const sliderTreatment = splitClient.getTreatment(ONBOARDING_RANGES_TEST, {
    questionnaireType: questionnaireType || 'undefined'
  });
  const useSliderTreatment = sliderOverride || sliderTreatment === 'on';

  const handleChange = onChangeFactory<number>('propertyValue', propertyValueNodeId);
  const handlePropertyValueMinChange = onChangeFactory<number>('propertyValueMin', props.nodeId);
  const handlePropertyValueMaxChange = onChangeFactory<number>('propertyValueMax', props.nodeId);

  const handlePropertyValueRangeChange = useCallback(
    (value: number[]) => {
      handleChange(value[1], undefined);
      handlePropertyValueMinChange(value[0], undefined);
      handlePropertyValueMaxChange(value[1], undefined);
    },
    [handleChange, handlePropertyValueMinChange, handlePropertyValueMaxChange]
  );

  const handleChangeWrapper = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      handleChange(floatValue, undefined);
      // min and max are the same for numeric input (no ranges)
      handlePropertyValueMinChange(floatValue, undefined);
      handlePropertyValueMaxChange(floatValue, undefined);
    },
    [handleChange, handlePropertyValueMinChange, handlePropertyValueMaxChange]
  );

  const headerTextOverride = useSliderTreatment
    ? `What is the price range of the property you're looking to refinance?`
    : `What's your estimated property value?`;

  return (
    <NodeContainer {...props}>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>{headerTextOverride}</Question>
        <Description>
          Most lenders will require an official appraisal to confirm this estimate. We&apos;ll
          include all fees in your quotes for easy comparison.
        </Description>
        {useSliderTreatment ? (
          <SliderComponent
            handleChange={handlePropertyValueRangeChange}
            defaultValue={propertyValue}
          />
        ) : (
          <DollarInput
            id="Property value"
            label="Property value"
            value={propertyValue}
            name="PropertyValue"
            decimalScale={0}
            errorText={'Please enter a valid property value.'}
            dollarInputPredicate={propertyValuePredicate({ write: { propertyValue } })}
            onValueChange={handleChangeWrapper}
          />
        )}
      </NodeForm>
    </NodeContainer>
  );
};

export const PropertyValueNode = () => {
  const props = useNodeProperties<PropertyValueDataModel>(
    propertyValueNodeId,
    propertyValuePredicate
  );
  return (
    <Loader>
      <PropertyValueNodeImpl {...props} />
    </Loader>
  );
};
