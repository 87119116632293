import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { NewConstructionDataModel } from './data-model';
import { NewConstructionNodeImpl } from './element';
import { newConstructionPredicate } from './predicate';

export const acceptedOfferNewConstructionNodeId = 'new-construction';
export const AcceptedOfferNewConstructionNode = () => {
  const props = useNodeProperties<NewConstructionDataModel>(
    acceptedOfferNewConstructionNodeId,
    newConstructionPredicate
  );

  return (
    <Loader>
      <NewConstructionNodeImpl {...props} />
    </Loader>
  );
};
