import {
  CACTUS_100,
  CHARCOAL_100,
  FOG_100,
  monostenCMixin,
  OwnUpSmallBodyMedium,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { NextStepsDataModel } from '../next-steps-node/data-model';
import { AdvisorImage } from './advisor-image';
import { NextStepsBookingButton } from './next-steps-booking-button';
import { useAdvisors } from './next-steps-helpers';

export type NextStepsYcbmPitchProps = Pick<NextStepsDataModel['read'], 'advisor' | 'state'> & {
  ycbmLink: string;
};

const Container = styled.div<{ $extraRightSpacing: boolean }>`
  ${monostenCMixin}
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    align-items: flex-start;
  }

  > .ou-advisor-stack {
    flex-shrink: 0;
    height: 48px;
    /* images overlap by 10px, this give space for the '+1' placeholder 48-10=38 */
    margin: 0 ${({ $extraRightSpacing }) => ($extraRightSpacing ? '38px' : 0)} 0 28px;

    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
      margin: 32px 0 0 0;
    }
    /* Much of the styles on these old components are at the element-style level 
     *   rather than the class level so some "!important" tags are needed. */
    > img {
      height: 48px !important;
      width: 48px !important;
      :last-child {
        margin-left: -10px;
      }
    }
    /* The +N bubble at the end */
    > .ou-box {
      color: ${CHARCOAL_100} !important;
      background-color: ${FOG_100} !important;
    }
  }
`;

const GreenDot = styled.span`
  height: 6px;
  width: 6px;
  background-color: ${CACTUS_100};
  border-radius: 50%;
  display: inline-block;
`;

const TextContainer = styled.div`
  color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.text.primary};
  display: inline-flex;
  align-items: center;
  margin-left: 24px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
    margin-left: 0;
    margin-top: 12px;
  }
  > :last-child {
    margin-left: 16px;
  }
`;

const AdvisorImagesContainer = styled.div`
  display: flex;
  margin-left: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  & > img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0, 0.5)};
  }
`;

export const NextStepsYcbmPitch = ({ advisor, state, ycbmLink }: NextStepsYcbmPitchProps) => {
  const allAdvisors = useAdvisors();
  const featuredAdvisors = useMemo(() => {
    const yourAdvisor = advisor ? allAdvisors?.[advisor] : undefined;
    if (yourAdvisor && yourAdvisor.deleted !== true) {
      // Don't show if your advisor is available.
      return [];
    }
    if (!allAdvisors) {
      return [];
    }
    const advisors = Object.values(allAdvisors)
      .filter(
        ({ deleted, licensed }) => !deleted && (state === undefined || licensed.includes(state))
      )
      .sort(() => Math.random() - 0.5)
      .slice(0, 3);
    return advisors;
  }, [allAdvisors, advisor, state]);
  return (
    <Container $extraRightSpacing={featuredAdvisors.length > 3}>
      <NextStepsBookingButton link={ycbmLink}>Schedule a call</NextStepsBookingButton>
      {featuredAdvisors.length > 0 ? (
        <React.Fragment>
          {/* Randomized advisor images will not be checked by Chromatic */}
          <AdvisorImagesContainer data-chromatic="ignore">
            {featuredAdvisors.map((adv, i) => (
              <AdvisorImage key={`advisor-${i}`} advisor={adv} />
            ))}
          </AdvisorImagesContainer>
          <TextContainer>
            <GreenDot />
            <OwnUpSmallBodyMedium variant="body1">Available this week</OwnUpSmallBodyMedium>
          </TextContainer>
        </React.Fragment>
      ) : undefined}
    </Container>
  );
};
