import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo, useState } from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { Loader, NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { SecondaryMortgageDataModel } from './data-model';
import { FollowUpQuestions } from './follow-up-questions';
import { helocFollowUpPredicate } from './heloc-follow-up';
import { secondaryMortgageFollowUpPredicate } from './secondary-mortgage-follow-up';
import {
  getMortgageResponse,
  HELOC,
  MORTGAGE_AND_HELOC,
  NO_SECOND_MORTGAGE,
  SECOND_MORTGAGE
} from './secondary-mortgage-helpers';

// Must return either true or false. Undefined is not a valid response.
export const secondaryMortgagePredicate = ({
  write: {
    heloc,
    helocBalance,
    helocRolledIn,
    secondaryMortgage,
    secondaryMortgageRolledIn,
    secondaryMortgageBalance
  }
}: SecondaryMortgageDataModel) => {
  if (heloc && secondaryMortgage) {
    return (
      helocFollowUpPredicate(helocBalance, helocRolledIn) &&
      secondaryMortgageFollowUpPredicate(secondaryMortgageBalance, secondaryMortgageRolledIn)
    );
  } else if (heloc && !secondaryMortgage) {
    return helocFollowUpPredicate(helocBalance, helocRolledIn);
  } else if (!heloc && secondaryMortgage) {
    return secondaryMortgageFollowUpPredicate(secondaryMortgageBalance, secondaryMortgageRolledIn);
  } else if (heloc === false && secondaryMortgage === false) {
    return true;
  }
  return false;
};

export const secondaryMortgageNodeId = 'secondary-and-heloc';
export const SecondaryMortgageNodeImpl = ({
  onChangeFactory,
  ...props
}: NodeProperties<SecondaryMortgageDataModel>) => {
  const {
    write: { heloc, secondaryMortgage },
    read: { secondaryMortgageResponses }
  } = props.dataModel;

  const [secondaryMortgageResponse, setSecondaryMortgageResponse] = useState(
    getMortgageResponse(heloc, secondaryMortgage)
  );

  const helocWrapper = onChangeFactory<boolean>('heloc', secondaryMortgageNodeId);
  const secondaryMortgageWrapper = onChangeFactory<boolean>(
    'secondaryMortgage',
    secondaryMortgageNodeId
  );

  const handleOnChange = useCallback(
    (_: any, newValue?: string) => {
      const hasHeloc = newValue === MORTGAGE_AND_HELOC || newValue === HELOC;
      const hasSecondMortgage = newValue === MORTGAGE_AND_HELOC || newValue === SECOND_MORTGAGE;

      if (newValue === MORTGAGE_AND_HELOC) {
        setSecondaryMortgageResponse(MORTGAGE_AND_HELOC);
      } else if (newValue === HELOC) {
        setSecondaryMortgageResponse(HELOC);
      } else if (newValue === SECOND_MORTGAGE) {
        setSecondaryMortgageResponse(SECOND_MORTGAGE);
      } else {
        setSecondaryMortgageResponse(NO_SECOND_MORTGAGE);
      }
      helocWrapper(hasHeloc, hasHeloc);
      secondaryMortgageWrapper(hasSecondMortgage, hasSecondMortgage);
    },
    [helocWrapper, setSecondaryMortgageResponse, secondaryMortgageWrapper]
  );

  const secondaryMortgageOptions = useMemo(
    () =>
      secondaryMortgageResponses.map(({ label, value }) => (
        <InputWrapper key={value} size="half">
          <OwnUpRadioButton value={value}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [secondaryMortgageResponses]
  );

  return (
    <NodeContainer {...props}>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>Do you have a second mortgage or a HELOC?</Question>
        <OwnUpRadioGroup
          name="Has Secondary Mortgage or HELOC"
          value={secondaryMortgageResponse}
          onChange={handleOnChange}
        >
          <InputGroupWrapper>
            <StandaloneErrorWrapper
              predicateResult={radioButtonPredicate(secondaryMortgageResponse)}
            />
            {secondaryMortgageOptions}
          </InputGroupWrapper>
        </OwnUpRadioGroup>
        {secondaryMortgageResponse && secondaryMortgageResponse !== 'No' && (
          <FollowUpQuestions
            secondaryMortgageResponse={secondaryMortgageResponse}
            onChangeFactory={onChangeFactory}
            {...props}
          />
        )}
      </NodeForm>
    </NodeContainer>
  );
};

export const SecondaryMortgageNode = () => {
  const props = useNodeProperties<SecondaryMortgageDataModel>(
    secondaryMortgageNodeId,
    secondaryMortgagePredicate
  );
  return (
    <Loader>
      <SecondaryMortgageNodeImpl {...props} />
    </Loader>
  );
};
