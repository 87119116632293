import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { StateInfoDataModel } from '../../common/state-info-node/data-model';
import { StateInfoNodeImpl } from '../../common/state-info-node/element';

export const purchaseStateInfoNodeId = 'state-info';
export const PurchaseStateInfoNode = () => {
  const props = useNodeProperties<StateInfoDataModel>(purchaseStateInfoNodeId);
  return (
    <Loader>
      <StateInfoNodeImpl {...props} />
    </Loader>
  );
};
