import React from 'react';
import { HintButton, HintButtonProps } from '../../../shared/hint/hint-button';
import { HintBody } from '../../../shared/hint/typography/hint-body';
import { HintHeader } from '../../../shared/hint/typography/hint-header';

export const PropertyTypeHint = (props: Omit<HintButtonProps, 'children' | 'title'>) => (
  <HintButton {...props} title="Property types">
    <HintHeader>Single-family</HintHeader>
    <HintBody>
      A mortgage loan secured by a property that contains one residential dwelling unit.
    </HintBody>
    <br />
    <HintHeader>Condominium (condo)</HintHeader>
    <HintBody>
      A unit in a condominium project. Each unit owner has title to his or her individual unit, an
      individual interest in the project’s common areas, and, in some cases, the exclusive use of
      certain limited common areas.
    </HintBody>
    <br />
    <HintHeader>Multi-family</HintHeader>
    <HintBody>
      Properties that provide separate housing units for two or more families, although they secure
      only a single mortgage.
    </HintBody>
    <br />
    <HintHeader>Townhouse</HintHeader>
    <HintBody>
      A property type typically constructed with common walls bordering the adjacent units. The
      property owner not only owns his or her unit, but also an undivided interest in any common
      area. In some cases, a townhouse owner owns the physical structure rather than just the space
      inside the walls, like a condo owner. In some cases, However, &ldquo;townhouse&rdquo; can
      simply be a term to reference the style of the structure rather than the form of ownership.
    </HintBody>
    <br />
    <HintHeader>Manufactured home</HintHeader>
    <HintBody>
      A property that is assembled in a factory, transported to the site of use, and attached to a
      permanent foundation system.
    </HintBody>
    <br />
    <HintHeader>Mobile home</HintHeader>
    <HintBody>
      A large trailer or transportable prefabricated structure that is situated in one particular
      place, used as a permanent living accommodation, but is not attached to a permanent foundation
      system.
    </HintBody>
  </HintButton>
);
