import { getSearch } from 'connected-react-router';
import querystring from 'querystring';
import { createSelector } from 'reselect';
import { AppState } from '..';
import { getNodeIdFromPathname } from '../../modules/path-helpers';

export const questionnairePathSelector = (state: AppState) => state?.path;

/**
 * Get the last node id from the path
 */
export const lastNodeInPathSelector = createSelector(questionnairePathSelector, (path) => {
  if (path && path.length > 0) {
    return path[path.length - 1];
  }
  return '';
});

/**
 * Get the first node id from the path
 */
export const firstNodeInPathSelector = createSelector(questionnairePathSelector, (path) => {
  if (path && path.length > 0) {
    return path[0];
  }
  return '';
});

export const routerLocationSelector = (state: AppState) => state?.router?.location || {};

/**
 * Returns the pathname of your current url
 */
export const routerPathnameSelector = createSelector(
  routerLocationSelector,
  (location) => location?.pathname || ''
);

/**
 * Returns the current nodeId
 */
export const currentNodeSelector = createSelector(routerPathnameSelector, (pathname) => {
  return getNodeIdFromPathname(pathname);
});

/**
 * Returns the query parameters in the form of an object
 */
export const queryParametersSelector = createSelector(
  (state: AppState) => getSearch(state),
  (urlParams) =>
    querystring.parse(urlParams[0] === '?' ? urlParams.slice(1) : urlParams) as Record<
      string,
      string
    >
);
