import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useState } from 'react';
import { useClickedSubmitContext } from '../../../../hooks/use-clicked-submit-context';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { infoModalOpenedAction } from '../../../../store/events/actions';
import { NodeContainer } from '../../../shared/container';
import { NodeIndent } from '../../../shared/containers/node-indent';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { VeteransBenefitsConsentDataModel } from './data-model';
import { VeteransBenefitsHint } from './hint';

const statusOptions = (firstPerson: boolean) =>
  [
    {
      display: `${firstPerson ? 'I am' : 'They are'} the surviving spouse of someone who served`,
      value: 'SurvivingSpouse' as const
    },
    {
      display: `${firstPerson ? 'I' : 'They'} have a service-connected disability`,
      value: 'Disabled' as const
    },
    {
      display: `Neither of these apply to ${firstPerson ? 'me' : 'them'}`,
      value: 'Eligible' as const
    }
  ].map(({ display, value }) => (
    <InputWrapper key={value} size="single">
      <OwnUpRadioButton value={value}>{display}</OwnUpRadioButton>
    </InputWrapper>
  ));

const toggleOptions = [
  {
    display: 'Yes',
    value: true
  },
  { display: 'No', value: false }
].map(({ display, value }) => {
  return (
    <InputWrapper key={String(value)} size="single">
      <OwnUpRadioButton value={String(value)}>{display}</OwnUpRadioButton>
    </InputWrapper>
  );
});

const VeteransBenefitsQuestions = ({
  onStatusChange,
  onPriorVaChange,
  status,
  priorVa,
  firstPerson
}: {
  onStatusChange: (event: React.ChangeEvent<HTMLInputElement>, value: string | undefined) => void;
  onPriorVaChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  status: VeteransBenefitsConsentDataModel['write']['borrowerStatus'];
  priorVa: VeteransBenefitsConsentDataModel['write']['borrowerPriorVa'];
  firstPerson: boolean;
}) => {
  const { setHasClickedSubmit } = useClickedSubmitContext();
  const [showQuestions, setShowQuestions] = useState(() => {
    if (status === undefined) {
      return undefined;
    } else if (status === 'Ineligible') {
      return false;
    } else {
      return true;
    }
  });
  const onShowQuestionsChange = useCallback(
    (_: any, newValue?: string) => {
      setShowQuestions(newValue === 'true');
      // Reset this to not show errors on drawers upon render
      setHasClickedSubmit(false);
      // If 'No' selected set ineligible
      onStatusChange(_, newValue === 'false' ? 'Ineligible' : undefined);
    },
    [onStatusChange, setHasClickedSubmit]
  );

  return (
    <>
      <OwnUpRadioGroup
        name="Show veteran's benefits questions"
        value={String(showQuestions)}
        onChange={onShowQuestionsChange}
      >
        <InputGroupWrapper>
          <StandaloneErrorWrapper predicateResult={radioButtonPredicate(showQuestions)} />
          {toggleOptions}
        </InputGroupWrapper>
      </OwnUpRadioGroup>
      {showQuestions ? (
        <NodeIndent>
          <Question>
            Do either of the following apply to {firstPerson ? 'you' : 'your co-borrower'}?
          </Question>
          <OwnUpRadioGroup
            name="Disabled, surviving spouse, or neither"
            value={status}
            onChange={onStatusChange}
          >
            <InputGroupWrapper>
              <StandaloneErrorWrapper predicateResult={radioButtonPredicate(status)} />
              {statusOptions(firstPerson)}
            </InputGroupWrapper>
          </OwnUpRadioGroup>
          {
            // Only ask if the user has a previous VA loan if they don't meet one of the above conditions
            status === 'Eligible' ? (
              <NodeIndent>
                <Question>
                  {firstPerson ? 'Have you' : 'Has your co-borrower'} previously had a VA loan?
                </Question>
                <OwnUpRadioGroup name="Previous VA loan" value={priorVa} onChange={onPriorVaChange}>
                  <InputGroupWrapper>
                    <StandaloneErrorWrapper predicateResult={radioButtonPredicate(priorVa)} />
                    {toggleOptions}
                  </InputGroupWrapper>
                </OwnUpRadioGroup>
              </NodeIndent>
            ) : null
          }
        </NodeIndent>
      ) : null}
    </>
  );
};

export interface VeteransBenefitsConsentProps
  extends NodeProperties<VeteransBenefitsConsentDataModel> {
  firstPerson?: boolean;
}

export const VeteransBenefitsConsentImpl = ({
  dataModel: {
    read: {},
    write: { borrowerStatus, borrowerPriorVa }
  },
  onChangeFactory,
  dispatch,
  firstPerson = true,
  questionnaireType,
  splitClient,
  ...props
}: VeteransBenefitsConsentProps) => {
  const onBorrowerStatusChange = onChangeFactory('borrowerStatus', props.nodeId);
  const onBorrowerStatusChangeWrapper = useCallback(
    (_: any, newValue?: string) => onBorrowerStatusChange(newValue, undefined),
    [onBorrowerStatusChange]
  );
  const onBorrowerPriorVaChange = onChangeFactory('borrowerPriorVa', props.nodeId);
  const onBorrowerPriorVaChangeWrapper = useCallback(
    (_: any, newValue?: string) => onBorrowerPriorVaChange(newValue, undefined),
    [onBorrowerPriorVaChange]
  );

  const clickInfoModal = useCallback(() => {
    dispatch(infoModalOpenedAction({ nodeId: props.nodeId }));
  }, [dispatch, props.nodeId]);

  return (
    <NodeContainer {...props}>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question hint={<VeteransBenefitsHint onClick={clickInfoModal} />}>
          {firstPerson ? 'Are you' : 'Is your co-borrower'} eligible for veteran’s benefits?
        </Question>
        <VeteransBenefitsQuestions
          onStatusChange={onBorrowerStatusChangeWrapper}
          onPriorVaChange={onBorrowerPriorVaChangeWrapper}
          status={borrowerStatus}
          priorVa={borrowerPriorVa}
          firstPerson={firstPerson}
        />
      </NodeForm>
    </NodeContainer>
  );
};
