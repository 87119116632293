import { DownPaymentDataModel } from './data-model';

/**
 * Return true when downPayment is non-zero, and if an investment property,
 *   the user has answered whether or not the down payment is coming from
 *   a gift.
 */
export const downPaymentPredicate = ({
  write: { downPayment, isDownPaymentGift },
  read: { occupancyType, minReqDownPaymentPercentage, purchasePrice }
}: DownPaymentDataModel) => {
  if (downPayment !== undefined) {
    if (occupancyType === 'InvestmentProperty') {
      return isDownPaymentGift !== undefined;
    }
    if (Math.floor((purchasePrice || 0) * minReqDownPaymentPercentage) > downPayment) {
      return false;
    }
    return true;
  }
  return false;
};
