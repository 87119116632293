import { purchaseConsultationNextStepsNodeId } from './consultation-next-steps-node';
import { purchaseNextStepsNodeId } from './next-steps-node';

/**
 * List of nodes that won't display the sidebar.
 */
export const purchaseFullWidthNodes = [
  purchaseNextStepsNodeId,
  purchaseConsultationNextStepsNodeId
];
