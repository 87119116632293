import { DataModel } from '../../../../store/data-models/slice';
export interface CurrentLoanDataModel extends DataModel {
  read: {
    currentProductTypes: Array<{ value: string; label: string }>;
  };
  write: {
    currentLoanType?: string;
    currentRate?: number;
  };
}

export const interestRatePredicate = (dataModel: CurrentLoanDataModel) =>
  !!dataModel.write.currentRate && dataModel.write.currentRate > 0;

export const currentLoanNodePredicate = (dataModel: CurrentLoanDataModel) =>
  !!dataModel.write.currentLoanType && !!interestRatePredicate(dataModel);
