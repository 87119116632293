import {
  OwnUpDropdown,
  OwnUpDropdownProps,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useClickedSubmitContext } from '../../../hooks/use-clicked-submit-context';
import { InputWrapper } from '../form/inputs/input-wrapper';

export const StyledDropdown = styled(OwnUpDropdown)<PropsWithTheme<DropdownProps>>`
  margin-bottom: ${({ error }: PropsWithTheme<DropdownProps>) =>
    error ? '12px !important' : '0px'};
`;

/**
 * Dropdown wrapper that supports error states.
 */

interface DropdownProps extends OwnUpDropdownProps {
  /** Text to be passed as helpertext if there is an error */
  errorText?: string;
  dropdownPredicate?: boolean;
}

export const AdornedDropdown = ({
  errorText = '',
  dropdownPredicate = false,
  ...props
}: DropdownProps) => {
  const { hasClickedSubmit } = useClickedSubmitContext();
  /**
   *  defaultState = {showError:props.value !== undefined } allows illegal
   *   pre-filled values to trigger the error state by default.
   */
  const [showError, setShowError] = useState(props.value !== undefined);
  const handleBlur = useCallback(() => {
    if (!showError) {
      setShowError(true);
    }
  }, [showError, setShowError]);

  const dropdownErrorTextMemo = useMemo(() => {
    /**
     * This should be triggered by: 1. a user clicking `continue` without making a selection
     *  2. a default dropdownPredicate = false, e.g. a defaultValue of 0 in an input that does
     *  not allow zeroes. or 3. a wrong user interaction (tracked by 'onBlur'), e.g interacting
     *  with the input but entering nothing or zero.
     */
    return (dropdownPredicate && hasClickedSubmit) || (showError && dropdownPredicate)
      ? errorText
      : undefined;
  }, [showError, dropdownPredicate, errorText, hasClickedSubmit]);

  return (
    <InputWrapper size="single">
      <StyledDropdown
        {...props}
        labelPosition="inner"
        helperText={dropdownErrorTextMemo}
        error={dropdownErrorTextMemo}
        onClose={handleBlur}
      />
    </InputWrapper>
  );
};
