import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { PropertyAddressDataModel } from '../../common/property-address-node/data-model';
import { PropertyAddressNodeImpl } from '../../common/property-address-node/element';
import { propertyAddressPredicate } from '../../common/property-address-node/predicate';

export const acceptedOfferNewPropertyAddressNodeId = 'new-property-address';

export const AcceptedOfferNewPropertyAddressNode = () => {
  const props = useNodeProperties<PropertyAddressDataModel>(
    acceptedOfferNewPropertyAddressNodeId,
    propertyAddressPredicate
  );

  return (
    <Loader>
      <PropertyAddressNodeImpl header="What's your new address?" {...props} />
    </Loader>
  );
};
