import { FOG_100 } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { Advisor } from './next-steps-helpers';

const StyledImage = styled.img`
  border-radius: 3rem;
  border: 1px solid ${FOG_100};
`;

export const AdvisorImage = ({ advisor }: { advisor: Advisor }) => (
  <StyledImage
    src={advisor.mini_photo || advisor.photo || advisor.big_photo}
    alt={advisor.name.full}
  />
);
