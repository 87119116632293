import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { StepUpConfirmPhoneDataModel } from '../../common/step-up-confirm-phone-node/data-model';
import { StepUpConfirmPhoneNodeImpl } from '../../common/step-up-confirm-phone-node/element';
import { stepUpConfirmPhonePredicate } from '../../common/step-up-confirm-phone-node/predicate';

export const refiStepUpConfirmPhoneNodeId = 'step-up-confirm-phone';

export const RefiStepUpConfirmPhoneNode = () => {
  const props = useNodeProperties<StepUpConfirmPhoneDataModel>(
    refiStepUpConfirmPhoneNodeId,
    stepUpConfirmPhonePredicate
  );
  return (
    <Loader>
      <StepUpConfirmPhoneNodeImpl {...props} />
    </Loader>
  );
};
