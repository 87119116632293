import { OwnUpMenuItem } from '@rategravity/own-up-component-library';
import { PercentIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/percent';
import React, { useCallback, useMemo } from 'react';
import { AdornedNumberInput } from '../../../../components/shared/inputs/adorned-number-input';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader, NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { AdornedDropdown } from '../../../shared/inputs/adorned-dropdown';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import {
  CurrentLoanDataModel,
  currentLoanNodePredicate,
  interestRatePredicate
} from './current-loan-node-helper';

export const currentLoanNodeId = 'current-loan';
export const CurrentLoanNodeImpl = ({
  dataModel: {
    write: { currentLoanType, currentRate },
    read: { currentProductTypes }
  },
  onChangeFactory,
  splitClient,
  questionnaireType,
  ...props
}: NodeProperties<CurrentLoanDataModel>) => {
  const handleLoanTypeChange = useCallback(
    (
      event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
      _child: React.ReactNode
    ) => {
      const newValue = event.target.value;
      onChangeFactory<string>('currentLoanType', currentLoanNodeId)(
        typeof newValue === 'string' ? newValue : undefined,
        currentLoanType
      );
    },
    [onChangeFactory, currentLoanType]
  );
  const handleInterestRateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      onChangeFactory<number>('currentRate', currentLoanNodeId)(
        newValue === undefined ? undefined : Number(newValue),
        currentRate
      );
    },
    [onChangeFactory, currentRate]
  );

  const loanTypeOptions = useMemo(
    () =>
      currentProductTypes.map(({ label, value }) => (
        <OwnUpMenuItem key={label} value={value}>
          {label}
        </OwnUpMenuItem>
      )),
    [currentProductTypes]
  );

  return (
    <NodeContainer {...props}>
      <Overline>{props.sectionTitle}</Overline>
      <NodeForm {...props}>
        <Question>What is your current mortgage?</Question>
        <InputGroupWrapper>
          <AdornedDropdown
            value={currentLoanType}
            label="Loan type"
            onChange={handleLoanTypeChange}
            dropdownPredicate={!currentLoanType}
            errorText={'Please select a loan type'}
          >
            {loanTypeOptions}
          </AdornedDropdown>
          <AdornedNumberInput
            type="text"
            allowNegative={false}
            decimalScale={3}
            label="Interest rate"
            $leadingIcon={<PercentIcon />}
            value={currentRate}
            numberInputPredicate={interestRatePredicate({
              write: { currentRate }
            } as CurrentLoanDataModel)}
            errorText={'Please enter a valid interest rate'}
            onChange={handleInterestRateChange}
            {...props}
          />
        </InputGroupWrapper>
      </NodeForm>
    </NodeContainer>
  );
};

export const CurrentLoanNode = () => {
  const props = useNodeProperties<CurrentLoanDataModel>(
    currentLoanNodeId,
    currentLoanNodePredicate
  );
  return (
    <Loader>
      <CurrentLoanNodeImpl {...props} />
    </Loader>
  );
};
