import {
  OwnUpGridItem,
  OwnUpNumberInput,
  OwnUpNumberInputProps,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import * as colors from '@rategravity/own-up-component-library/colors';
import { DollarIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/dollar';
import { PercentIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/percent';
import React, { useCallback, useMemo, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import styled, { css } from 'styled-components';
import { useClickedSubmitContext } from '../../../hooks/use-clicked-submit-context';
import { InputGroupWrapper } from '../form/inputs/input-group-wrapper';
import { InputWrapper } from '../form/inputs/input-wrapper';
import { StandaloneError } from '../form/standalone-error';

const sharedInputStyles = css<
  PropsWithTheme<{ otherInputFocused: boolean; otherInputHovered: boolean }>
>`
  .MuiFilledInput-root {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .MuiFilledInput-underline:not(.Mui-error):not(.Mui-disabled):hover:before,
  .MuiFilledInput-underline:not(.Mui-error):not(.Mui-disabled):hover:after,
  .MuiFilledInput-underline:not(.Mui-error):before,
  .MuiFilledInput-underline:not(.Mui-error):after {
    ${({ otherInputFocused, otherInputHovered }) => {
      if (otherInputFocused || otherInputHovered) {
        return `background-color: ${colors.ALOE_100};
                height: 2px;
                width: 100%;
                border-radius: 0;`;
      }
    }};
  }
  .MuiFilledInput-underline:not(.Mui-error):not(.Mui-disabled):hover:before,
  .MuiFilledInput-underline:not(.Mui-error):not(.Mui-disabled):hover:after,
  .MuiFilledInput-underline:not(.Mui-error).Mui-focused:before,
  .MuiFilledInput-underline:not(.Mui-error).Mui-focused:after,
  .MuiFilledInput-underline:not(.Mui-error):before,
  .MuiFilledInput-underline:not(.Mui-error):after {
    border-radius: 0;
  }
`;

const NumericDPInputWrapper = styled.div<
  PropsWithTheme<{ otherInputFocused: boolean; otherInputHovered: boolean }>
>`
  display: none;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    ${sharedInputStyles}
    display: block;
    min-width: 200px;
    .MuiFilledInput-adornedEnd {
      padding-right: 0;
    }
    input {
      padding: 27px 32px 12px 56px;
    }
  }
`;

const NumericDPInputWrapperXS = styled.div<PropsWithTheme<{}>>`
  display: block;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;

const PercentDPInputWrapper = styled.div<
  PropsWithTheme<{ otherInputFocused: boolean; otherInputHovered: boolean }>
>`
  display: none;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    .MuiInputLabel-filled.MuiInputLabel-shrink {
      transform: translate(32px, 10px) scale(0.75);
    }
    ${sharedInputStyles}
    display: block;
    width: 147px;
    input {
      width: 52px;
      padding: 27px 0 12px 32px;
    }
  }
`;

const UnattachedPercentDPInputWrapper = styled(InputWrapper)<PropsWithTheme<{}>>`
  display: block;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;

const DPInputWrapper = styled(InputWrapper)<PropsWithTheme<{}>>`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    display: flex;
  }
`;

export interface DownPaymentInputProps extends Omit<OwnUpNumberInputProps, 'label'> {
  /** Text to be passed as helpertext if there is an error */
  errorText?: string;
  downPaymentInputPredicate?: boolean;
  numericValue?: number | string;
  percentValue?: number | string;
  onNumericChange?: (value: NumberFormatValues) => void;
  onPercentChange?: (value: NumberFormatValues) => void;
  onSeparatedPercentChange?: (value: NumberFormatValues) => void;
  onSeparatedNumericChange?: (value: NumberFormatValues) => void;
  // Input refs to track when a user starts typing. Also tracks input
  //  values that are not handled by state, e.g when a user deletes
  //  their entry on either of the inputs.
  numericRef?: React.RefObject<HTMLInputElement>;
  percentRef?: React.RefObject<HTMLInputElement>;
  separatedPercentRef?: React.RefObject<HTMLInputElement>;
  separatedNumericRef?: React.RefObject<HTMLInputElement>;
}

/** (Dual) Number Input adorned with cash, and percent icons; and supports error states */
export const DownPaymentInput = ({
  errorText = '',
  downPaymentInputPredicate = false,
  numericValue,
  percentValue,
  onNumericChange,
  onPercentChange,
  onSeparatedPercentChange,
  onSeparatedNumericChange,
  numericRef,
  percentRef,
  separatedPercentRef,
  separatedNumericRef,
  ...props
}: DownPaymentInputProps) => {
  const { hasClickedSubmit } = useClickedSubmitContext();
  /**
   *  defaultState = {showError:props.value !== undefined } allows illegal
   *   pre-filled values to trigger the error state by default.
   */
  const [showError, setShowError] = useState(
    numericValue !== undefined &&
      numericValue !== null &&
      percentValue !== undefined &&
      percentValue !== null
  );

  // Focused state to track when a user interacts with the input.
  const [numericFocused, setNumericFocused] = React.useState(false);
  const [percentFocused, setPercentFocused] = React.useState(false);
  // Tracks hover state to facilitate styling of the input underlines
  //  when hovered.
  const [numericHovered, setNumericHovered] = React.useState(false);
  const [percentHovered, setPercentHovered] = React.useState(false);

  const handleBlur = useCallback(() => {
    if (!showError) {
      setShowError(true);
    }
    setNumericFocused(false);
    setPercentFocused(false);
  }, [showError, setShowError, setNumericFocused, setPercentFocused]);

  const downPaymentInputErrorTextMemo = useMemo(() => {
    /**
     * This should be triggered by:
     *  1. a user clicking `continue` without making a selection
     *  2. a default input error, e.g. a defaultValue of 0 in an input that does
     *  not allow zeroes. Or
     *  3. a wrong user interaction (tracked by 'onBlur'), e.g interacting
     *  with the input but entering nothing or zero(if zero is a prohibited input).
     */
    return (!downPaymentInputPredicate && hasClickedSubmit) ||
      (showError && !downPaymentInputPredicate)
      ? errorText
      : undefined;
  }, [showError, downPaymentInputPredicate, errorText, hasClickedSubmit]);

  const renderPercentLabel = useMemo(() => {
    const percentRefFocused = document.activeElement === percentRef?.current;
    if ((percentValue && !isNaN(percentValue as number)) || percentFocused || percentRefFocused) {
      return 'Percent';
    }
    return '0';
  }, [percentValue, percentFocused, percentRef]);

  return (
    <InputGroupWrapper>
      <DPInputWrapper size="single">
        <NumericDPInputWrapper
          otherInputFocused={percentFocused}
          otherInputHovered={percentHovered}
        >
          <OwnUpNumberInput
            {...props}
            label="Down payment"
            labelPosition="inner"
            name="downPaymentNumber"
            inputRef={numericRef}
            value={numericValue}
            $leadingIcon={<DollarIcon />}
            $hideErrorIcon={true}
            allowNegative={false}
            allowLeadingZeros={false}
            fixedDecimalScale={true}
            decimalScale={0}
            error={!!downPaymentInputErrorTextMemo}
            onValueChange={onNumericChange}
            onBlur={handleBlur}
            onFocus={() => setNumericFocused(true)}
            onMouseOver={() => setNumericHovered(true)}
            onMouseOut={() => setNumericHovered(false)}
          />
        </NumericDPInputWrapper>
        <NumericDPInputWrapperXS>
          <OwnUpNumberInput
            {...props}
            label="Down payment"
            labelPosition="inner"
            name="downPaymentNumber"
            inputRef={separatedNumericRef}
            value={numericValue}
            $leadingIcon={<DollarIcon />}
            allowNegative={false}
            allowLeadingZeros={false}
            fixedDecimalScale={true}
            decimalScale={0}
            error={!!downPaymentInputErrorTextMemo}
            onValueChange={onSeparatedNumericChange}
            onBlur={handleBlur}
            onFocus={() => setNumericFocused(true)}
            onMouseOver={() => setNumericHovered(true)}
            onMouseOut={() => setNumericHovered(false)}
          />
        </NumericDPInputWrapperXS>
        <PercentDPInputWrapper
          otherInputFocused={numericFocused}
          otherInputHovered={numericHovered}
        >
          <OwnUpNumberInput
            {...props}
            label={renderPercentLabel}
            labelPosition="inner"
            name="downPaymentPercent"
            inputRef={percentRef}
            value={percentValue}
            $endIcon={<PercentIcon />}
            allowNegative={false}
            allowLeadingZeros={false}
            fixedDecimalScale={true}
            decimalScale={2}
            error={!!downPaymentInputErrorTextMemo}
            onValueChange={onPercentChange}
            onBlur={handleBlur}
            onFocus={() => setPercentFocused(true)}
            onMouseOver={() => setPercentHovered(true)}
            onMouseOut={() => setPercentHovered(false)}
            style={{
              boxShadow: `-18px 0 0 -17px ${colors.ALOE_100}`
            }}
          />
        </PercentDPInputWrapper>
      </DPInputWrapper>
      <UnattachedPercentDPInputWrapper size="single">
        <OwnUpNumberInput
          {...props}
          label={renderPercentLabel}
          labelPosition="inner"
          name="downPaymentPercent"
          inputRef={separatedPercentRef}
          value={percentValue}
          $leadingIcon={<PercentIcon />}
          allowNegative={false}
          allowLeadingZeros={false}
          fixedDecimalScale={true}
          decimalScale={2}
          error={!!downPaymentInputErrorTextMemo}
          onValueChange={onSeparatedPercentChange}
          onBlur={handleBlur}
          onFocus={() => setPercentFocused(true)}
        />
      </UnattachedPercentDPInputWrapper>

      {!!downPaymentInputErrorTextMemo && (
        <OwnUpGridItem xs={12}>
          <StandaloneError hideErrorIcon={true}>{downPaymentInputErrorTextMemo}</StandaloneError>
        </OwnUpGridItem>
      )}
    </InputGroupWrapper>
  );
};
