import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { StateDataModel } from '../../common/state-node/data-model';
import { StateNodeImpl } from '../../common/state-node/element';
import { statePredicate } from '../../common/state-node/predicate';

export const preapprovalStateNodeId = 'state';
export const PreapprovalStateNode = () => {
  const props = useNodeProperties<StateDataModel>(preapprovalStateNodeId, statePredicate);
  return (
    <Loader>
      <StateNodeImpl
        headerText="Let's get started on your preapproval. Tell us about the property you're looking for."
        subheaderText="Where are you looking for a home?"
        {...props}
      />
    </Loader>
  );
};
