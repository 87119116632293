import { SLATE_100 } from '@rategravity/own-up-component-library';
import React from 'react';
import { HintButton, HintButtonProps } from '../../../shared/hint/hint-button';
import { HintBody } from '../../../shared/hint/typography/hint-body';

export const CoborrowerHint = (props: Omit<HintButtonProps, 'children' | 'title'>) => (
  <HintButton {...props} title="Co-borrower">
    <HintBody style={{ color: SLATE_100 }}>
      A co-borrower is any additional borrower whose name appears on loan documents.
    </HintBody>
  </HintButton>
);
