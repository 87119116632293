import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { ConfirmContactInfoDataModel } from '../../common/confirm-contact-info-node/data-model';
import { ConfirmContactInfoNodeImpl } from '../../common/confirm-contact-info-node/element';
import { confirmContactInfoPredicate } from '../../common/confirm-contact-info-node/predicate';

export const refiConfirmContactInfoNodeId = 'confirm-contact-info';

export const RefiConfirmContactInfoNode = () => {
  const props = useNodeProperties<ConfirmContactInfoDataModel>(
    refiConfirmContactInfoNodeId,
    confirmContactInfoPredicate
  );
  return (
    <Loader>
      <ConfirmContactInfoNodeImpl {...props} />
    </Loader>
  );
};
