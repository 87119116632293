import {
  MINT_100,
  OwnUpFillButtonSecondary,
  OwnUpIcon,
  OwnUpOverline,
  OwnUpSmallBody,
  PropsWithTheme,
  SLATE_100,
  WHITE_100
} from '@rategravity/own-up-component-library';
import { SearchIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/search';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { sendToSentry } from '../../modules/segment';
import { serializeError } from '../../modules/segment/types';
import { DisplayState } from '../../store/display/state';

export type ErrorPageProps = DisplayState['error'];

const ErrorContainer = styled.div`
  max-width: 360px;
  display: flex;
  flex-direction: column;
  margin: 6rem auto;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  text-align: center;
`;

const ErrorIcon = styled(OwnUpIcon)`
  background-color: ${MINT_100};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2.5)}px;
  border-radius: 50%;
`;

const ErrorHeader = styled(OwnUpOverline)`
  text-transform: uppercase;
  background-color: ${MINT_100};
  padding: 0.25rem 0.5rem 0;
  border-radius: 4px;
`;

const ErrorBody = styled(OwnUpSmallBody)`
  color: ${SLATE_100};
`;

/**
 * Page to render when something errors
 */
export const ErrorPage = ({
  headline = "Something's not right.",
  message = "Don't worry! We have saved the information you entered so far. Please wait a moment and try again.",
  backButtonText = 'Back to OwnUp.com',
  backButtonUrl = 'https://www.ownup.com'
}: ErrorPageProps = {}) => {
  useEffect(() => {
    sendToSentry(serializeError(new Error('Shopper saw error page'), { headline, message }));
  }, [headline, message]);
  return (
    <ErrorContainer>
      <ErrorIcon icon={SearchIcon} height="3rem" color={WHITE_100} />
      <ErrorHeader>{headline}</ErrorHeader>
      <ErrorBody variant="body1">{message}</ErrorBody>
      <a
        href={backButtonUrl}
        onClick={(e) => {
          // Replace instead of append to history if a local page
          if (backButtonUrl.startsWith('/')) {
            e.preventDefault();
            window.location.replace(backButtonUrl);
          }
        }}
      >
        <OwnUpFillButtonSecondary>{backButtonText}</OwnUpFillButtonSecondary>
      </a>
    </ErrorContainer>
  );
};
