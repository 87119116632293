import {
  OwnUpMenuItem,
  OwnUpRadioButton,
  OwnUpRadioGroup
} from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { AdornedDropdown } from '../../../../components/shared/inputs/adorned-dropdown';
import { AdornedNumberInput } from '../../../../components/shared/inputs/adorned-number-input';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { NodeContainer } from '../../../shared/container';
import { NodeIndent } from '../../../shared/containers/node-indent';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { LenderOfferDataModel } from './data-model';
import { interestRatePredicate } from './predicate';

interface CurrentLoanOfferComponent {
  rate?: number;
  productRadioOptions: Array<{ display: string; value: string }>;
  productType?: string;
  rateChangeHandler: (values: NumberFormatValues) => void;
  productTypeChangeHandler: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}
const CurrentLoanOfferComponent = (props: CurrentLoanOfferComponent) => {
  const { rate, productRadioOptions, productType, rateChangeHandler, productTypeChangeHandler } =
    props;

  return (
    <NodeIndent>
      <Question>What&apos;s the best rate you&apos;ve been offered for this purchase?</Question>
      <InputGroupWrapper>
        <AdornedDropdown
          value={productType}
          label="Loan type"
          onChange={productTypeChangeHandler}
          dropdownPredicate={!productType}
          errorText={'Please select a loan type'}
        >
          {productRadioOptions.map(({ display, value }) => (
            <OwnUpMenuItem key={value} value={value}>
              {display}
            </OwnUpMenuItem>
          ))}
        </AdornedDropdown>
        <AdornedNumberInput
          label="Interest rate (%)"
          labelPosition="inner"
          allowNegative={false}
          decimalScale={3}
          allowLeadingZeros
          errorText={'Please enter a valid interest rate'}
          onValueChange={rateChangeHandler}
          value={rate}
          numberInputPredicate={interestRatePredicate(rate)}
          {...props}
        />
      </InputGroupWrapper>
    </NodeIndent>
  );
};

export const LenderOfferNodeImpl = ({
  dataModel: {
    read: { productOptions },
    write: { hasLenderOffer, rate, productType }
  },
  onChangeFactory,
  dispatch,
  questionnaireType,
  splitClient,
  ...props
}: NodeProperties<LenderOfferDataModel>) => {
  const hasLenderOfferChangeDispatcher = onChangeFactory<boolean>('hasLenderOffer', props.nodeId);
  const rateChangeDispatcher = onChangeFactory<number>('rate', props.nodeId);
  const productTypeChangeDispatcher = onChangeFactory<string>('productType', props.nodeId);

  const handleProductTypeChange = useCallback(
    (
      e: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => productTypeChangeDispatcher(e.target.value as string, undefined),
    [productTypeChangeDispatcher]
  );

  const handleRateChange = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      if (interestRatePredicate(floatValue)) {
        rateChangeDispatcher(floatValue, undefined);
      }
    },
    [rateChangeDispatcher]
  );

  /**
   * When an option is selected that removes any sub-questions, the previous inputs
   *   to the sub-questions will be cleared.
   */
  const handleHasLenderOfferChange = useCallback(
    (_: any, newValue?: string) => {
      if (!newValue) {
        rateChangeDispatcher(undefined, undefined);
        productTypeChangeDispatcher(undefined, undefined);
      }
      hasLenderOfferChangeDispatcher(newValue === 'true', undefined);
    },
    [rateChangeDispatcher, productTypeChangeDispatcher, hasLenderOfferChangeDispatcher]
  );

  const productOptionsRadioOptions = useMemo(
    () =>
      productOptions.map(({ label, value }) => ({
        display: label,
        value
      })),
    [productOptions]
  );

  const renderCurrentOfferOptions = useMemo(() => {
    if (hasLenderOffer === true) {
      return (
        <CurrentLoanOfferComponent
          rate={rate}
          productType={productType}
          productRadioOptions={productOptionsRadioOptions}
          rateChangeHandler={handleRateChange}
          productTypeChangeHandler={handleProductTypeChange}
          {...props}
        />
      );
    }
    return <React.Fragment />;
  }, [
    hasLenderOffer,
    rate,
    productType,
    productOptionsRadioOptions,
    handleRateChange,
    handleProductTypeChange,
    props
  ]);

  const selectedHasLenderOfferOption = useMemo(
    () => (typeof hasLenderOffer === 'boolean' ? hasLenderOffer : null),
    [hasLenderOffer]
  );

  return (
    <NodeContainer {...props}>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>Have you been offered financing from a lender?</Question>
        <OwnUpRadioGroup
          name="Lender offer"
          value={selectedHasLenderOfferOption}
          onChange={handleHasLenderOfferChange}
        >
          <InputGroupWrapper>
            <StandaloneErrorWrapper
              predicateResult={radioButtonPredicate(selectedHasLenderOfferOption)}
            />
            <InputWrapper size="single">
              <OwnUpRadioButton value={true}>Yes</OwnUpRadioButton>
            </InputWrapper>
            <InputWrapper size="single">
              <OwnUpRadioButton value={false}>No</OwnUpRadioButton>
            </InputWrapper>
          </InputGroupWrapper>
        </OwnUpRadioGroup>
        {renderCurrentOfferOptions}
      </NodeForm>
    </NodeContainer>
  );
};
