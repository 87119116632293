import { TrackingEventPayload } from '../1pt';

/**
 * Ferry events in to Google Tag Manager/Google Analytics if it is present
 *   on the page. This is installed via GTM and is not a part of the
 *   code base, but if it was successfully added to the page this function
 *   will forward events to it.
 */
export const sendToGoogle = (payload: TrackingEventPayload) => {
  if (payload.method === 'track') {
    // If the dataLayer has not been initialized yet, initialize it as an
    //   empty array. GTM will drain it when it loads.
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ...payload.properties, event: payload.event });
  }
};
