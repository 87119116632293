import * as colors from '@rategravity/own-up-component-library/colors';
import React from 'react';
import styled from 'styled-components';

export interface NavBarProgressProps {
  /**
   * How much of the progress bar to fill.
   */
  percentComplete?: number;
}

export const NavBarProgressImpl = (props: NavBarProgressProps) => (
  <div {...props}>
    <div className="progress-bar" />
  </div>
);

export const NavBarProgress = styled(NavBarProgressImpl)<NavBarProgressProps>`
  position: absolute;
  height: 4px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${colors.FOG_60};

  & .progress-bar {
    height: 100%;
    width: ${({ percentComplete = 0 }) => Math.ceil(percentComplete * 100)}%;
    /* Round the right-side edges */
    border-radius: 0 4px 4px 0;
    background-color: ${colors.ALOE_100};
  }
`;
