import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { EmailAndPhoneDataModel } from '../../common/email-and-phone-node/data-model';
import { EmailAndPhoneNodeImpl } from '../../common/email-and-phone-node/element';
import { emailAndPhonePredicate } from '../../common/email-and-phone-node/predicate';

export const acceptedOfferEmailAndPhoneNodeId = 'email-and-phone';

export const AcceptedOfferEmailAndPhoneNode = () => {
  const props = useNodeProperties<EmailAndPhoneDataModel>(
    acceptedOfferEmailAndPhoneNodeId,
    emailAndPhonePredicate
  );
  return (
    <Loader>
      <EmailAndPhoneNodeImpl {...props} />
    </Loader>
  );
};
