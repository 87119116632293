import {
  OwnUpIconButton,
  OwnUpIconButtonProps,
  OwnUpModalProps,
  OwnUpSideModal
} from '@rategravity/own-up-component-library';
import { useCombinedClassName } from '@rategravity/own-up-component-library/hooks/use-combined-class-name';
import { MicroQuestionIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/micro-icons/question';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

/**
 * Extension of {@see OwnUpModalProps} that excludes some fields
 *   that are auto-set by the hint button.
 */
export interface HintModalProps extends Omit<Partial<OwnUpModalProps>, 'title' | 'children'> {}

/**
 * Extension of {@see OwnUpIconButtonProps} that excludes some
 *   fields that are auto-set by the hint button.
 */
export interface HintButtonProps extends Omit<OwnUpIconButtonProps, 'icon' | 'name' | 'title'> {
  /**
   * Header for hint modal
   *
   * HACK: Should be `OwnUpModalProps['title']` but the
   *   chain of `Omit` types is causing this to lose
   *   some specificity.
   */
  title: React.ReactNode;
  /**
   * Content for hint modal.
   *
   * HACK: Should be `OwnUpModalProps['children']` but the
   *   chain of `Omit` types is causing this to lose
   *   some specificity.
   */
  children: React.ReactNode;
  /**
   * Optional direct access to the Modal Props.
   *
   * Note: `title` and `children` should be on the
   *   HintButton itself, not this sub-props.
   */
  modalProps?: Omit<HintModalProps, 'title' | 'children'>;
}

const HintButtonImpl = ({
  className,
  title,
  children,
  onClick,
  modalProps: { open: defaultOpen, onClose, ...modalProps } = {},
  ...props
}: HintButtonProps) => {
  // State control for the modal.
  const [open, setOpen] = useState(defaultOpen || false);

  // Wrappers around event listeners for the modal and button.
  const onCloseWrapper = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose, setOpen]);

  const onClickWrapper = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      setOpen(true);
      onClick?.(evt);
    },
    [onClick, setOpen]
  );

  // Add a class name for the button to allow for custom CSS from parent
  //   containers.
  const buttonClassName = useCombinedClassName('hint-button', className);
  return (
    <React.Fragment>
      {/* HACK: title and children casts are due to Typescript
          issues described above. */}
      <OwnUpSideModal
        title={title as OwnUpModalProps['title']}
        open={open}
        onClose={onCloseWrapper}
        {...modalProps}
      >
        {children as OwnUpModalProps['children']}
      </OwnUpSideModal>
      <OwnUpIconButton
        size={1.5}
        {...props}
        onClick={onClickWrapper}
        className={buttonClassName}
        name="Hint"
        icon={MicroQuestionIcon}
      />
    </React.Fragment>
  );
};

/**
 * A "HintButton" provides a clickable (?) button that can pop open
 *   a modal containing useful information for the node.
 *
 * To line it up with a Typography component, it should be placed inside
 *   that Typography component (typically a {@see Headline} or
 *   {@see Question} component) as the very first element. e.g.:
 * ```tsx
 * <Question>
 *   <HintButton {...} />
 *   How are you?
 * </Question>
 * ```
 *
 * Figma: https://www.figma.com/file/xOJwjvBP1RFcnViMvWLKsX/Koala-%2F-System-Skin?node-id=8%3A3850
 */
export const HintButton = styled(HintButtonImpl)`
  & {
    float: right;
    clear: right;
  }
`;
