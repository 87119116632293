import React, { useEffect } from 'react';
import { NodeProperties } from '../../properties';
import { AccountDataModel } from './data-model';

export const AccountNodeImpl = ({
  dataModel: {
    read: { accountId, idToken, waitFor }
  }
}: NodeProperties<AccountDataModel>) => {
  useEffect(() => {
    let waitForUrl: string | undefined;
    if (waitFor) {
      if (typeof waitFor === 'string') {
        waitForUrl = `wait-for=${waitFor}`;
      } else {
        waitForUrl = `wait-for=${waitFor.field}&wait-for-key=${waitFor.key}&wait-for-value=${waitFor.value}`;
      }
    }
    // Direct the user into their account using their temp token (if applicable)
    const path = `/account/${accountId}`;
    const queryParams = waitForUrl ? `?${waitForUrl}` : ``;
    const hashParams = idToken ? `#temp_token=${idToken}` : ``;
    window.location.replace(`${process.env.MY_OWNUP_URL}${path}${queryParams}${hashParams}`);
  }, [accountId, idToken, waitFor]);
  //this element has to return something
  return <React.Fragment />;
};

// could direct to auth instead of account
