import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { CloseDateDataModel } from './data-model';

export interface CloseDateNodeProps extends NodeProperties<CloseDateDataModel> {
  treatmentOverride?: boolean;
}

export const CloseDateNodeImpl = ({
  dataModel: {
    write: { closeDate, splitTreatment },
    read: { options }
  },
  onChangeFactory,
  splitClient,
  treatmentOverride,
  nodeId,
  ...props
}: CloseDateNodeProps) => {
  const useSplitTreatment = treatmentOverride || splitTreatment === 'on';

  const closeDateChangeDispenser = onChangeFactory<number>('closeDate', nodeId);
  const handleCloseDateChange = useCallback(
    (_: any, newValue?: string) => {
      closeDateChangeDispenser(Number(newValue), closeDate);
    },
    [closeDateChangeDispenser, closeDate]
  );
  const selectedCloseDateOption = useMemo(
    () => (typeof closeDate === 'number' ? closeDate : null),
    [closeDate]
  );

  const date = new Date();
  const twentyOneDaysOutEpoch = date.setDate(date.getDate() + 21);
  const twentyOneDaysOut = new Date(twentyOneDaysOutEpoch).toLocaleDateString('en-us', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
  return (
    <NodeContainer>
      <NodeForm nodeId={nodeId} {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>
          {useSplitTreatment ? (
            <>
              Is your close date <strong>on or before</strong> {twentyOneDaysOut}?
            </>
          ) : (
            'What is the approximate close date on your new property?'
          )}
        </Question>
        <OwnUpRadioGroup
          name="Close Date"
          value={selectedCloseDateOption}
          onChange={handleCloseDateChange}
        >
          <InputGroupWrapper>
            <StandaloneErrorWrapper
              predicateResult={radioButtonPredicate(selectedCloseDateOption)}
            />
            {options.map(({ value, label }, i) => (
              <InputWrapper size="single" key={i}>
                <OwnUpRadioButton value={value}>{label}</OwnUpRadioButton>
              </InputWrapper>
            ))}
          </InputGroupWrapper>
        </OwnUpRadioGroup>
      </NodeForm>
    </NodeContainer>
  );
};
