import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpGridWrapperProps,
  ownUpLightTheme,
  OwnUpThemeProvider,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import { Cobranding } from '@rategravity/widgets-react/components/cobranding';
import React, { PropsWithChildren, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { questionnaireLoadingSelector } from '../../store/questionnaire/selectors';
import { ThinkingScreen } from '../thinking-screen';
import { Loading } from './loading';

export type NodeContainerProps = OwnUpGridWrapperProps;

/**
 * When in a node view, override the body background color.
 */
const NodeBackground = createGlobalStyle`
  body {
    background-color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.background.default};
  }
`;

const NodeWrapper = styled.div`
  background-color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.background.default};
  width: 100%;
  height: 100%;
  margin-bottom: 48px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    margin-bottom: 64px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    margin-bottom: 80px;
  }
`;

const CobrandingWrapper = styled(Cobranding)`
  & > img {
    margin-top: 56px;
    max-height: 48px;
    min-height: 24px;
  }
`;

const NodeContainerImpl = ({ children, ...props }: PropsWithChildren<NodeContainerProps>) => (
  <OwnUpThemeProvider theme={ownUpLightTheme}>
    <NodeBackground />
    <NodeWrapper>
      <OwnUpGridWrapper {...props}>
        <OwnUpGridContainer variant="slim">
          {/* Left gutter is not visible on small and down, 1 column from MD-LG, 2 columns on LG */}
          <OwnUpGridOffset xs={0} md={1} xl={2} />
          <OwnUpGridItem xs={12} md={10} xl={8}>
            {children}
            <CobrandingWrapper />
          </OwnUpGridItem>
        </OwnUpGridContainer>
      </OwnUpGridWrapper>
    </NodeWrapper>
  </OwnUpThemeProvider>
);

/**
 * Full page wrapper for nodes. Contains a responsive grid and top-margins.
 *
 * Figma: https://www.figma.com/file/xOJwjvBP1RFcnViMvWLKsX/Koala-%2F-System-Skin?node-id=25%3A5754
 */
export const NodeContainer = styled(NodeContainerImpl)<PropsWithTheme<NodeContainerProps>>`
  /* Add top margins to make room for the fixed navbar */
  & {
    ::before {
      content: '';
      display: block;
      /* 80px at lg+ */
      height: 5rem;
      ${({ theme }: PropsWithTheme<NodeContainerProps>) => theme.breakpoints.down('sm')} {
        /* 72px at <=sm */
        height: 4.5rem;
      }
    }
  }
`;

/**
 * The loader is separate from the container so it does not cause Provider issues
 *   when the container is rendered in Storybook
 *
 * @param messages - Include to show the more intense thinking screen
 * @param children - elements to render when loading is done
 */
export const Loader = ({
  messages,
  children,
  waitingForExperiment
}: {
  messages?: (JSX.Element | string)[];
  children: ReactNode;
  waitingForExperiment?: boolean;
}) => {
  const loading = useSelector(questionnaireLoadingSelector);
  if (loading || waitingForExperiment) {
    return messages ? <ThinkingScreen messages={messages} /> : <Loading />;
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};
