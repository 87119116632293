/* eslint-disable max-lines-per-function */
import { useClient } from '@splitsoftware/splitio-react';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeProperties } from '../components/nodes/properties';
import { ObjectPredicate } from '../modules/predicate';
import { dataModelSelector } from '../store/data-models/selectors';
import { clearDataModelAction, DataModel, updateDataModelAction } from '../store/data-models/slice';
import { clickedBackButtonAction } from '../store/events/actions';
import { goBackAction } from '../store/path/actions';
import { firstNodeInPathSelector } from '../store/path/selectors';
import { saveQuestionnaireRequestAction } from '../store/questionnaire/save-actions';
import { questionnaireTypeSelector } from '../store/questionnaire/selectors';
import { useDebounceTracker } from './use-debounce-tracker';
import { useDeconstructedLoginToken } from './use-deconstructed-login-token';
import { useNodeConfigurations } from './use-node-configurations';
import { useScrollToError } from './use-scroll-to-error';
import { useScrollTop } from './use-scroll-top';

/**
 * You can declare your own predicate for the
 * model. For instance, if a field can be 0 you should declare a function
 * that returns true for this scenario. However, once you declare a predicate
 * it must be complete for your model - meaning it is now the single source
 * of truth for completness of the node.
 */
export const isCompleteFactory =
  <TDataModel>(predicate: ObjectPredicate<TDataModel>) =>
  (dataModel: TDataModel) =>
    predicate(dataModel);

/**
 * Hook to generate node property sets.
 *
 * @param converter - Converter to grab the node model
 */
export const useNodeProperties = <TDataModel extends DataModel>(
  nodeId: string,
  predicate: ObjectPredicate<TDataModel> = () => true
): NodeProperties<TDataModel> => {
  const dispatch = useDispatch();
  const dataModel = useSelector(dataModelSelector(nodeId)) as unknown as TDataModel;

  const scrollToError = useScrollToError();

  // Callback for hitting the submit button.
  const onSubmit = useCallback(
    (e?: SyntheticEvent<unknown>) => {
      scrollToError();
      predicate(dataModel) ? dispatch(saveQuestionnaireRequestAction()) : e?.preventDefault();
    },
    [dispatch, dataModel, predicate, scrollToError]
  );

  // Callback for hitting the back button.
  const onBack = useCallback(() => {
    dispatch(goBackAction());
    dispatch(clickedBackButtonAction({ nodeId }));
  }, [dispatch, nodeId]);

  // Factory function for creating onChange methods.
  const onChangeFactory = useCallback(
    <T>(fieldName: string, id: string) => {
      return (newValue: T | undefined, _: T | undefined) =>
        dispatch(updateDataModelAction({ nodeId: id, updatedFields: { [fieldName]: newValue } }));
    },
    [dispatch]
  );

  const clearModel = useCallback((id: string) => dispatch(clearDataModelAction(id)), [dispatch]);

  // Whether or not the back button should be submittable
  const firstNodeId = useSelector(firstNodeInPathSelector);

  // Only allow users to navigate backwards if they're not at the start.
  const canGoBack = useMemo(() => nodeId !== firstNodeId, [firstNodeId, nodeId]);

  // Build out the completion checking function
  const isComplete = useMemo(() => isCompleteFactory(predicate), [predicate]);
  // Evaluate it whenever the model changes
  const canSubmit = useMemo(() => isComplete(dataModel), [isComplete, dataModel]);

  const config = useNodeConfigurations();
  const sectionTitle = useMemo(() => config?.nodeToSectionMapping[nodeId], [config, nodeId]);

  // Get questionnaire type
  const questionnaireType = useSelector(questionnaireTypeSelector);

  const splitClient = useClient() as SplitIO.IClient;

  // Get the accountId if it exists
  const { accountId } = useDeconstructedLoginToken();

  const [waitingForSplitClient, setWaitingForSplitClient] = useState(true);
  useEffect(() => {
    splitClient
      .ready()
      .catch(() => {
        console.error('Split client errored out');
      })
      .finally(() => {
        setWaitingForSplitClient(false);
      });
  }, [waitingForSplitClient, splitClient]);

  // Memoized property set.
  const props = useMemo(
    () => ({
      dataModel,
      dispatch,
      onSubmit,
      canGoBack,
      nodeId,
      onBack,
      onChangeFactory,
      canSubmit,
      clearModel,
      sectionTitle,
      questionnaireType,
      splitClient,
      waitingForSplitClient,
      accountId
    }),
    [
      canGoBack,
      canSubmit,
      dataModel,
      dispatch,
      nodeId,
      onSubmit,
      onBack,
      onChangeFactory,
      clearModel,
      sectionTitle,
      questionnaireType,
      splitClient,
      waitingForSplitClient,
      accountId
    ]
  );

  // Scroll to the top of the page on first render
  useScrollTop();

  // Fire off an event every 60s when user is on a node
  useDebounceTracker();

  return props;
};
