import {
  OwnUpFillButtonPrimary,
  OwnUpLineButton,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React, { ReactNode, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ClickedSubmitContext } from '../../../hooks/use-clicked-submit-context';

/**
 * Properties to define common graph modification form
 */
export interface NodeFormProps
  extends React.PropsWithChildren<
    React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>
  > {
  /**
   * Node identifier
   */
  nodeId: string;

  /**
   * Account identifier
   */
  accountId?: string;

  /**
   * Optional callback for when submit is clicked.
   */
  onSubmit?: (e: SyntheticEvent<unknown>) => void;

  /**
   * Function to run on going back
   */
  onBack: () => void;

  /**
   * Set to false to disable the submit button
   */
  canSubmit?: boolean;

  /**
   * Set to false to hide the submit button
   */
  hasSubmit?: boolean;

  /**
   * Set to false to hide the back button
   */
  canGoBack?: boolean;

  /**
   * Set to false to hide `account home` button
   */
  showAccountHomeButton?: boolean;

  /**
   * Alternative 'Continue' button display
   */
  submitText?: ReactNode;

  /**
   * Alternative 'Back' button display
   */
  backText?: ReactNode;

  /**
   * Alternative 'Account home' button display
   */
  accountHomeButtonText?: ReactNode;
}

export const ButtonContainer = styled.div<PropsWithTheme<{}>>`
  width: 100%;

  /* 40px */
  margin-top: 2.5rem;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
    /* 32px */
    margin-top: 2rem;
  }
`;

export const MobileButtons = styled.div<PropsWithTheme<{}>>`
  /* Don't display this container on desktop */
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    display: none;
  }

  & button {
    width: 100%;

    /* Add top-margins to all but the first button */
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
`;

export const DesktopButtons = styled.div<PropsWithTheme<{}>>`
  /* Don't display this container on mobile */
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
    display: none;
  }

  & button {
    min-width: 133px;
    display: inline-block;

    /* Add left-margins to all but the first button */
    &:not(:first-child) {
      margin-left: 12px;
    }
  }
`;

/**
 * Form container that automatically provides submit/back/account home
 *   buttons and functionality. Also fires off events!
 *
 * Figma: https://www.figma.com/file/xOJwjvBP1RFcnViMvWLKsX/Koala-%2F-System-Skin?node-id=25%3A5754
 */
export const NodeForm = ({
  accountId,
  hasSubmit = true,
  canGoBack = true,
  showAccountHomeButton = false,
  onSubmit,
  onBack,
  submitText = 'Continue',
  backText = 'Back',
  accountHomeButtonText = 'Account home',
  children
}: NodeFormProps) => {
  const [hasClickedSubmit, setHasClickedSubmit] = useState(false);

  const backButton = useMemo(() => {
    if (canGoBack) {
      return <OwnUpLineButton onClick={onBack}>{backText}</OwnUpLineButton>;
    }
    return null;
  }, [backText, canGoBack, onBack]);

  const submitButton = useMemo(() => {
    if (hasSubmit) {
      return (
        <OwnUpFillButtonPrimary
          // This button's should always be enabled
          disabled={false}
          type="submit"
          onClick={() => setHasClickedSubmit(true)}
        >
          {submitText}
        </OwnUpFillButtonPrimary>
      );
    }
    return null;
  }, [hasSubmit, submitText, setHasClickedSubmit]);

  const accountHomeButton = useMemo(() => {
    if (showAccountHomeButton) {
      return (
        <OwnUpFillButtonPrimary
          onClick={(e) => {
            e.preventDefault();
            window.location.href = `${process.env.MY_OWNUP_URL}/account/${accountId}`;
          }}
          type="button"
        >
          {accountHomeButtonText}
        </OwnUpFillButtonPrimary>
      );
    }
    return null;
  }, [accountId, accountHomeButtonText, showAccountHomeButton]);

  const submissionWrapper = useCallback(
    (e: SyntheticEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onSubmit?.(e);
    },
    [onSubmit]
  );

  const clickedSubmitContextProviderValue = useMemo(
    () => ({ hasClickedSubmit, setHasClickedSubmit }),
    [hasClickedSubmit, setHasClickedSubmit]
  );

  return (
    <ClickedSubmitContext.Provider value={clickedSubmitContextProviderValue}>
      <form onSubmit={submissionWrapper}>
        {children}
        <ButtonContainer>
          <DesktopButtons>
            {backButton}
            {submitButton}
            {accountHomeButton}
          </DesktopButtons>
          <MobileButtons>
            {accountHomeButton}
            {submitButton}
            {backButton}
          </MobileButtons>
        </ButtonContainer>
      </form>
    </ClickedSubmitContext.Provider>
  );
};
