import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { DownPaymentDataModel } from '../../common/down-payment-node/data-model';
import { DownPaymentNodeImpl } from '../../common/down-payment-node/element';
import { downPaymentPredicate } from '../../common/down-payment-node/predicate';

export const purchaseDownPaymentNodeId = 'down-payment';

export const PurchaseDownPaymentNode = () => {
  const props = useNodeProperties<DownPaymentDataModel>(
    purchaseDownPaymentNodeId,
    downPaymentPredicate
  );

  return (
    <Loader>
      <DownPaymentNodeImpl {...props} />
    </Loader>
  );
};
