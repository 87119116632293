import {
  OwnUpHeadlineProps,
  OwnUpSmallHeadlineBook,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { createContainerWrappedTypography } from './container';

/**
 * Extension of {@see OwnUpHeadlineProps}
 */
export type HeadlineProps = Omit<OwnUpHeadlineProps, 'variant'>;

/**
 * The "Headline" is large attention-grabbing text that may appear
 *   above questions.
 *
 * Figma: https://www.figma.com/file/xOJwjvBP1RFcnViMvWLKsX/Koala-%2F-System-Skin?node-id=3%3A1532
 */
const HeadlineImpl = (props: HeadlineProps) => <OwnUpSmallHeadlineBook {...props} variant="h2" />;

const HeadlineContainer = createContainerWrappedTypography('headline', HeadlineImpl);

export const Headline = styled(HeadlineContainer)`
  /* Wrapping this in a new scope helps prevent css collisions */
  & {
    /* 20px */
    margin-top: 1.25rem;

    & .hint-button {
      /* Add top margin to hint button to ensure it aligns
       *   with text baseline */
      margin-top: 0.5rem;
      ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
        margin-top: 0.75rem;
      }
    }
  }
`;
