export const findNextElement = <T>(element: T, arr: T[]) => {
  const index = arr.indexOf(element);
  if (index !== -1 && arr.length > index + 1) {
    return arr[index + 1];
  } else {
    return null;
  }
};

/**
 * Returns true if all elements in the array are defined
 *
 * @param elements
 */
export const allAreDefined = <T>(elements: T[]) => elements.every((x) => x !== undefined);

/**
 * Returns true if one of the elements in the array is defined
 *
 * @param elements - array
 */
export const atLeastOneDefined = <T>(elements: T[]) => elements.some((x) => x != undefined);

/**
 * Remove the specified element from an array if it exists
 *
 * @param elements - The array holding the element to be removed
 * @param element - The element to be removed
 */
export const removeElement = <T>(elements: T[], element: T) =>
  elements.filter((n) => n !== element);
