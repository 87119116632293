import {
  OwnUpDetail,
  OwnUpDetailProps,
  OwnUpIcon,
  OwnUpIconProps,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import { useCombinedClassName } from '@rategravity/own-up-component-library/hooks/use-combined-class-name';
import { ErrorDarkIcon } from '@rategravity/own-up-component-library/icon-library/notification-icons/error-dark';
import React from 'react';
import styled from 'styled-components';
import { INPUT_GROUP_ITEM_PADDING } from './inputs/input-group-wrapper';

export interface StandaloneErrorProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  iconProps?: Partial<OwnUpIconProps>;
  detailProps?: Partial<OwnUpDetailProps>;
  hideErrorIcon?: boolean;
}

const StandaloneErrorImpl = ({
  className,
  iconProps: { height = '1.5rem', icon = ErrorDarkIcon, ...iconProps } = {},
  detailProps,
  children,
  hideErrorIcon = false,
  ...props
}: StandaloneErrorProps) => {
  const fullClassName = useCombinedClassName('standalone-error', className);
  return (
    <div className={fullClassName} {...props}>
      {!hideErrorIcon && <OwnUpIcon {...iconProps} height={height} icon={icon} />}
      <OwnUpDetail variant="body2" {...detailProps}>
        {children}
      </OwnUpDetail>
    </div>
  );
};

/**
 * A standalone error is a single block of error copy that is
 *   mainly meant to be inserted as part of a Checkbox or
 *   Radio Group component, alerting of the error.
 */
export const StandaloneError = styled(StandaloneErrorImpl)<PropsWithTheme<StandaloneErrorProps>>`
  color: ${({ theme }: PropsWithTheme<StandaloneErrorProps>) => theme.palette.error.main};
  display: flex;
  align-items: center;

  /* Top: 0, Sides: 20px, Bottom: 12px */
  margin: 0 1.25rem 0.75rem;

  .input-group & {
    /* Same margins as above minus the inherent padding from the input group.
       Also add a negative top-margin to reduce vertical spacing of whole
       input group down to 24px/16px. */
    margin: -0.125rem calc(1.25rem - ${INPUT_GROUP_ITEM_PADDING})
      calc(0.75rem - ${INPUT_GROUP_ITEM_PADDING});
  }

  & svg {
    /* 16px to the right of the icon */
    margin-right: 1rem;
  }
`;
