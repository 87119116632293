import {
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpLogoLink,
  OwnUpNavBar,
  OwnUpNavBarProps
} from '@rategravity/own-up-component-library';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useDeconstructedLoginToken } from '../../../hooks/use-deconstructed-login-token';
import { percentCompleteSelector } from '../../../store/questionnaire/selectors';
import { NavBarProgress, NavBarProgressProps } from './nav-bar-progress';
import { NavBarUserMenu, NavBarUserMenuProps } from './nav-bar-user-menu';

export interface NavBarProps
  extends Omit<OwnUpNavBarProps, 'children'>,
    NavBarUserMenuProps,
    NavBarProgressProps {}

const OverlaidNavBar = styled(OwnUpNavBar)`
  z-index: 5;
`;

export const NavBarImpl = ({ accountId, name, percentComplete, ...props }: NavBarProps) => {
  return (
    <OverlaidNavBar {...props}>
      <OwnUpGridItem xs={2} md={4} style={{ margin: 'auto 0' }}>
        <OwnUpLogoLink href="https://ownup.com" />
      </OwnUpGridItem>
      <OwnUpGridOffset xs={!accountId || !name ? 8 : 6} md={4} />
      <OwnUpGridItem xs={!accountId || !name ? 2 : 4}>
        <NavBarUserMenu accountId={accountId} name={name} />
      </OwnUpGridItem>
      <NavBarProgress percentComplete={percentComplete} />
    </OverlaidNavBar>
  );
};

export const NavBar = () => {
  const percentComplete = useSelector(percentCompleteSelector);

  const { name, accountId } = useDeconstructedLoginToken();
  return <NavBarImpl percentComplete={percentComplete} name={name} accountId={accountId} />;
};
