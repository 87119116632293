const piiKeys = new Set([
  'dateOfBirth',
  'address',
  'unit',
  'name',
  'street',
  'email',
  'phone',
  'phoneNumber',
  'hubspotCookie'
]);
const eventKeys = new Set([...piiKeys, 'loading', 'percentComplete', 'lastModifiedTime']);

const filter =
  (filterKeys: Set<string>) =>
  <T extends Record<string, unknown>>(obj: T): T =>
    obj
      ? Object.entries(obj)
          .filter(([key, _]) => !filterKeys.has(key))
          .reduce(
            (newObj, [key, value]) => ({
              ...newObj,
              [key]:
                typeof value === 'object' && value !== null
                  ? filter(filterKeys)(value as Record<string, unknown>)
                  : value
            }),
            {} as T
          )
      : obj;

export const piiFilter = filter(piiKeys);
export const eventsFilter = filter(eventKeys);
