import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { StopNodeImpl } from '../../common/stop-node';
import { StopNodeDataModel } from '../../common/stop-node/helpers';

export const stopRefiLowLoanNodeId = 'stop-low-loan';

export const RefiLowLoanStopNode = () => {
  const props = useNodeProperties<StopNodeDataModel>(stopRefiLowLoanNodeId);
  return <StopNodeImpl {...props} />;
};
