/* eslint-disable @typescript-eslint/naming-convention */
import { createTrackingInstance } from '@rategravity/1pt-lib';
import { SegmentPayload } from '../segment/types';

// For tracking event details, see https://github.com/RateGravity/1PT-lib/blob/main/src/create-tracking-instance.ts

export interface OnePtTrackPayload {
  method: 'track';
  /**
   * The name of the event you’re tracking.
   */
  event: string;
  /**
   * A dictionary of properties for the event. If the event was 'Added to Cart', it might
   *   have properties like price and productType.
   */
  properties?: {};
}

/**
 * The page method lets you record page views on your website, along with optional
 *   extra information about the page being viewed.
 */
export interface OnePtPagePayload {
  method: 'page';
}

export interface OnePtIdentifyPayload {
  method: 'identify';
  /**
   * The user's accountId. Note that 1PT should only identify logged-in users. Anonymous
   *   users can remain anonymous.
   */
  accountId?: string;
  /**
   * A dictionary of properties for the event. If the event was 'Added to Cart', it might
   *   have properties like price and productType.
   */
  properties?: {};
}

export type OnePtPayload = OnePtPagePayload | OnePtTrackPayload | OnePtIdentifyPayload;

export type TrackingEventPayload = OnePtPayload | SegmentPayload;

export const trackingInstance = createTrackingInstance({
  debug: process.env.DEPLOY_ENV === 'staging',
  url: '/events',
  local: process.env.DEPLOY_ENV === 'staging'
});

/**
 * Produces an event payload based on the input data and sends it
 *   to 1pt.
 *
 * @param payload - payload to transform and send
 */
export const sendTo1pt = (payload: TrackingEventPayload) => {
  switch (payload.method) {
    case 'track':
      trackingInstance.track(payload.event, payload.properties);
      break;
    case 'page':
      trackingInstance.page();
      break;
    case 'identify':
      // 1PT only identifies logged-in users
      if ('accountId' in payload && payload.accountId) {
        trackingInstance.identify(payload.accountId, payload.properties);
      }
      break;
    default:
      console.log('Unknown 1pt method');
      return;
  }
};
