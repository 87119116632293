import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { StopNodeImpl } from '../../common/stop-node';
import { StopNodeDataModel } from '../../common/stop-node/helpers';

export const stopAcceptedOfferLowLoanNodeId = 'stop-low-loan';

export const AcceptedOfferLowLoanStopNode = () => {
  const props = useNodeProperties<StopNodeDataModel>(stopAcceptedOfferLowLoanNodeId);
  return <StopNodeImpl {...props} />;
};
