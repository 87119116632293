import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DataModel extends Record<string, unknown> {
  write?: object;
  read?: object;
}
export type DataModelState = Record<string, DataModel>;

// Action payload for update a node's write fields
interface UpdateModelPayload {
  nodeId: string;
  updatedFields: Record<string, unknown>;
}

/**
 * Handles the data model domain. It exposes actions and a reducer for handling
 * update data model actions.
 */
const dataModelSlice = createSlice({
  name: 'dataModels',
  initialState: {} as DataModelState,
  reducers: {
    updateDataModels: (state, action: PayloadAction<Record<string, Record<string, unknown>>>) => ({
      ...state,
      ...action.payload
    }),
    updateDataModel: (
      state,
      { payload: { nodeId, updatedFields } }: PayloadAction<UpdateModelPayload>
    ) => {
      const dataModel = state[nodeId];
      dataModel.write = { ...dataModel.write, ...updatedFields };
    },
    clearDataModel: (state, { payload: nodeId }: PayloadAction<string>) => {
      const dataModel = state[nodeId];
      dataModel.write = {};
    }
  }
});

export const {
  actions: {
    updateDataModels: updateDataModelsAction,
    updateDataModel: updateDataModelAction,
    clearDataModel: clearDataModelAction
  },
  reducer: dataModelReducer
} = dataModelSlice;
