import { OwnUpGridItem } from '@rategravity/own-up-component-library';
import React, { useMemo } from 'react';
import { useClickedSubmitContext } from '../../hooks/use-clicked-submit-context';
import { StandaloneError } from './form/standalone-error';

interface StandaloneErrorProps {
  predicateResult: boolean;
  errorMessage?: string;
}

export const StandaloneErrorWrapper = ({ predicateResult, errorMessage }: StandaloneErrorProps) => {
  const { hasClickedSubmit } = useClickedSubmitContext();
  const result = useMemo(
    () =>
      !predicateResult && hasClickedSubmit ? (
        <OwnUpGridItem xs={12}>
          <StandaloneError>{errorMessage || 'Please make a selection.'}</StandaloneError>
        </OwnUpGridItem>
      ) : (
        <React.Fragment />
      ),
    [predicateResult, hasClickedSubmit, errorMessage]
  );
  return result;
};
