import { createAction } from '@reduxjs/toolkit';
import { QuestionnaireModel } from '../../modules/questionnaire/questionnaire-model';
import { ErrorPayload } from '../../modules/segment/types';

/**
 * Dispatch a request to create a new questionnaire.
 *
 * Will post the passed-in payload.
 */
export const createQuestionnaireRequestAction = createAction<
  Pick<QuestionnaireModel, 'questionnaireType' | 'advisor' | 'pitch' | 'jornayaId'>
>('questionnaire/createRequest');

export type CreateQuestionnaireRequestAction = ReturnType<typeof createQuestionnaireRequestAction>;

/**
 * Indicate that we've successfully created the questionnaire.
 */
export const createQuestionnaireSuccessAction = createAction<{
  model: QuestionnaireModel;
  path: string[];
  dataModels?: Record<string, Record<string, unknown>>;
  percentComplete?: number;
  isComplete?: boolean;
}>('questionnaire/createSuccess');

export type CreateQuestionnaireSuccessAction = ReturnType<typeof createQuestionnaireSuccessAction>;

export const createQuestionnaireFailureAction = createAction<ErrorPayload>(
  'questionnaire/createFailure'
);
export type CreateQuestionnaireFailureAction = ReturnType<typeof createQuestionnaireFailureAction>;
