import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { StopNodeImpl } from '../../common/stop-node';
import { StopNodeDataModel } from '../../common/stop-node/helpers';

export const purchaseInfoNotVerifiedStopNodeId = 'stop-info-not-verified';

export const PurchaseInfoNotVerifiedStopNode = () => {
  const props = useNodeProperties<StopNodeDataModel>(purchaseInfoNotVerifiedStopNodeId);
  return <StopNodeImpl {...props} />;
};
