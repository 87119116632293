import { FOG_20, OwnUpBody, OwnUpSpinner } from '@rategravity/own-up-component-library';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

export const PROCESSING_INFO_MESSAGE = 'Processing your information...';

const Message = styled(OwnUpBody)<{ fadeIn: boolean }>`
  opacity: ${(props) => (props.fadeIn ? 1 : 0)};
  transition: ${(props) => (props.fadeIn ? 'opacity 0.5s' : 'opacity 2s')};
  text-align: center;
  padding: 3em;
  padding-top: 16px;
  width: 100%;
`;

/**
 * Generates an element representing the animated component of
 * the thinking screen.
 * For each message in the thinking screen:
 *   - fade in after 0.25s. Fade-in duration is 0.5s.
 *   - persist. All messages except the last persist for 1s.
 *     The last message persists perpetually.
 *   - fade out, after persistence. Fade-out duration is 0.5s.
 *     (The last message does not fade out)
 * @screenSteps Array of elements. E.g., <h2>MESSAGE<h2>
 *   - takes elements so that the messages can be custom-styled.
 */
const RotatingMessages = ({ messages }: { messages: (JSX.Element | string)[] }) => {
  const [messageToDisplayIndex, setMessageToDisplayIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  useEffect(() => {
    const interval = setInterval(() => {
      if (messageToDisplayIndex < messages.length - 1) {
        if (!fadeIn) {
          setMessageToDisplayIndex(messageToDisplayIndex + 1);
        }
        setFadeIn(!fadeIn);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [messageToDisplayIndex, setMessageToDisplayIndex, setFadeIn, fadeIn, messages.length]);
  return useMemo(
    () => (
      <Message variant="body1" fadeIn={fadeIn} className="rotatingMessages">
        {messages[messageToDisplayIndex]}
      </Message>
    ),
    [messageToDisplayIndex, fadeIn, messages]
  );
};

const ThinkingScreenBox = styled.div`
  background-color: ${FOG_20};
  width: 100%;
  height: 100%;
  min-height: 100vh;
  align-content: center;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ThinkingScreenSpinnerContainer = styled.div`
  height: 44px;
  align-items: center;
  justify-content: center;
  margin-top: calc(50vh - 40px);
`;

const SpinnerContainer = styled.div`
  width: 44px;
  height: 44px;
  align-items: center;
`;

export const ThinkingScreen = ({ messages }: { messages: (JSX.Element | string)[] }) => {
  return useMemo(
    () => (
      <ThinkingScreenBox>
        <ThinkingScreenSpinnerContainer>
          <SpinnerContainer>
            <OwnUpSpinner />
          </SpinnerContainer>
        </ThinkingScreenSpinnerContainer>
        <RotatingMessages messages={messages} />
      </ThinkingScreenBox>
    ),
    [messages]
  );
};
