import { PropertyTypeDataModel } from './data-model';

export const propertyNodePredicate = ({
  read: { askFees, permittedTypes },
  write: { propertyType, condoFee }
}: PropertyTypeDataModel) => {
  if (
    // Only check fees if required by node
    askFees &&
    // Only check fees if condo or townhome
    (propertyType === 'Condo' || propertyType === 'Townhome') &&
    // Fee must be defined and >= 0
    (condoFee === undefined || condoFee < 0)
  ) {
    return false;
  }
  // Pass if a permitted type is selected or if on staging
  return !!(
    propertyType &&
    (permittedTypes.includes(propertyType) || process.env.DEPLOY_ENV !== 'prod')
  );
};
