import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { StepUpConfirmCodeDataModel } from '../../common/step-up-confirm-code-node/data-model';
import { StepUpConfirmCodeNodeImpl } from '../../common/step-up-confirm-code-node/element';
import { stepUpConfirmCodePredicate } from '../../common/step-up-confirm-code-node/predicate';

export const acceptedOfferStepUpConfirmCodeNodeId = 'step-up-confirm-code';

export const AcceptedOfferStepUpConfirmCodeNode = () => {
  const props = useNodeProperties<StepUpConfirmCodeDataModel>(
    acceptedOfferStepUpConfirmCodeNodeId,
    stepUpConfirmCodePredicate
  );
  return (
    <Loader>
      <StepUpConfirmCodeNodeImpl {...props} />
    </Loader>
  );
};
