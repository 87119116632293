import { GridSize } from '@material-ui/core/Grid';
import {
  Breakpoint,
  OwnUpGridItem,
  OwnUpGridItemProps,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import { useCombinedClassName } from '@rategravity/own-up-component-library/hooks/use-combined-class-name';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

export type InputWrapperSizes = 'full' | 'half' | 'single' | 'double';

export interface InputWrapperProps extends Omit<OwnUpGridItemProps, Breakpoint> {
  /**
   * Size of this input wrapper. Set to 'single' for a
   *   single input item on one line. Set to 'full' for
   *   a single input item that covers the full width
   *   of its container. Set to 'half' for a single
   *   input item that covers half of its parent container.
   *   Set to 'double' for an input item that covers twice
   *   the width of a 'single'/'half' item (differs from
   *   full in that it may have right-padding at certain
   *   breakpoints.)
   */
  size?: InputWrapperSizes;
}

const SIZE_DICTIONARY: Record<InputWrapperSizes, Partial<Record<Breakpoint, GridSize>>> = {
  single: {
    xs: 12,
    sm: 10,
    md: 6
  },
  double: {
    xs: 12,
    sm: 10,
    md: 12
  },
  half: {
    xs: 12,
    sm: 10,
    md: 6
  },
  full: {
    xs: 12
  }
};

const InputWrapperImpl = ({ className, size = 'single', ...props }: InputWrapperProps) => {
  const fullClassName = useCombinedClassName('input-wrapper', `input-wrapper--${size}`, className);
  const sizeProps = useMemo(() => SIZE_DICTIONARY[size], [size]);
  return <OwnUpGridItem className={fullClassName} {...props} {...sizeProps} />;
};

/**
 * Adds right-side padding on desktop screens to ensure the grid
 *   does not try to add the next item to its right.
 */
const singleMixin = css<PropsWithTheme<InputWrapperProps>>`
  ${({ theme }: PropsWithTheme<InputWrapperProps>) => theme.breakpoints.up('md')} {
    margin-right: 50%;
  }
`;

/**
 * Wrapper around {@see OwnUpGridItem} that will size itself in the grid
 *   based on the input `size` you provide it.
 * If no `size` is provided, defaults to `half`
 *
 * @param props - {@see InputWrapperProps}
 */
export const InputWrapper = styled(InputWrapperImpl)<PropsWithTheme<InputWrapperProps>>`
  /* If this is a "single" size input, add the singleMixin */
  ${({ size }: PropsWithTheme<InputWrapperProps>) => (size === 'single' ? singleMixin : null)}
`;
