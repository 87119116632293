import React from 'react';

/**
 * Shield Icon used for "Feedback" sections.
 */
export const ShieldIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5957 11.4407C16.415 10.8531 15.5851 10.8531 15.4044 11.4407L14.5174 14.3245H11.6246C11.0287 14.3245 10.7725 15.0821 11.2456 15.4452L13.6137 17.2627L12.7138 20.1884C12.535 20.7698 13.2065 21.2383 13.6884 20.8685L16 19.0943L18.3117 20.8685C18.7936 21.2383 19.4651 20.7698 19.2863 20.1884L18.3864 17.2627L20.7545 15.4452C21.2275 15.0821 20.9714 14.3245 20.3755 14.3245H17.4827L16.5957 11.4407Z"
      fill="#3E6D6A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.608 4.17559C16.6324 3.55141 15.3676 3.55141 14.3921 4.17559C12.8715 5.14857 10.0147 6.82917 6.58124 8.14557C5.50736 8.5573 4.74779 9.58415 4.75148 10.7713C4.76756 15.9539 4.94391 19.8394 7.5358 23.1307C8.90702 24.8719 10.5617 26.2606 12.0755 27.2173C12.833 27.6961 13.5653 28.0731 14.2203 28.3327C14.8606 28.5864 15.4815 28.75 16 28.75C16.5642 28.75 17.2529 28.5286 17.9335 28.2276C18.6388 27.9158 19.4249 27.4776 20.2125 26.9602C21.7782 25.9316 23.4234 24.5412 24.4784 23.1122C26.4416 20.453 27.1346 16.0931 27.2366 10.7829C27.2596 9.58473 26.4901 8.5563 25.4188 8.14557C21.9854 6.82917 19.1286 5.14858 17.608 4.17559ZM15.2006 5.43909C15.6832 5.13025 16.3168 5.13025 16.7995 5.43909C18.3697 6.44376 21.3205 8.18071 24.8818 9.54615C25.4116 9.74929 25.7467 10.238 25.7368 10.7542C25.6349 16.0633 24.9262 19.9802 23.2717 22.2212C22.3579 23.4588 20.8675 24.7351 19.3889 25.7065C18.6543 26.1891 17.941 26.5842 17.327 26.8558C16.6883 27.1381 16.2378 27.25 16 27.25C15.7576 27.25 15.3418 27.1636 14.7729 26.9382C14.2189 26.7186 13.5697 26.3873 12.8769 25.9494C11.4899 25.0728 9.96804 23.7948 8.71426 22.2027C6.48641 19.3737 6.26776 16.0152 6.25147 10.7666C6.24983 10.2391 6.59103 9.74829 7.11823 9.54616C10.6796 8.18071 13.6304 6.44376 15.2006 5.43909Z"
      fill="#3E6D6A"
    />
  </svg>
);
