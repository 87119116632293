import { OwnUpDetail, OwnUpUnderlineLink, SLATE_100 } from '@rategravity/own-up-component-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  emailPredicate,
  namePredicate,
  phonePredicate
} from '../../../../modules/shared-predicates';
import { sendToTrackingEventAction } from '../../../../store/events/actions';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { AdornedNumberInput } from '../../../shared/inputs/adorned-number-input';
import { AdornedTextInput } from '../../../shared/inputs/adorned-text-input';
import { Awareness } from '../../../shared/typography/awareness';
import { Headline } from '../../../shared/typography/headline';
import { Overline } from '../../../shared/typography/overline';
import { NodeProperties } from '../../properties';
import { EmailAndPhoneDataModel } from './data-model';

export interface EmailAndPhoneNodeProps extends NodeProperties<EmailAndPhoneDataModel> {
  reasoningExperimentOverride?: boolean;
}

const ConsentText = styled.div`
  margin-top: 12px;
  color: ${SLATE_100};
`;

const PhoneConsentCopy = () => (
  <label style={{ color: SLATE_100, display: 'block' }}>
    {/* This hidden input fields causes Jornaya to capture the language in the label as TCPA disclosure text */}
    <input type="hidden" id="leadid_tcpa_disclosure" />
    By providing your contact information and clicking on the &quot;I agree and continue&quot;
    button, you acknowledge, agree and are providing express &quot;written&quot; consent by
    electronic signature to receive telephonic sales, promotional, marketing, and other calls and
    text messages from Own Up Real Estate Services, LLC (&quot;Own Up RE&quot;), Own Up RE
    participating providers (&quot;
    <OwnUpUnderlineLink link="https://www.ownup.com/providers/" target="_blank" rel="noreferrer">
      RE Providers
    </OwnUpUnderlineLink>
    &quot;), RateGravity, Inc. d/b/a Own Up (&quot;Own Up&quot;) and Own Up&apos;s Marketplace
    participating lenders (&quot;
    <OwnUpUnderlineLink link="https://www.ownup.com/providers/" target="_blank" rel="noreferrer">
      Providers
    </OwnUpUnderlineLink>
    &quot;). This includes any calls and messages sent by any automated system or other means for
    the selection or dialing of telephone numbers or using an artificial or prerecorded voice
    message when a connection is completed from a Provider or RE Provider at the telephone number
    you have provided, even if that telephone number is on a corporate, state, or national
    do-not-call list or registry. You may revoke your consent at any time. Your consent and
    agreement to receive such calls or text messages is not a condition of purchasing any property,
    goods, or services from us or any of our Providers and RE Providers. Please contact us at{' '}
    <OwnUpUnderlineLink link="mailto:privacy@ownup.com">privacy@ownup.com</OwnUpUnderlineLink> if
    you have any questions.
  </label>
);

export const EmailAndPhoneNodeImpl = ({
  dataModel: {
    read: { hasAccount },
    write: { email, phoneNumber, phoneMarketingConsent, name }
  },
  onChangeFactory,
  onSubmit,
  dispatch,
  nodeId,
  questionnaireType,
  reasoningExperimentOverride,
  splitClient,
  ...props
}: EmailAndPhoneNodeProps) => {
  const [initialEmail] = useState(email);
  const [initialPhone] = useState(phoneNumber);

  const handleChange = useCallback(
    (field: 'email' | 'phoneNumber' | 'name') => (value: string) => {
      const trimmedValue = field !== 'name' ? value.trim() : value;
      onChangeFactory<string>(field, nodeId)(trimmedValue, undefined);
    },
    [onChangeFactory, nodeId]
  );

  // Disable the email/phone fields only if logged in and email/phone exists
  // Need to check the initial value, otherwise once anything is typed
  // the field gets disabled.
  const disableEmail = useMemo(
    () => !!(hasAccount || props.accountId) && !!initialEmail,
    [props.accountId, hasAccount, initialEmail]
  );
  const disablePhone = useMemo(
    () => !!(hasAccount || props.accountId) && !!initialPhone,
    [props.accountId, hasAccount, initialPhone]
  );

  const phoneMarketingConsentChanged = onChangeFactory<boolean>('phoneMarketingConsent', nodeId);

  useEffect(() => {
    if (!phoneMarketingConsent) {
      phoneMarketingConsentChanged(true, phoneMarketingConsent);
    }
  }, [phoneMarketingConsent, phoneMarketingConsentChanged]);

  const onEmailAndPhoneSubmit = useCallback(() => {
    const events = [
      'Email_Collected',
      'onboarding_email_collected',
      'Phone_Collected',
      'onboarding_phone_collected'
    ];
    events.forEach((event) =>
      dispatch(
        sendToTrackingEventAction({
          method: 'track',
          event,
          properties: {
            nodeId,
            questionnaireType
          }
        })
      )
    );
    handleChange('email')(email?.toLowerCase() || '');
    onSubmit();
  }, [dispatch, onSubmit, nodeId, questionnaireType, email, handleChange]);

  return (
    <NodeContainer {...props}>
      <NodeForm
        nodeId={nodeId}
        onSubmit={onEmailAndPhoneSubmit}
        submitText="I agree and continue"
        {...props}
      >
        <Overline>{props.sectionTitle}</Overline>
        <Headline>Let&apos;s explore mortgage options</Headline>
        <Awareness>
          Please share your contact details to verify it&apos;s really you. Rest assured, your
          privacy is our priority.
        </Awareness>
        <InputGroupWrapper>
          <AdornedTextInput
            wrapperSize="single"
            type="text"
            label="First and last name"
            className="fs-mask private"
            value={name}
            textInputPredicate={namePredicate(name)}
            errorText="Please enter a first and last name."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange('name')(e.target.value)
            }
          />
          <AdornedTextInput
            wrapperSize="single"
            type="email"
            label="Email address"
            className="fs-mask private"
            value={email}
            textInputPredicate={emailPredicate({ write: { email } })}
            disabled={disableEmail}
            errorText="Please enter a valid email address."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange('email')(e.target.value)
            }
          />
          <AdornedNumberInput
            wrapperSize="single"
            type="tel"
            label="Phone number"
            className="fs-mask private"
            value={phoneNumber as string | undefined}
            numberInputPredicate={phonePredicate(phoneNumber as string)}
            disabled={disablePhone}
            errorText="Please provide a valid phone number."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange('phoneNumber')(e.target.value);
            }}
            placeholder="(___) ___-____"
            allowNegative={false}
            decimalScale={0}
            allowLeadingZeros={true}
            mask="_"
            format="###-###-####"
          />
        </InputGroupWrapper>
        <ConsentText>
          <OwnUpDetail>
            By clicking “I agree and continue,” you agree to the terms and conditions below.
          </OwnUpDetail>
        </ConsentText>
      </NodeForm>
      <div style={{ marginTop: '12px' }}>
        <OwnUpDetail>
          <PhoneConsentCopy />
        </OwnUpDetail>
      </div>
    </NodeContainer>
  );
};
