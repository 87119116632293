import { browserInit } from '@rategravity/frontend/modules/segment';
import {
  Action,
  identify as createIdentifyEvent,
  pageView as createPageViewEvent,
  event as createTrackingEvent
} from '@rategravity/frontend/modules/user-actions';
import * as Sentry from '@sentry/react';
import Rx from 'rxjs/Rx';
import { SegmentPayload, SerializableError } from './types';

declare global {
  interface Window {
    analytics?: {
      ready: (callback: () => void) => void;
    };
  }
}

let segmentSubject: Rx.Subject<Action>;
/**
 * Retrieves the event pipeline, or creates it if it
 *   doesn't exist.
 *
 * By declaring this as an instanced singleton we can
 *   avoid producing this pipeline if the user has
 *   adblockers on or tracking off.
 */
const getSegmentTopic = () => {
  if (!segmentSubject) {
    segmentSubject = new Rx.Subject<Action>();
    browserInit(segmentSubject);
  }
  return segmentSubject;
};

/**
 * Produces an event payload based on the input data and sends it
 *   to segment.
 *
 * @param payload - payload to transform and send
 */
export const sendToSegment = (payload: SegmentPayload) => {
  if (window.analytics) {
    window.analytics.ready(() => {
      let action: Action;
      switch (payload.method) {
        case 'track':
          action = createTrackingEvent(payload.event, payload.properties);
          break;
        case 'identify':
          action = createIdentifyEvent(payload.userId, payload.traits);
          break;
        case 'page':
          action = createPageViewEvent(window.location.href, payload.properties);
          break;
        default:
          console.log('Unknown segment method');
          return;
      }
      getSegmentTopic().next(action);
    });
  } else {
    console.log('Segment object not found');
  }
};

/**
 * Send an error directly to sentry.
 *
 * @param error - Error payload to send
 */
export const sendToSentry = (error: SerializableError) => {
  if (process.env.SENTRY_DSN) {
    // HACK: Sentry cannot properly report non-exceptions, but redux
    //   cannot handle raw exceptions because they're not serializable.
    //   To remedy this, we produce a new error in-line and rehydrate
    //   it with the fields.
    const builtError = new Error(error.message);
    Object.entries(error).forEach(([key, value]) => {
      (builtError as any)[key] = value;
    });
    Sentry.captureException(builtError);
  } else {
    console.error(`No sentry instance found to send ${error.name}`);
  }
};
