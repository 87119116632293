import { OwnUpSpinner, OwnUpUnderlineLink } from '@rategravity/own-up-component-library';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { sendVerificationCode } from '../../../../modules/api-handlers';
import { questionnaireIdSelector } from '../../../../store/questionnaire/selectors';
import { userIdSelector } from '../../../../store/questionnaire/selectors';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { AdornedNumberInput } from '../../../shared/inputs/adorned-number-input';
import { Description } from '../../../shared/typography/description';
import { Headline } from '../../../shared/typography/headline';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { StepUpConfirmCodeDataModel } from './data-model';

const MAX_ATTEMPTS_ERROR =
  "You've reached the maximum number of attempts. Please try again in 10 minutes.";
const LoadingContainer = styled.div`
  margin-top: 1rem;
`;
type DescriptionState = 'default' | 'loading' | 'success' | 'maxAttemptsError' | 'generalError';
const NewCodeComponent = ({ state, onClick }: { state: DescriptionState; onClick: () => void }) => {
  switch (state) {
    case 'loading':
      return (
        <LoadingContainer>
          <OwnUpSpinner style={{ width: '1.5rem', height: '1.5rem' }} />
        </LoadingContainer>
      );
    case 'success':
      return <Description>A new code has been sent!</Description>;
    case 'maxAttemptsError':
      return <Description>{MAX_ATTEMPTS_ERROR}</Description>;
    case 'generalError':
      return <Description>An error occurred when sending your confirmation code.</Description>;
    default:
      return (
        <Description>
          Didn&apos;t receive a code after 30 seconds?{' '}
          <OwnUpUnderlineLink onClick={onClick}>Click here to send it again.</OwnUpUnderlineLink>
        </Description>
      );
  }
};

export const StepUpConfirmCodeNodeImpl = ({
  dataModel: {
    read: { error, sendCodeVerificationMethod },
    write: { verificationCode }
  },
  onChangeFactory,
  nodeId,
  ...props
}: NodeProperties<StepUpConfirmCodeDataModel>) => {
  const handleChange = useCallback(
    (value: string) => {
      onChangeFactory('verificationCode', nodeId)(value === '' ? undefined : value, undefined);
    },
    [onChangeFactory, nodeId]
  );
  const [newCodeState, setNewCodeState] = useState<DescriptionState>('default');
  const userId = useSelector(userIdSelector);
  const questionnaireId = useSelector(questionnaireIdSelector);

  const onNewCodeClick = useCallback(async () => {
    setNewCodeState('loading');
    const res = await sendVerificationCode(userId as string, questionnaireId as string);
    if (res.sent) {
      setNewCodeState('success');
    } else {
      setNewCodeState(
        'error' in res && res.error === 'Max send attempts reached'
          ? 'maxAttemptsError'
          : 'generalError'
      );
    }
    setTimeout(() => setNewCodeState('default'), 20000);
  }, [setNewCodeState, userId, questionnaireId]);
  return (
    <NodeContainer {...props}>
      <NodeForm
        nodeId={nodeId}
        {...props}
        hasSubmit={
          // this error comes from the backend data model
          error !== MAX_ATTEMPTS_ERROR
        }
      >
        <Overline>{props.sectionTitle}</Overline>
        <Headline>Please enter your verification code.</Headline>
        <Question>
          You should receive a {sendCodeVerificationMethod === 'call' ? 'call' : 'text'} shortly.
        </Question>
        <InputGroupWrapper>
          <AdornedNumberInput
            wrapperSize="single"
            label="Verification code"
            className="fs-mask private"
            value={verificationCode}
            numberInputPredicate={verificationCode !== undefined && !error}
            errorText={error || 'Please provide a valid code.'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e.target.value);
            }}
            allowNegative={false}
            decimalScale={0}
            allowLeadingZeros={true}
            thousandSeparator={false}
          />
        </InputGroupWrapper>
        <NewCodeComponent
          onClick={() => {
            void onNewCodeClick();
          }}
          state={newCodeState}
        />
      </NodeForm>
    </NodeContainer>
  );
};
