import {
  OwnUpBodyProps,
  OwnUpIcon,
  OwnUpIconProps,
  OwnUpSmallBody
} from '@rategravity/own-up-component-library';
import * as colors from '@rategravity/own-up-component-library/colors';
import { useCombinedClassName } from '@rategravity/own-up-component-library/hooks/use-combined-class-name';
import React from 'react';
import styled from 'styled-components';
import { ShieldIcon } from '../icons/shield-icon';
import { createContainerWrappedTypography } from './container';

/**
 * Extension of {@see OwnUpSmallBodyProps}
 */
export interface AwarenessProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  iconProps?: OwnUpIconProps;
  typographyProps?: Omit<OwnUpBodyProps, 'variant' | 'children'>;
}

const AwarenessImpl = ({
  children,
  iconProps: {
    className: iconClassName,
    height = '2.0rem',
    color = colors.ALOE_100,
    icon = ShieldIcon,
    ...iconProps
  } = {} as OwnUpIconProps,
  typographyProps: { className: typographyClassName, ...typographyProps } = {},
  ...props
}: AwarenessProps) => {
  const fullIconClassName = useCombinedClassName('awareness-icon', iconClassName);
  const fullTypographyClassName = useCombinedClassName('awareness-typography', typographyClassName);
  return (
    <div {...props}>
      <OwnUpIcon
        className={fullIconClassName}
        height={height}
        color={color}
        icon={icon}
        {...iconProps}
      />
      <OwnUpSmallBody className={fullTypographyClassName} {...typographyProps} variant="body1">
        {children}
      </OwnUpSmallBody>
    </div>
  );
};

const AwarenessContainer = createContainerWrappedTypography('awareness-container', AwarenessImpl, {
  size: 'half'
});

/**
 * "Awareness" is typically ahead of a user's input to let them know about why
 *   they're about to do something.
 *
 * Figma: https://www.figma.com/file/5eLxhnyCojROIRTbFJkCpO/questionnaire-recs?type=design&node-id=27-5849&mode=design&t=TW0ZDxKpWMwkQaNe-0
 */
export const Awareness = styled(AwarenessContainer)`
  margin-top: 0.5rem;

  .awareness-container {
    display: flex;

    & .ou-icon {
      margin-right: 0.5rem;
    }
  }
`;
