import React from 'react';
import { stateLookUp } from '../../modules/state-helpers';
import { Feedback } from './typography/feedback';

export interface StateLightbulbTextProps {
  state?: string;
  permittedStates: string[];
}

export const StateLightbulbText = ({ state, permittedStates }: StateLightbulbTextProps) => {
  const stateName = state ? stateLookUp[state] : undefined;
  if (!state || !stateName) {
    return null;
  }
  if (!permittedStates.includes(state)) {
    return null;
  }

  return (
    <Feedback>
      Great! We&apos;ve got <strong>{stateName}</strong> covered by our exclusive marketplace of top
      local and national lenders.
    </Feedback>
  );
};
