import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { PurchasePriceDataModel } from '../../common/purchase-price-node/data-model';
import { PurchasePriceNodeImpl } from '../../common/purchase-price-node/element';
import { purchasePricePredicate } from '../../common/purchase-price-node/predicate';

export const purchasePurchasePriceNodeId = 'purchase-price';

export const PurchasePurchasePriceNode = () => {
  const props = useNodeProperties<PurchasePriceDataModel>(
    purchasePurchasePriceNodeId,
    purchasePricePredicate
  );

  return (
    <Loader>
      <PurchasePriceNodeImpl
        {...props}
        headerText="What is the price of the property you're looking to purchase?"
      />
    </Loader>
  );
};
