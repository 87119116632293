import { getCookie } from '@rategravity/frontend/modules/hubspot';
import { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { START_NEW_QUESTIONNAIRE_TOKEN } from '../components/pages/incomplete-questionnaire';
import { trackingInstance } from '../modules/1pt';
import {
  queryIncompleteQuestionnaires,
  QueryIncompleteQuestionnairesResult
} from '../modules/api-handlers';
import { getDecodedLoginToken } from '../modules/authentication';
import { getJornayaId, JornayaResponse } from '../modules/get-jornaya-id';
import { RouteParams } from '../modules/path-helpers';
import { initializeSplitClient } from '../modules/split';
import { convertPitchToBool } from '../modules/utils';
import { showIncompleteQuestionnairePageAction } from '../store/display/slice';
import { queryParametersSelector } from '../store/path/selectors';
import { createQuestionnaireRequestAction } from '../store/questionnaire/create-actions';
import { fetchQuestionnaireRequestAction } from '../store/questionnaire/fetch-actions';
import { saveQuestionnaireParamsRequestAction } from '../store/questionnaire/save-actions';
import {
  questionnaireIdSelector,
  questionnaireTypeSelector,
  userIdSelector
} from '../store/questionnaire/selectors';
import { updateQuestionnaireAction } from '../store/questionnaire/slice';
import { advisorIdKey, pitchKey } from '../store/questionnaire/state';

interface TriggerPageLoadActionArgs {
  accountId?: string;
  userId?: string;
  jornayaResponse: JornayaResponse;
  questionnaireId?: string;
  questionnaireType: string;
  existingQuestionnaireType?: string;
  pitch?: string;
  advisor?: string;
  nodeId?: string;
  startNewQuestionnaire?: boolean;
  incompleteQuestionnaires: QueryIncompleteQuestionnairesResult[];
  dispatch: Dispatch<any>;
}

export const triggerPageLoadActions = async ({
  accountId,
  userId,
  jornayaResponse: { id: jornayaId, updated: shouldUpdateJornayaId },
  questionnaireId,
  questionnaireType,
  existingQuestionnaireType,
  pitch,
  advisor,
  nodeId,
  startNewQuestionnaire,
  incompleteQuestionnaires,
  dispatch
}: TriggerPageLoadActionArgs) => {
  // If userId and questionnaireId are known, and the questionnaire type remains the same...
  if (userId && questionnaireId && questionnaireType === existingQuestionnaireType) {
    // ... simply fetch (or update with new params) your questionnaire.
    const payload = {
      questionnaireType,
      jornayaId,
      pitch: convertPitchToBool(pitch),
      ...(advisor && { advisor })
    };

    const shouldUpdateAdvisor = advisor && advisor !== sessionStorage.getItem(advisorIdKey);
    const shouldUpdatePitch = pitch !== undefined && pitch !== sessionStorage.getItem(pitchKey);

    if (shouldUpdateAdvisor || shouldUpdatePitch || shouldUpdateJornayaId) {
      dispatch(updateQuestionnaireAction(payload));
      dispatch(saveQuestionnaireParamsRequestAction(payload));
    } else {
      dispatch(updateQuestionnaireAction({ questionnaireType }));
      dispatch(fetchQuestionnaireRequestAction({ nodeId }));
    }
  } else if (accountId && incompleteQuestionnaires.length > 0 && !startNewQuestionnaire) {
    // If there is an incomplete questinnaire associated with this user show incomplete questionnaire page.
    incompleteQuestionnaires.sort((a, b) => b.lastModifiedTime - a.lastModifiedTime);
    dispatch(
      showIncompleteQuestionnairePageAction({
        oldQuestionnaireType: incompleteQuestionnaires[0].questionnaireType
      })
    );
  } else {
    // ... otherwise, request a new questionnaire be created.
    const hubspotCookie = getCookie();
    const payload = {
      userId,
      questionnaireType,
      jornayaId,
      pitch: convertPitchToBool(pitch),
      ...(advisor && { advisor }),
      ...(hubspotCookie && {
        primaryBorrower: {
          hubspotCookie
        }
      })
    };
    dispatch(updateQuestionnaireAction(payload));
    dispatch(createQuestionnaireRequestAction(payload));
    // Remove start new questionnaire token
    localStorage.removeItem(START_NEW_QUESTIONNAIRE_TOKEN);
  }
};

export const useQuestionnaireInitializer = () => {
  // Fetch dispatcher
  const dispatch = useDispatch();

  // Fetch questionnaire type from params, it may be
  //    needed to init the redux store
  const { questionnaireType, nodeId } = useParams<RouteParams>();

  // Fetch the ids from the redux store
  const userId = useSelector(userIdSelector);
  initializeSplitClient(trackingInstance.getAnonymousId() || userId || '');
  const questionnaireId = useSelector(questionnaireIdSelector);
  const existingQuestionnaireType = useSelector(questionnaireTypeSelector);
  const { advisor, pitch } = useSelector(queryParametersSelector);
  const startNewQuestionnaire = localStorage.getItem(START_NEW_QUESTIONNAIRE_TOKEN) === 'true';

  // Trigger page load actions when the redux state is settled
  useEffect(() => {
    void (async () => {
      const { accountId } = await getDecodedLoginToken();
      const jornayaResponse = await getJornayaId();
      let incompleteQuestionnaires: QueryIncompleteQuestionnairesResult[] = [];
      if (accountId && userId) {
        incompleteQuestionnaires = await queryIncompleteQuestionnaires({ userId });
      }
      await triggerPageLoadActions({
        accountId,
        userId,
        jornayaResponse,
        questionnaireId,
        questionnaireType,
        existingQuestionnaireType,
        pitch,
        advisor,
        nodeId,
        startNewQuestionnaire,
        incompleteQuestionnaires,
        dispatch
      });
    })();
    // This only wants to trigger once on page load, so no dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
