import { OwnUpSpinner } from '@rategravity/own-up-component-library';
import React from 'react';

const stylingInitialLoading = {
  width: '100%'
};

export const Loading = ({
  useLoadingSpinnerStyle = false
}: {
  useLoadingSpinnerStyle?: boolean;
}) => {
  return (
    <div
      style={{
        height: '80vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...(useLoadingSpinnerStyle ? stylingInitialLoading : {})
      }}
    >
      <OwnUpSpinner />
    </div>
  );
};
