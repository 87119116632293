import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback } from 'react';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { RealEstateAgentDataModel } from './data-model';

const mappedOptions = [
  { display: 'Yes', value: true },
  { display: 'No', value: false }
].map(({ display, value }) => (
  <InputWrapper key={display} size="single">
    <OwnUpRadioButton value={String(value)}>{display}</OwnUpRadioButton>
  </InputWrapper>
));

export const RealEstateAgentNodeImpl = ({
  dataModel: {
    write: { hasAgent }
  },
  onChangeFactory,
  questionnaireType,
  splitClient,
  ...props
}: NodeProperties<RealEstateAgentDataModel>) => {
  const changeWrapper = onChangeFactory<boolean>('hasAgent', props.nodeId);
  const onChange = useCallback(
    (_: any, newValue?: string) => changeWrapper(newValue === 'true', undefined),
    [changeWrapper]
  );

  return (
    <NodeContainer>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>Are you working with a real estate agent?</Question>
        <OwnUpRadioGroup name="Real Estate Agent" value={String(hasAgent)} onChange={onChange}>
          <InputGroupWrapper>
            <StandaloneErrorWrapper predicateResult={radioButtonPredicate(hasAgent)} />
            {mappedOptions}
          </InputGroupWrapper>
        </OwnUpRadioGroup>
      </NodeForm>
    </NodeContainer>
  );
};
