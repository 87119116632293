import React, { useCallback } from 'react';
import { AddressModel } from '../../../../modules/address-helpers';
import { states } from '../../../../modules/state-helpers';
import { FullAddress } from '../../../shared/address/full-address';
import { NodeContainer } from '../../../shared/container';
import { NodeForm } from '../../../shared/form/node-form';
import { Description } from '../../../shared/typography/description';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { PropertyAddressNodeProperties } from './node-properties';

export const PropertyAddressNodeImpl = ({
  header,
  dataModel: {
    read: {},
    write: { property }
  },
  onChangeFactory,
  splitClient,
  ...props
}: PropertyAddressNodeProperties) => {
  const { state, address, unit, city, zip, county } = property;
  const propertyChangeDispatcher = onChangeFactory<object>('property', props.nodeId);
  const handlePropertyFullAddressChange = useCallback(
    (newValue: AddressModel | undefined) => {
      propertyChangeDispatcher({ ...property, ...newValue }, undefined);
    },
    [propertyChangeDispatcher, property]
  );

  const handlePropertyFieldChange = useCallback(
    (field: keyof typeof property) => (newValue: string | undefined) => {
      propertyChangeDispatcher(
        {
          ...property,
          [field]: newValue
        },
        undefined
      );
    },
    [property, propertyChangeDispatcher]
  );

  return (
    <NodeContainer {...props}>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        {header ? <Question>{header}</Question> : undefined}
        <Description>
          This helps us give accurate monthly payment estimates based on your new property&apos;s
          value and taxes.
        </Description>
        <FullAddress
          addressModel={{
            address,
            unit,
            city,
            state,
            zip,
            county
          }}
          onAutocompleteChange={handlePropertyFullAddressChange}
          onAddressChange={handlePropertyFieldChange('address')}
          onUnitChange={handlePropertyFieldChange('unit')}
          onCityChange={handlePropertyFieldChange('city')}
          onStateChange={handlePropertyFieldChange('state')}
          onZipChange={handlePropertyFieldChange('zip')}
          onCountyChange={handlePropertyFieldChange('county')}
          id="property-address"
          states={states}
          {...props}
        />
      </NodeForm>
    </NodeContainer>
  );
};
