import {
  OwnUpComponentLibraryProvider,
  ownUpWhiteTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { errorStateSelector } from '../store/display/selectors';
import { ErrorPage } from './pages/error';
import { OnboardingRouter } from './router';

export const App = () => {
  const errorState = useSelector(errorStateSelector);
  return (
    <React.Fragment>
      <Helmet>
        <title>Onboarding</title>
      </Helmet>
      <OwnUpComponentLibraryProvider theme={ownUpWhiteTheme}>
        {errorState ? <ErrorPage {...errorState} /> : <OnboardingRouter />}
      </OwnUpComponentLibraryProvider>
    </React.Fragment>
  );
};
