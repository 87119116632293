import React from 'react';
import { useSelector } from 'react-redux';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { questionnaireIdSelector, userIdSelector } from '../../../../store/questionnaire/selectors';
import { Loader } from '../../../shared/container';
import { ConsultationNextStepsNodeImpl } from '../../common/consultation-next-stops-node/element';
import { NextStepsDataModel } from '../../common/next-steps-node/data-model';

export const acceptedOfferConsultationNextStepsNodeId = 'consultation-next-steps';

export const AcceptedOfferConsultationNextStepsNode = () => {
  const questionnaireId = useSelector(questionnaireIdSelector);
  const userId = useSelector(userIdSelector);
  const props = useNodeProperties<NextStepsDataModel>(acceptedOfferConsultationNextStepsNodeId);
  return (
    <Loader>
      <ConsultationNextStepsNodeImpl userId={userId} questionnaireId={questionnaireId} {...props} />
    </Loader>
  );
};
