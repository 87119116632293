import * as Sentry from '@sentry/react';
import { decode } from 'jsonwebtoken';
import { trackingInstance } from '../1pt';

export const TOKEN_ID = 'OWNUP_LOGIN_TOKEN';

/**
 * Checks local storage and returns the loginToken.
 *   If this user is under the my.ownup.com domain,
 *   it will be able to retrieve the token from
 *   the other site's session data.
 *
 * Presented as a promise: though it is synchronous now
 *   future workflows will likely involve async operations
 *   such as a hit to Auth0. Simply coercing this to
 *   a promise for now to future-proof our repo.
 */
export const getLoginToken = async (): Promise<string> => localStorage.getItem(TOKEN_ID) || '';

export interface DecodedToken extends Record<string, unknown> {
  accountId?: string;
  email?: string;
  name?: string;
}

/**
 * Retrieves a decoded version of the user's login token
 *   (if present)
 */
export const getDecodedLoginToken = async (): Promise<DecodedToken> => {
  const token = await getLoginToken();
  const decoded = decode(token)!;
  if (!decoded || typeof decoded !== 'object') {
    return {};
  }
  return { accountId: decoded.sub, ...decoded };
};

/**
 * Logout from my.ownup.com
 *
 * Presented as a promise: though it is synchronous now
 *   future workflows will likely involve async operations
 *   such as a hit to Auth0. Simply coercing this to
 *   a promise for now to future-proof our repo.
 */
export const logout = async (): Promise<void> => {
  localStorage.removeItem(TOKEN_ID);
  if (process.env.SENTRY_DSN) {
    Sentry.setUser(null);
    Sentry.setTag('user.userId', undefined);
    Sentry.setTag('user.accountId', undefined);
  }
  trackingInstance.reset();
  // Loading into this page will send you to auth0's logout workflow.
  window.location.href = `${process.env.MY_OWNUP_URL}/auth/logout`;
};
