import { OwnUpUnderlineLink } from '@rategravity/own-up-component-library';
import React from 'react';
import { HintButton, HintButtonProps } from '../../../shared/hint/hint-button';
import { HintBody } from '../../../shared/hint/typography/hint-body';

export const VeteransBenefitsHint = (props: Omit<HintButtonProps, 'children' | 'title'>) => (
  <HintButton {...props} title="Veteran's benefits">
    <HintBody>
      The U.S. Department of Veterans Affairs (VA) offers service members, veterans, and eligible
      surviving spouses home loan benefits and housing related programs to help buy, build, repair,
      retain, or adapt a home for personal occupancy.
      <br />
      <br />
      To learn more about program benefits and specific eligibility requirements, visit{' '}
      <OwnUpUnderlineLink
        link="https://www.benefits.va.gov/homeloans/"
        className="link__hover--yellow"
        rel="noopener noreferrer"
        target="_blank"
      >
        the VA website
      </OwnUpUnderlineLink>
      .
    </HintBody>
  </HintButton>
);
