import { createSelector } from 'reselect';
import { AppState } from '..';

export const dataModelsSelector = (state: AppState) => state.dataModels;
export const dataModelSelector = (nodeId?: string) =>
  createSelector(dataModelsSelector, (dataModels) =>
    nodeId && dataModels[nodeId] && Object.keys(dataModels[nodeId]).length !== 0
      ? dataModels[nodeId]
      : { read: {}, write: {} }
  );
export const dataModelSubmitSelector = (nodeId?: string) =>
  createSelector(dataModelSelector(nodeId), (dataModel) => dataModel.write);
