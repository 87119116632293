import {
  emailPredicate,
  namePredicate,
  phonePredicate
} from '../../../../modules/shared-predicates';
import { EmailAndPhoneDataModel } from './data-model';

export const emailAndPhonePredicate = (model: EmailAndPhoneDataModel) =>
  namePredicate(model.write.name) &&
  emailPredicate(model) &&
  phonePredicate(model.write.phoneNumber as string) &&
  !!model.write.phoneMarketingConsent;
