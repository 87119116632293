import {
  Breakpoint,
  OwnUpGridItem,
  OwnUpGridItemProps,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import * as colors from '@rategravity/own-up-component-library/colors';
import React from 'react';
import styled from 'styled-components';
import { INPUT_GROUP_ITEM_PADDING } from '../form/inputs/input-group-wrapper';

/**
 * Extends {@see OwnUpGridItemProps} but does not allow you to
 *   change the size, as it will always be 12 columns.
 */
export interface NodeIndentProps extends Omit<OwnUpGridItemProps, Breakpoint> {}

/**
 * Wrapper around {@see OwnUpGridItem} that conveys a subsection of the node,
 *   such as a conditional question
 *
 * Figma: https://www.figma.com/file/xOJwjvBP1RFcnViMvWLKsX/Koala-%2F-System-Skin?node-id=66%3A20733
 */
const NodeIndentDiv = styled.div<PropsWithTheme<{}>>`
  & {
    position: relative;

    /* Left stripe. Done as a pseudo-element to allow for border radius */
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      border-radius: 2px;
      height: 100%;
      background-color: ${colors.FOG_60};
    }

    /* top/bottom: 8px, left: 24px */
    padding: 0.5rem 0 0.5rem 24px;
    /* 32px */
    margin-top: 2rem;

    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
      /* 24px */
      margin-top: 1.5rem;
    }

    /* Remove edge margins of the first and last elements in this div */
    & > :first-child {
      margin-top: 0;
    }
    & > :last-child {
      margin-bottom: 0;
    }

    /* If this is embedded in an input group, adjust margins. */
    .input-group .MuiGrid-item > & {
      /* Vertical spacing: 32px minus padding */
      margin: calc(2rem - ${INPUT_GROUP_ITEM_PADDING} * 2) 0;

      ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
        /* Vertical spacing: 24px minus padding */
        margin: calc(1.5rem - ${INPUT_GROUP_ITEM_PADDING} * 2) 0;
      }
    }
  }
`;

export const NodeIndent = ({ children, ...props }: NodeIndentProps) => {
  return (
    <OwnUpGridItem xs={12} {...props}>
      <NodeIndentDiv className="node-indent">{children}</NodeIndentDiv>
    </OwnUpGridItem>
  );
};
