import { acceptedOfferFullWidthNodes } from './accepted-offer/full-width-nodes';
import {
  acceptedOfferNodeMap,
  acceptedOfferNodeSectionMapping,
  acceptedOfferSectionTitles
} from './accepted-offer/node-map';

import { preapprovalFullWidthNodes } from './preapproval/full-width-nodes';
import {
  preapprovalNodeMap,
  preapprovalNodeSectionMapping,
  preapprovalSectionTitles
} from './preapproval/node-map';
import { purchaseFullWidthNodes } from './purchase/full-width-nodes';
import {
  purchaseNodeMap,
  purchaseNodeSectionMapping,
  purchaseSectionTitles
} from './purchase/node-map';
import { refiFullWidthNodes } from './refinance/full-width-nodes';
import { refiNodeMap, refiNodeSectionMapping, refiSectionTitles } from './refinance/node-map';

/**
 * Configurations for each questionnaire
 */
export interface NodeConfiguration {
  /**
   * Mapping of nodes to their elements
   */
  nodeMap: Record<string, () => JSX.Element>;

  /**
   * List of nodes that will not show their sidebar
   */
  fullWidthNodes: string[];

  /**
   * Section Titles to be used in the summary
   *   Note that order matters
   */
  sectionTitles: string[];

  /**
   * Maps node ids to a given section title
   */
  nodeToSectionMapping: Record<string, string>;
}

export const nodeConfigurations: Record<string, NodeConfiguration> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  'accepted-offer': {
    nodeMap: acceptedOfferNodeMap,
    fullWidthNodes: acceptedOfferFullWidthNodes,
    sectionTitles: acceptedOfferSectionTitles,
    nodeToSectionMapping: acceptedOfferNodeSectionMapping
  },
  preapproval: {
    nodeMap: preapprovalNodeMap,
    fullWidthNodes: preapprovalFullWidthNodes,
    sectionTitles: preapprovalSectionTitles,
    nodeToSectionMapping: preapprovalNodeSectionMapping
  },
  refinance: {
    nodeMap: refiNodeMap,
    fullWidthNodes: refiFullWidthNodes,
    sectionTitles: refiSectionTitles,
    nodeToSectionMapping: refiNodeSectionMapping
  },
  purchase: {
    nodeMap: purchaseNodeMap,
    fullWidthNodes: purchaseFullWidthNodes,
    sectionTitles: purchaseSectionTitles,
    nodeToSectionMapping: purchaseNodeSectionMapping
  }
  /* eslint-enable @typescript-eslint/naming-convention */
};
