import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { CloseDateDataModel } from './data-model';
import { CloseDateNodeImpl } from './element';
import { closeDatePredicate } from './predicate';

export const acceptedOfferCloseDateOnlyNodeId = 'close-date-only';
export const AcceptedOfferCloseDateOnlyNode = () => {
  const props = useNodeProperties<CloseDateDataModel>(
    acceptedOfferCloseDateOnlyNodeId,
    closeDatePredicate
  );

  return (
    <Loader>
      <CloseDateNodeImpl {...props} />
    </Loader>
  );
};
