import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Reducer } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { dataModelReducer, DataModelState } from './data-models/slice';
import { displayReducer } from './display/slice';
import { DisplayState } from './display/state';
import { eventsSaga } from './events/sagas';
import { pathSaga } from './path/sagas';
import { pathReducer, PathState } from './path/slice';
import { questionnaireSaga } from './questionnaire/sagas';
import { questionnaireReducer } from './questionnaire/slice';
import { QuestionnaireState } from './questionnaire/state';

export interface AppState {
  router: RouterState<ReturnType<typeof createBrowserHistory>>;
  display: DisplayState;
  path: PathState;
  questionnaire: QuestionnaireState;
  dataModels: DataModelState;
  // Segment does not have a state or reducer, just sagas.
}

function* rootSaga() {
  yield all([fork(questionnaireSaga), fork(pathSaga), fork(eventsSaga)]);
}

export const history = createBrowserHistory({ basename: process.env.SITE_ROOT });
const sagaMiddleware = createSagaMiddleware();
const historyMiddleWare = routerMiddleware(history);

export const store = configureStore({
  reducer: {
    // connect-react-router is typed incorrectly
    router: connectRouter(history) as unknown as Reducer<
      RouterState<ReturnType<typeof createBrowserHistory>>
    >,
    display: displayReducer,
    path: pathReducer,
    questionnaire: questionnaireReducer,
    dataModels: dataModelReducer
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    historyMiddleWare,
    sagaMiddleware
  ]
});

sagaMiddleware.run(rootSaga);
