import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { NewConstructionDataModel } from './data-model';

interface NewConstructionNodeProps extends NodeProperties<NewConstructionDataModel> {}

export const NewConstructionNodeImpl = ({
  dataModel: {
    write: { builderLender },
    read: {}
  },
  onChangeFactory,
  nodeId,
  ...props
}: NewConstructionNodeProps) => {
  const builderLenderChangeDispatcher = onChangeFactory<boolean>('builderLender', nodeId);

  const selectedBuilderLenderOption = useMemo(
    () => (typeof builderLender === 'boolean' ? builderLender : null),
    [builderLender]
  );

  const handleBuilderLenderChange = useCallback(
    (_: any, newValue?: string) => {
      builderLenderChangeDispatcher(newValue === 'true', undefined);
    },
    [builderLenderChangeDispatcher]
  );

  return (
    <NodeContainer>
      <NodeForm nodeId={nodeId} {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>
          Are you buying a new construction property from a builder with an affiliated mortgage
          company or a preferred lender?
        </Question>
        <OwnUpRadioGroup
          name="Builder Lender"
          value={selectedBuilderLenderOption}
          onChange={handleBuilderLenderChange}
        >
          <InputGroupWrapper>
            <StandaloneErrorWrapper
              predicateResult={radioButtonPredicate(selectedBuilderLenderOption)}
            />
            <InputWrapper size="single">
              <OwnUpRadioButton value={true}>Yes</OwnUpRadioButton>
            </InputWrapper>
            <InputWrapper size="single">
              <OwnUpRadioButton value={false}>No</OwnUpRadioButton>
            </InputWrapper>
          </InputGroupWrapper>
        </OwnUpRadioGroup>
      </NodeForm>
    </NodeContainer>
  );
};
