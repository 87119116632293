import { createAction } from '@reduxjs/toolkit';
import { TrackingEventPayload } from '../../modules/1pt';
import { PitchType } from '../../modules/questionnaire/pitch-types';
import { SerializableError } from '../../modules/segment/types';

export const identifyUser = createAction('events/identifyUser');
export type IdentifyUser = ReturnType<typeof identifyUser>;

export const enteredFunnel = createAction<string>('events/enteredFunnel');
export type EnteredFunnel = ReturnType<typeof enteredFunnel>;

export interface NodeEvent {
  nodeId: string;
}

export const loadedNode = createAction<NodeEvent>('events/loadedNode');
export type LoadedNode = ReturnType<typeof loadedNode>;

export const clickedBackButtonAction = createAction<NodeEvent>('events/clickedBackButtonAction');
export type ClickedBackButtonAction = ReturnType<typeof clickedBackButtonAction>;

export const submittedNode = createAction<NodeEvent>('events/submittedNode');
export type SubmittedNode = ReturnType<typeof submittedNode>;

export const nodeIncomplete = createAction<NodeEvent>('events/nodeIncomplete');
export type NodeIncomplete = ReturnType<typeof nodeIncomplete>;

interface InfoModalOpenedEvent {
  nodeId: string;
  infoBoxId?: string;
}

export const infoModalOpenedAction = createAction<InfoModalOpenedEvent>('events/infoModalOpened');
export type InfoModalOpenedAction = ReturnType<typeof infoModalOpenedAction>;

interface LoadPostQuestionnairePitchEvent {
  questionnaireId?: string;
  userId?: string;
  questionnaireType?: string;
  pitchType?: PitchType;
}

export const loadPostQuestionnairePitchAction = createAction<LoadPostQuestionnairePitchEvent>(
  'events/loadPostQuestionnairePitch'
);
export type LoadPostQuestionnairePitchAction = ReturnType<typeof loadPostQuestionnairePitchAction>;

interface LoadConsultPitchEvent {
  questionnaireId?: string;
  userId?: string;
  questionnaireType?: string;
  pitchType?: PitchType;
}

export const loadConsultPitchAction =
  createAction<LoadConsultPitchEvent>('events/loadConsultPitch');
export type LoadConsultPitchAction = ReturnType<typeof loadConsultPitchAction>;

/**
 * Dispatch payload to segment and 1pt
 *
 * @param payload {@see TrackingEventPayload}
 */
export const sendToTrackingEventAction = createAction<TrackingEventPayload>(
  'events/sendToTrackingEventAction'
);
export type SendToTrackingEventAction = ReturnType<typeof sendToTrackingEventAction>;

/**
 * Dispatch an exception to sentry
 */
export const sendToSentryAction = createAction<SerializableError>('events/sendToSentryAction');
export type SendToSentryAction = ReturnType<typeof sendToSentryAction>;
