export const getOccupancyFeedback = (value?: string) => {
  switch (value) {
    case 'PrimaryResidence':
      return 'Lenders view a primary residence as the least risky, so they offer lower rates for this occupancy type.';
    case 'SecondHome':
      return 'Lenders view a second home as more risky than a primary residence, so rates may be higher for this occupancy type.';
    case 'InvestmentProperty':
      return 'Lenders view an investment property as the most risky occupancy type, so they have higher rates.';
    default:
      return 'Lenders view a primary residence as the least risky, so they offer lower rates for this occupancy type.';
  }
};

/**
 * Get the display value for occupancy. A switch is used in lieu
 * of a lookup on the data template because the value needs to be
 * the plural form of the occupancy type.
 */
export const getOccupancy = (value?: string) => {
  switch (value) {
    case 'PrimaryResidence':
      return 'Primary residences';
    case 'SecondHome':
      return 'Second homes';
    case 'InvestmentProperty':
      return 'Investment properties';
    default:
      return value;
  }
};
