import queryString from 'querystring';
import { NextStepsDataModel } from '../components/nodes/common/next-steps-node/data-model';

export const buildYCBMLink = ({
  state,
  email,
  name,
  advisor,
  questionnaireType = 'general'
}: NextStepsDataModel['read']) => {
  const queryParameters = queryString.stringify({
    STATE: state?.toUpperCase(),
    EMAIL: email,
    OEMAIL: email,
    NAME: name
  });
  const advisorPath = advisor ? `/${advisor?.toLowerCase()}` : '';
  return `https://consult.ownup.com/schedule/${questionnaireType?.toLowerCase()}${advisorPath}?${queryParameters}`;
};
