import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { StateDataModel } from '../../common/state-node/data-model';
import { StateNodeImpl } from '../../common/state-node/element';
import { statePredicate } from '../../common/state-node/predicate';

export const purchaseStateNodeId = 'state';
export const PurchaseStateNode = () => {
  const props = useNodeProperties<StateDataModel>(purchaseStateNodeId, statePredicate);
  return (
    <Loader>
      <StateNodeImpl
        headerText="Plan ahead by finding the perfect lender before you have an accepted offer."
        subheaderText="Where are you looking for a home?"
        {...props}
      />
    </Loader>
  );
};
