export interface AddressFields {
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  county?: string;
}

export interface FormattedPlace {
  /* eslint-disable @typescript-eslint/naming-convention */
  postal_code?: string;
  locality?: string;
  administrative_area_level_1?: string;
  administrative_area_level_2?: string;
  street_number?: string;
  route?: string;
  /* eslint-enable @typescript-eslint/naming-convention */
}

export interface AddressModel {
  address?: string;
  unit?: string;
  city?: string;
  state?: string;
  zip?: string;
  county?: string;
}

export interface AddressFieldModel {
  model: AddressModel;
  error?: boolean;
  onChange: ((newValue: string | undefined) => void) | undefined;
}

export interface StateFieldModel extends AddressFieldModel {
  states: Array<{ display: string; value: string }>;
}

export interface AddressProps {
  addressModel?: AddressModel;
  onAutocompleteChange: (newValue: AddressModel) => void;
  id: string;
  states: Array<{ value: string; label: string }>;
}

export interface CityStateAddressProps extends AddressProps {
  onCityChange?: (newValue: string | undefined) => void;
  onStateChange?: (newValue: string | undefined) => void;
}

export interface FullAddressProps extends CityStateAddressProps {
  onAddressChange?: (newValue: string | undefined) => void;
  onUnitChange?: (newValue: string | undefined) => void;
  onCountyChange?: (newValue: string | undefined) => void;
  onZipChange?: (newValue: string | undefined) => void;
}

interface FormattedPlaces {
  [key: string]: string;
}

export const formattedPlace = (place: google.maps.places.PlaceResult) => {
  const formatted: FormattedPlaces = {};
  if (place.address_components) {
    place?.address_components.forEach((component: google.maps.GeocoderAddressComponent) => {
      component.types.forEach((type) => {
        formatted[type] = component.short_name;
      });
    });
  }
  return {
    zip: formatted.postal_code,
    city: formatted.locality,
    state: formatted.administrative_area_level_1,
    address: place.name || `${formatted.street_number || ''} ${formatted.route || ''}`.trim(),
    county: formatted.administrative_area_level_2
  };
};
