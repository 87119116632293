import { USStateAndTerritoryMapping } from '@rategravity/core-lib/enums';
import React from 'react';
import { NumberFormatValues } from 'react-number-format';
import { AdornedFilterDropdown } from '../../../components/shared/inputs/adorned-filter-dropdown';
import { AdornedNumberInput } from '../../../components/shared/inputs/adorned-number-input';
import { AdornedTextInput } from '../../../components/shared/inputs/adorned-text-input';
import { AddressFieldModel, StateFieldModel } from '../../../modules/address-helpers';
import { stateLookUp, stateLookUpByName } from '../../../modules/state-helpers';
import { InputWrapper } from '../form/inputs/input-wrapper';

export const City = ({ model, error, onChange, ...props }: AddressFieldModel): JSX.Element => {
  return (
    <AdornedTextInput
      wrapperSize="double"
      label="City"
      value={model.city}
      textInputPredicate={error}
      errorText={'Please enter a city.'}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value)}
      {...props}
    />
  );
};

export const State = ({ model, error, onChange }: StateFieldModel) => {
  const stateOptions = USStateAndTerritoryMapping.map(({ name }) => name);
  let fullStateName = '';
  if (model.state) {
    // check to see if model.state is the abbreviated version of the state name
    if (model.state.length === 2) {
      fullStateName = stateLookUp[model.state];
    } else {
      fullStateName = model.state;
    }
  }
  return (
    <InputWrapper size="half">
      <AdornedFilterDropdown
        label="State"
        value={fullStateName}
        onChange={(_: React.ChangeEvent<{}>, selectedValue: any) =>
          onChange?.(stateLookUpByName[selectedValue])
        }
        errorText={'Please select a state.'}
        filterDropdownPredicate={error}
        options={stateOptions}
      />
    </InputWrapper>
  );
};

export const Zip = ({ model, error, onChange }: AddressFieldModel) => {
  return (
    <AdornedNumberInput
      wrapperSize="half"
      label="Zip"
      labelPosition="inner"
      allowNegative={false}
      format={'#####'}
      allowLeadingZeros
      errorText={'Please enter a valid zip code.'}
      onValueChange={({ formattedValue }: NumberFormatValues) => onChange?.(formattedValue)}
      value={model.zip}
      numberInputPredicate={error}
    />
  );
};
