import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback } from 'react';
import { phonePredicate } from '../../../../modules/shared-predicates';
import { NodeContainer } from '../../../shared/container';
import { NodeIndent } from '../../../shared/containers/node-indent';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { AdornedNumberInput } from '../../../shared/inputs/adorned-number-input';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Headline } from '../../../shared/typography/headline';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { StepUpConfirmPhoneDataModel } from './data-model';

export const StepUpConfirmPhoneNodeImpl = ({
  dataModel: {
    write: { phoneNumber, sendCodeVerificationMethod }
  },
  onChangeFactory,
  nodeId,
  ...props
}: NodeProperties<StepUpConfirmPhoneDataModel>) => {
  const handleChange = useCallback(
    (field: 'phoneNumber' | 'sendCodeVerificationMethod') => (value: string) => {
      onChangeFactory<string>(field, nodeId)(value, undefined);
    },
    [onChangeFactory, nodeId]
  );

  return (
    <NodeContainer {...props}>
      <NodeForm nodeId={nodeId} submitText="Send code" {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Headline>We need to send you a verification code.</Headline>
        <Question>
          Please double check your number one more time. Hit “send code” and you’ll receive a
          verification code that will allow you to continue.
        </Question>
        <InputGroupWrapper>
          <AdornedNumberInput
            wrapperSize="single"
            type="tel"
            label="Phone number"
            className="fs-mask private"
            value={phoneNumber as string | undefined}
            numberInputPredicate={phonePredicate(phoneNumber as string)}
            errorText="Please provide a valid phone number."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange('phoneNumber')(e.target.value);
            }}
            placeholder="(___) ___-____"
            allowNegative={false}
            decimalScale={0}
            allowLeadingZeros={true}
            mask="_"
            format="###-###-####"
          />
        </InputGroupWrapper>
        <NodeIndent>
          <Question>How would you like to receive your verification code?</Question>
          <OwnUpRadioGroup
            name="Code Verification Method"
            value={sendCodeVerificationMethod}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange('sendCodeVerificationMethod')(e.target.value);
            }}
          >
            <InputGroupWrapper>
              <StandaloneErrorWrapper predicateResult={!!sendCodeVerificationMethod} />
              <InputWrapper size="single">
                <OwnUpRadioButton value="sms">Text</OwnUpRadioButton>
              </InputWrapper>
              <InputWrapper size="single">
                <OwnUpRadioButton value="call">Call</OwnUpRadioButton>
              </InputWrapper>
            </InputGroupWrapper>
          </OwnUpRadioGroup>
        </NodeIndent>
      </NodeForm>
    </NodeContainer>
  );
};
