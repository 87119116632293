import { OwnUpUnderlineLink } from '@rategravity/own-up-component-library';
import React, { useMemo } from 'react';
import { NodeContainer } from '../../../shared/container';
import { NodeForm } from '../../../shared/form/node-form';
import { Description } from '../../../shared/typography/description';
import { Headline } from '../../../shared/typography/headline';
import { Overline } from '../../../shared/typography/overline';
import { NodeProperties } from '../../properties';
import { StopNodeDataModel } from './helpers';

export interface StopNodeProps extends NodeProperties<StopNodeDataModel> {
  showAccountHomeButton?: boolean;
}

const OWNUP_EMAIL_PHONE_REGEX = /\S+?@ownup\.com|\d\d\d-\d\d\d-\d\d\d\d/g;

/**
 * Identify all Own Up emails in the content and turn
 *   them into clickable mailto links.
 */
export const parseEmailLinks = (content?: string) => {
  // Terminate immediately if nothing is there.
  if (!content) {
    return <React.Fragment />;
  }
  const builtElements: React.ReactChild[] = [];
  // Find all emails in the list
  const emailMatches = content.matchAll(OWNUP_EMAIL_PHONE_REGEX);

  // Start from the beginning
  let index = 0;
  // Iterate over every match
  for (const match of emailMatches) {
    // Match indices should always be defined. If not, this
    //   match cannot be used.
    if (!match.index) {
      continue;
    }
    const matchText = match.toString();
    // Determine if there's any text before this match that
    //   needs to be added.
    if (match.index > index) {
      builtElements.push(
        <React.Fragment key={`${index}-prefix`}>
          {content.substr(index, match.index - index)}
        </React.Fragment>
      );
    }
    // Add the mailto link for the matched email.
    if (matchText.includes('@')) {
      builtElements.push(
        <React.Fragment key={index}>
          <OwnUpUnderlineLink link={`mailto:${matchText}`}>{matchText}</OwnUpUnderlineLink>
        </React.Fragment>
      );
    }
    // Or add the tel link for the matched phone.
    else if (matchText.includes('-')) {
      builtElements.push(
        <React.Fragment key={index}>
          <OwnUpUnderlineLink link={`tel:+1-${matchText}`}>{matchText}</OwnUpUnderlineLink>
        </React.Fragment>
      );
    }

    // Set the current position in the text to the end of
    //   this match.
    index = match.index + matchText.length;
  }
  // Determine if there's any text after all the matches
  //   that needs to be added.
  if (index < content.length) {
    builtElements.push(
      <React.Fragment key={`${index}-suffix`}>{content.substr(index)}</React.Fragment>
    );
  }
  // Return the results if anything was built.
  if (builtElements.length > 0) {
    return <React.Fragment>{builtElements}</React.Fragment>;
  }
  // Just return the content if nothing was built.
  return <React.Fragment>{content}</React.Fragment>;
};

export const StopNodeImpl = ({
  dataModel: {
    read: { header, content }
  },
  accountId,
  splitClient,
  questionnaireType,
  ...props
}: StopNodeProps) => {
  const renderedBody = useMemo(() => {
    return accountId ? (
      <Description>
        Please reach out via chat or{' '}
        <OwnUpUnderlineLink link="mailto:hello@ownup.com">hello@ownup.com</OwnUpUnderlineLink> with
        any questions.
      </Description>
    ) : (
      <Description>{parseEmailLinks(content)}</Description>
    );
  }, [content, accountId]);

  return (
    <NodeContainer>
      <NodeForm
        hasSubmit={false}
        showAccountHomeButton={!!accountId}
        accountId={accountId}
        backText="Edit"
        {...props}
      >
        <Overline>{props.sectionTitle}</Overline>
        <Headline>{header}</Headline>
        {renderedBody}
      </NodeForm>
    </NodeContainer>
  );
};
