import { useEffect, useState } from 'react';

const ERROR_SELECTORS = ['.Mui-error', '.standalone-error'].join(',');
const findFirstError = () => document.querySelectorAll(ERROR_SELECTORS)[0];

/**
 * Build a callback that can be used to scroll to the first error
 *   element on the user's page. Accomplishes this by waiting a
 *   moment for the DOM to update, finding the first element on
 *   the page that matches the ERROR_SELECTORS, then smooth
 *   scrolling to the top of its bounding box.
 *
 * Uses a timeout to not only wait for the refresh, but also
 *   effectively provide a debounce so a user can't queue
 *   multiple scrolls at once.
 */
export const useScrollToError = () => {
  // State keeps track of whether or not we're waiting
  //   to scroll.
  const [waitingForScroll, setWaitingForScroll] = useState(false);
  // Effect will trigger whenever the "waitingForScroll"
  //   flag is changed.
  useEffect(() => {
    let refreshTimer: NodeJS.Timeout | undefined = undefined;
    // Only start the timer if the "waitingForScroll" flag
    //   is true.
    if (waitingForScroll) {
      // Start a timer that, upon expiring, will execute the scroll
      refreshTimer = setTimeout(() => {
        // Find the first error on the page
        const firstError = findFirstError();
        if (firstError) {
          // Smooth scroll to get the error in view.
          window.scrollTo({
            // Bounding client rectangle returns the bounding box of
            //   the element relative to the current viewport (i.e.
            //   how far down the user is currently scrolled.) So we
            //   need to do some simple math to determine the absolute
            //   Y coordinate we need to scroll to. Also add a buffer
            //   (chosen somewhat arbitrarily) to account for the navbar
            //   height and to see some of the context of the element.
            top: firstError.getBoundingClientRect().top + window.scrollY - 120,
            behavior: 'smooth'
          });
          // turn off the flag
          setWaitingForScroll(false);
        }
      }, 250);
    }
    // When the effect is re-triggered, clear the
    //   existing timer (if present.)
    return () => clearTimeout(refreshTimer);
  }, [waitingForScroll]);
  return () => setWaitingForScroll(true);
};
