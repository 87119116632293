import React, { useCallback } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { DataModel } from '../../../../store/data-models/slice';
import { Loader, NodeContainer } from '../../../shared/container';
import { NodeForm } from '../../../shared/form/node-form';
import { DollarInput } from '../../../shared/inputs/dollar-input';
import { Description } from '../../../shared/typography/description';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';

export interface LoanBalanceDataModel extends DataModel {
  write: { loanBalance?: number };
}

// Must return true or false. Undefined is not a valid response.
export const loanBalancePredicate = (dataModel: LoanBalanceDataModel) =>
  dataModel.write.loanBalance !== undefined && dataModel.write.loanBalance >= 0;

export const loanBalanceNodeId = 'loan-balance';
export const LoanBalanceNodeImpl = ({
  dataModel: {
    write: { loanBalance }
  },
  onChangeFactory,
  questionnaireType,
  splitClient,
  ...props
}: NodeProperties<LoanBalanceDataModel>) => {
  const handleChange = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      onChangeFactory<number>('loanBalance', loanBalanceNodeId)(floatValue, loanBalance);
    },
    [onChangeFactory, loanBalance]
  );

  return (
    <NodeContainer {...props}>
      <Overline>{props.sectionTitle}</Overline>
      <NodeForm {...props}>
        <Question>What is the remaining balance on your loan?</Question>
        <Description>Your best estimate is fine. </Description>
        <DollarInput
          id="Loan balance"
          allowNegative={false}
          decimalScale={0}
          label="Loan balance"
          name="Loan balance"
          value={loanBalance}
          dollarInputPredicate={loanBalancePredicate({ write: { loanBalance } })}
          errorText="Please enter a valid loan balance."
          onValueChange={handleChange}
        />
      </NodeForm>
    </NodeContainer>
  );
};

export const LoanBalanceNode = () => {
  const props = useNodeProperties<LoanBalanceDataModel>(loanBalanceNodeId, loanBalancePredicate);
  return (
    <Loader>
      <LoanBalanceNodeImpl {...props} />
    </Loader>
  );
};
