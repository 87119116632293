/**
 * convert pitch from a string to a boolean or undefined
 * @param pitch - value to indicate if the borrower should receive a pitch
 */
export const convertPitchToBool = (pitch?: string): boolean | undefined => {
  if (pitch === 'true') {
    return true;
  } else if (pitch === 'false') {
    return false;
  } else {
    return undefined;
  }
};

/**
 * convert pitch from a boolean to a string
 * @param pitch - value to indicate if the borrower should receive a pitch
 */
export const convertPitchToString = (pitch?: boolean | string): string =>
  pitch === true ? 'true' : 'false';
