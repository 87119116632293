import {
  monostenCMixin,
  OwnUpHeadlineBook,
  OwnUpHeadlineProps,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import * as colors from '@rategravity/own-up-component-library/colors';
import React from 'react';
import styled from 'styled-components';
import { createContainerWrappedTypography } from './container';

/**
 * Extension of {@see OwnUpHeadlineProps}
 */
export type OverlineProps = Omit<OwnUpHeadlineProps, 'variant'>;

const OverlineImpl = (props: OverlineProps) => <OwnUpHeadlineBook {...props} variant="h1" />;

const OverlineContainer = createContainerWrappedTypography('overline', OverlineImpl);

/**
 * The "Overline" is the highlighted title placed at the top of every node.
 *   Overline is a mostly custom component for this frontend.
 *
 * Figma: https://www.figma.com/file/xOJwjvBP1RFcnViMvWLKsX/Koala-%2F-System-Skin?node-id=3%3A1532
 */
export const Overline = styled(OverlineContainer)`
  /* Wrapping this in a new scope helps prevent css collisions */
  & {
    /* 80px */
    margin-top: 5rem;
    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
      /* 64px */
      margin-top: 4rem;
    }
    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
      /* 48px */
      margin-top: 3rem;
    }

    .MuiTypography-root {
      /* Use the Monosten font */
      ${monostenCMixin}
      /* Accent 40 */
      background-color: ${colors.MANGO_40};
      border-radius: 4px;
      /* 12px */
      font-size: 0.75rem;
      /* 20px */
      line-height: 1.25rem;
      /* Figma says this is 0.25em but that doesn't seem to
       produce the right size. 0.168rem seems more accurate */
      letter-spacing: 0.168rem;
      /* Force all text to uppercase */
      text-transform: uppercase;
      /* 4px top, 8px horizontal, 0 bottom */
      padding: 0.25rem 0.5rem 0;
      /* Don't stretch to take entire horizontal width of parent */
      display: inline-block;
    }
  }
`;
