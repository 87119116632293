import { replace } from 'connected-react-router';
import { Location } from 'history';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { findNextElement } from '../../modules/array-helpers';
import { getNodeIdFromPathname } from '../../modules/path-helpers';
import { QuestionnaireModel } from '../../modules/questionnaire/questionnaire-model';
import { loadedNode } from '../events/actions';
import { questionnaireTypeSelector } from '../questionnaire/selectors';
import { goBackAction } from './actions';
import {
  lastNodeInPathSelector,
  questionnairePathSelector,
  routerLocationSelector,
  routerPathnameSelector
} from './selectors';
import { PathState, setPathAction } from './slice';

/**
 * Corrects your router to reflect the new path item
 */
export function* handleSetPathAction() {
  // Fetch the questionnaire type
  const questionnaireType: QuestionnaireModel['questionnaireType'] =
    yield select(questionnaireTypeSelector);
  if (questionnaireType) {
    const location: Location = yield select(routerLocationSelector);
    const path: PathState = yield select(questionnairePathSelector);
    const currentNodeId: string = yield getNodeIdFromPathname(location.pathname);
    const lastNode: string = yield select(lastNodeInPathSelector);
    const nextNode: string = yield findNextElement(currentNodeId, path);
    const nodeId = nextNode ? nextNode : lastNode;
    const newPath = `/${questionnaireType}/${nodeId}`;
    // Check if they are different
    if (location.pathname !== newPath) {
      // Push the new path if so
      yield put(replace({ ...location, pathname: newPath }));
    }
    // trigger a loaded node event
    yield put(loadedNode({ nodeId }));
  }
}

/**
 * Corrects your router to reflect your new position
 */
export function* handleGoBackAction() {
  // Fetch the questionnaire type
  const questionnaireType: QuestionnaireModel['questionnaireType'] =
    yield select(questionnaireTypeSelector);
  if (questionnaireType) {
    // Get the pathname of your current URL
    const pathname: string = yield select(routerPathnameSelector);

    // Determine what your node from the path is
    const splitPath = pathname.split('/');
    const currentNodeId = splitPath[splitPath.length - 1];

    // Figure out where in the path you need to be
    const path: PathState = yield select(questionnairePathSelector);
    const targetNodeId = path[path.indexOf(currentNodeId) - 1];

    // Go there
    if (targetNodeId) {
      const location: Location = yield select(routerLocationSelector);
      const newPath = `/${questionnaireType}/${targetNodeId}`;
      yield put(replace({ ...location, pathname: newPath }));
      yield put(loadedNode({ nodeId: targetNodeId }));
    }
  }
}

export function* pathSaga() {
  yield all([
    takeEvery(setPathAction.type, handleSetPathAction),
    takeEvery(goBackAction.type, handleGoBackAction)
  ]);
}
