import * as Sentry from '@sentry/react';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { acceptedOfferConsultationNextStepsNodeId } from '../../components/nodes/accepted-offer/consultation-next-steps-node';
import { acceptedOfferNextStepsNodeId } from '../../components/nodes/accepted-offer/next-steps-node';
import { BaseNextStepsReadFields } from '../../components/nodes/common/next-steps/next-steps-helpers';
import { preapprovalConsultationNextStepsNodeId } from '../../components/nodes/preapproval/consultation-next-steps-node';
import { preapprovalNextStepsNodeId } from '../../components/nodes/preapproval/next-steps-node';
import { purchaseConsultationNextStepsNodeId } from '../../components/nodes/purchase/consultation-next-steps-node';
import { purchaseNextStepsNodeId } from '../../components/nodes/purchase/next-steps-node';
import { refiConsultationNextStepsNodeId } from '../../components/nodes/refinance/consultation-next-steps-node';
import { refiNextStepsNodeId } from '../../components/nodes/refinance/next-steps-node';
import { sendTo1pt } from '../../modules/1pt';
import { getDecodedLoginToken } from '../../modules/authentication';
import { eventsFilter } from '../../modules/filters';
import { sendToGoogle } from '../../modules/google';
import { QuestionnaireModel } from '../../modules/questionnaire/questionnaire-model';
import { sendToSegment, sendToSentry } from '../../modules/segment';
import { SegmentPayload } from '../../modules/segment/types';
import { dataModelSelector, dataModelSubmitSelector } from '../data-models/selectors';
import {
  CreateQuestionnaireFailureAction,
  createQuestionnaireFailureAction
} from '../questionnaire/create-actions';
import { fatalQuestionnaireFailureAction } from '../questionnaire/fatal-actions';
import {
  FetchQuestionnaireFailureAction,
  fetchQuestionnaireFailureAction
} from '../questionnaire/fetch-actions';
import {
  SaveQuestionnaireFailureAction,
  saveQuestionnaireFailureAction
} from '../questionnaire/save-actions';
import {
  questionnaireIdSelector,
  questionnaireStateSelector,
  questionnaireTypeSelector,
  userIdSelector
} from '../questionnaire/selectors';
import { QuestionnaireState } from '../questionnaire/state';
import {
  ClickedBackButtonAction,
  clickedBackButtonAction,
  EnteredFunnel,
  enteredFunnel,
  identifyUser,
  InfoModalOpenedAction,
  infoModalOpenedAction,
  LoadConsultPitchAction,
  loadConsultPitchAction,
  LoadedNode,
  loadedNode,
  LoadPostQuestionnairePitchAction,
  loadPostQuestionnairePitchAction,
  NodeIncomplete,
  nodeIncomplete,
  sendToSentryAction,
  SendToSentryAction,
  sendToTrackingEventAction,
  SendToTrackingEventAction,
  SubmittedNode,
  submittedNode
} from './actions';

// Event names.
export const SUBMITTED_EVENT = 'SubmittedNode';
export const LOADED_EVENT = 'LoadedNode';
export const NODE_INCOMPLETE = 'NodeIncomplete';
export const ENTERED_FUNNEL_EVENT = 'Entered Product Funnel';
export const INFO_MODAL_OPENED_EVENT = 'Info Modal Opened';
export const LOAD_POST_QUESTIONNAIRE_PITCH_EVENT = 'Load Post Questionnaire Pitch';
export const LOAD_CONSULT_PITCH_EVENT = 'Load Consult Pitch';
export const BACK_BUTTON_CLICKED_EVENT = 'Onboarding_BackButtonClicked';

export function* handleLoadedNodeEvent({ payload: { nodeId } }: LoadedNode) {
  const questionnaireState: QuestionnaireState = yield select(questionnaireStateSelector);
  const model: Record<string, unknown> = yield call(eventsFilter, questionnaireState);
  // Track event in segment
  yield put(
    sendToTrackingEventAction({
      method: 'track',
      event: `${LOADED_EVENT}-${nodeId}`,
      properties: { nodeId, ...model }
    })
  );
}

export function* handleSubmittedNodeEvent({ payload: { nodeId } }: LoadedNode) {
  const questionnaireState: QuestionnaireState = yield select(questionnaireStateSelector);
  const dataModel: object = yield select(dataModelSubmitSelector(nodeId));
  const model: Record<string, unknown> = yield call(eventsFilter, {
    ...questionnaireState,
    ...dataModel
  });
  // Track event in segment
  yield put(
    sendToTrackingEventAction({
      method: 'track',
      event: `${SUBMITTED_EVENT}-${nodeId}`,
      properties: { nodeId, ...model }
    })
  );
}

export function* handleBackButtonClickedEvent({ payload: { nodeId } }: ClickedBackButtonAction) {
  const questionnaireState: QuestionnaireState = yield select(questionnaireStateSelector);
  const model: Record<string, unknown> = yield call(eventsFilter, questionnaireState);
  // Track event in segment
  yield put(
    sendToTrackingEventAction({
      method: 'track',
      event: `${BACK_BUTTON_CLICKED_EVENT}-${nodeId}`,
      properties: { nodeId, ...model }
    })
  );
}

export function* handleNodeIncompleteEvent({ payload: { nodeId } }: NodeIncomplete) {
  const questionnaireState: QuestionnaireState = yield select(questionnaireStateSelector);
  const dataModel: object = yield select(dataModelSubmitSelector(nodeId));
  const model: Record<string, unknown> = yield call(eventsFilter, {
    ...questionnaireState,
    ...dataModel
  });
  // Track event in segment
  yield put(
    sendToTrackingEventAction({
      method: 'track',
      event: `${NODE_INCOMPLETE}-${nodeId}`,
      properties: { nodeId, ...model }
    })
  );
}

/**
 * Tracks un-identified users going through the questionnaire.
 * Not optimal for 1pt because the identify function is used for when the user has
 *   an account we can tie them to.
 */
export function* handleIdentifyUserEvent() {
  // Payload will automatically be populated with userId/accountId
  yield put(sendToTrackingEventAction({ method: 'identify' }));
}

export function* handleEnteredFunnelEvent({ payload }: EnteredFunnel) {
  const properties = {
    funnel: payload
  };
  // Call segment and 1pt
  yield put(
    sendToTrackingEventAction({ method: 'track', event: ENTERED_FUNNEL_EVENT, properties })
  );
}

/**
 * Track page view actions when router location changes.
 *
 * @param location - Location object containing new url info.
 * @param action - Action that caused this change. Could be a push/pop/replace, etc.
 */
export function* handlePageViewAction({ payload: { location, action } }: LocationChangeAction) {
  // Build up the absolute URL to submit as a tracking action.
  const url = `${window.location.origin}${location.pathname || ''}${location.search || ''}`;
  const properties = {
    action
  };
  // Track event in segment and 1pt
  yield put(
    sendToTrackingEventAction({
      method: 'page',
      url,
      properties
    })
  );
}

type FailureAction =
  | SaveQuestionnaireFailureAction
  | CreateQuestionnaireFailureAction
  | FetchQuestionnaireFailureAction;

/**
 * Report errors are both tracking events and sentry notifications
 *
 * @param errorType - the action type of the error
 * @param payload - the serialized error to report
 */
export function* handleFailures({ type: errorType, payload }: FailureAction) {
  yield put(
    sendToTrackingEventAction({
      method: 'track',
      event: errorType,
      properties: { payload }
    })
  );
  yield put(sendToSentryAction(payload.error));
}

export function* handleInfoModalOpenedAction({ payload }: InfoModalOpenedAction) {
  yield put(
    sendToTrackingEventAction({
      method: 'track',
      event: INFO_MODAL_OPENED_EVENT,
      properties: payload
    })
  );
}

export function* handleLoadPostQuestionnairePitchAction({
  payload
}: LoadPostQuestionnairePitchAction) {
  yield put(
    sendToTrackingEventAction({
      method: 'track',
      event: LOAD_POST_QUESTIONNAIRE_PITCH_EVENT,
      properties: payload
    })
  );
}

export function* handleLoadConsultPitchAction({ payload }: LoadConsultPitchAction) {
  yield put(
    sendToTrackingEventAction({
      method: 'track',
      event: LOAD_CONSULT_PITCH_EVENT,
      properties: payload
    })
  );
}

const emailConversionNodes = new Set([
  refiNextStepsNodeId,
  refiConsultationNextStepsNodeId,
  preapprovalNextStepsNodeId,
  preapprovalConsultationNextStepsNodeId,
  purchaseNextStepsNodeId,
  purchaseConsultationNextStepsNodeId,
  acceptedOfferNextStepsNodeId,
  acceptedOfferConsultationNextStepsNodeId
]);

export function* maybeHandlePixelConversion({ payload }: SubmittedNode) {
  // Only send this on email submission
  if (emailConversionNodes.has(payload.nodeId)) {
    // Get the data needed from your other models
    const dataModel: { read: BaseNextStepsReadFields } = yield select(
      dataModelSelector(payload.nodeId)
    );
    if (!dataModel?.read) {
      return;
    }

    // Fetch the userId
    const userId: string = yield select(userIdSelector);
    if (!userId) {
      return;
    }

    // Fetch the questionnaireId
    const questionnaireId: string = yield select(questionnaireIdSelector);
    if (!questionnaireId) {
      return;
    }
  }
}

export function* handleSendToTrackingEventAction({ payload }: SendToTrackingEventAction) {
  const userId: string | undefined = yield select(userIdSelector);
  const questionnaireId: string | undefined = yield select(questionnaireIdSelector);
  const questionnaireType: QuestionnaireModel['questionnaireType'] =
    yield select(questionnaireTypeSelector);
  const { accountId } = yield call(getDecodedLoginToken);

  if (process.env.SENTRY_DSN) {
    Sentry.setTag('user.userId', userId);
    Sentry.setTag('user.accountId', accountId);
  }

  const additionalFields = { accountId, userId: userId || '', questionnaireId, questionnaireType };
  const decoratedPayload: SegmentPayload = { ...additionalFields, ...payload };
  if ('traits' in decoratedPayload) {
    decoratedPayload.traits = { ...additionalFields, ...decoratedPayload.traits };
  }
  if ('properties' in decoratedPayload) {
    decoratedPayload.properties = { ...additionalFields, ...decoratedPayload.properties };
  }
  yield call(sendToSegment, decoratedPayload);
  yield call(sendTo1pt, decoratedPayload);
  yield call(sendToGoogle, decoratedPayload);
}

export function* handleSendToSentryAction({ payload }: SendToSentryAction) {
  yield call(sendToSentry, payload);
}

export function* eventsSaga() {
  yield all([
    takeEvery(identifyUser.type, handleIdentifyUserEvent),
    takeEvery(loadedNode.type, handleLoadedNodeEvent),
    takeEvery(submittedNode.type, handleSubmittedNodeEvent),
    takeEvery(nodeIncomplete.type, handleNodeIncompleteEvent),
    takeEvery(enteredFunnel.type, handleEnteredFunnelEvent),
    takeEvery(LOCATION_CHANGE, handlePageViewAction),
    takeEvery(
      [
        fetchQuestionnaireFailureAction,
        createQuestionnaireFailureAction,
        saveQuestionnaireFailureAction,
        fatalQuestionnaireFailureAction
      ],
      handleFailures
    ),
    takeEvery(infoModalOpenedAction, handleInfoModalOpenedAction),
    takeEvery(loadConsultPitchAction, handleLoadConsultPitchAction),
    takeEvery(loadPostQuestionnairePitchAction, handleLoadPostQuestionnairePitchAction),
    takeEvery(clickedBackButtonAction.type, handleBackButtonClickedEvent),
    takeEvery(loadedNode.type, maybeHandlePixelConversion),
    takeEvery(sendToTrackingEventAction.type, handleSendToTrackingEventAction),
    takeEvery(sendToSentryAction.type, handleSendToSentryAction)
  ]);
}
