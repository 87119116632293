import { createSelector } from 'reselect';
import { AppState } from '..';

export const displaySelector = (state: AppState) => state?.display;

export const errorStateSelector = createSelector(displaySelector, (display) => display?.error);

export const hasIncompleteQuestionnaireSelector = createSelector(
  displaySelector,
  (display) => display?.hasIncompleteQuestionnaire
);

export const oldQuestionnaireTypeSelector = createSelector(
  displaySelector,
  (display) => display?.oldQuestionnaireType
);
