import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { AccountDataModel } from '../../common/account-node/data-model';
import { AccountNodeImpl } from '../../common/account-node/element';

export const preapprovalAccountNodeId = 'account';

export const PreapprovalAccountNode = () => {
  const props = useNodeProperties<AccountDataModel>(preapprovalAccountNodeId);
  return <AccountNodeImpl {...props} />;
};
