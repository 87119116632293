import { OwnUpFillButtonPrimary, PropsWithTheme } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

export interface NextStepsBookingButtonProps {
  // Optional function to run before leaving the site
  beforeNavigation?: () => void;
  link: string;
}

const BookingLink = styled.a<PropsWithTheme<{}>>`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
    width: 100%;
  }

  button {
    min-width: 177px;
  }
`;

export const NextStepsBookingButton = ({
  children = 'Schedule call',
  beforeNavigation,
  link
}: React.PropsWithChildren<NextStepsBookingButtonProps>) => (
  <BookingLink
    href={link}
    onClick={
      beforeNavigation
        ? (e: React.MouseEvent<HTMLAnchorElement>) => {
            // If beforeNavigation is defined, prevent the typical link behavior
            e.preventDefault();
            // ...then perform the beforeNavigation function.
            beforeNavigation();
            // Allow background tasks to run before leaving
            setTimeout(() => {
              window.location.href = link;
            }, 10);
          }
        : undefined // If no beforeNavigation function is provided, this will act as a standard link
    }
  >
    <OwnUpFillButtonPrimary>{children}</OwnUpFillButtonPrimary>
  </BookingLink>
);
