import { OwnUpFillAccordion } from '@rategravity/own-up-component-library';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { NextStepsDataModel } from '../next-steps-node/data-model';

export type NextStepsReviewItemsProps = Pick<NextStepsDataModel, 'advisor' | 'questionnaireType'>;

const PREAPPROVAL_ITEMS = [
  [
    'Confirm the specifics of your scenario to ensure accurate results',
    'Discuss your debt-to-income ratio and eligibility.'
  ],
  [
    'Review your preapproval letter, verified by a lender from our marketplace',
    'Including a detailed breakdown, with zero-pressure advocacy and support.'
  ]
];
const DEFAULT_ITEMS = [
  ['Receive updated & lockable rates', 'Including detailed breakdowns for easy comparison.'],
  [
    'Analyze your best lender fit',
    'Expert guidance on how to meet your personal home financing goals.'
  ]
];

const ADVISOR_ITEMS = [
  [
    'Gain a 1:1 resource for any questions or future conversations',
    'Expert guidance on how to meet your personal home financing goals.'
  ]
];

const AccordionContainer = styled.div`
  margin-top: 3rem;
`;

export const NextStepsReviewItems = ({ questionnaireType, advisor }: NextStepsReviewItemsProps) => {
  const [expanded, setExpanded] = useState('');

  const items = useMemo(() => {
    const allItems = questionnaireType === 'preapproval' ? PREAPPROVAL_ITEMS : DEFAULT_ITEMS;
    return advisor ? allItems : [...allItems, ...ADVISOR_ITEMS];
  }, [questionnaireType, advisor]);

  const elements = useMemo(
    () =>
      items.map(([title, content], index) => {
        const key = `accordion-${index}`;
        return (
          <OwnUpFillAccordion
            expanded={expanded === key}
            onChange={(_: any, newExpanded: boolean) => setExpanded(newExpanded ? key : '')}
            key={key}
            title={title}
          >
            {content}
          </OwnUpFillAccordion>
        );
      }),
    [expanded, items, setExpanded]
  );

  return <AccordionContainer>{elements}</AccordionContainer>;
};
