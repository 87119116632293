import { CommonProps } from '@material-ui/core/OverridableComponent';
import {
  Breakpoint,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridItemProps,
  OwnUpGridOffset
} from '@rategravity/own-up-component-library';
import { useCombinedClassName } from '@rategravity/own-up-component-library/hooks/use-combined-class-name';
import React, { useMemo } from 'react';
import styled from 'styled-components';

/**
 * Wrapper around {@see OwnUpGridItemProps} that disallows setting of
 *   Breakpoints, as they will be dynamic based on screen size and
 *   content.
 */
export interface TypographyContainerProps extends Omit<OwnUpGridItemProps, Breakpoint | 'spacing'> {
  /**
   * A Hint Button to go alongside this Typography.
   */
  hint?: React.ReactNode;

  /**
   * Props for Typography container portion of this element.
   */
  mainGridItemProps?: Omit<OwnUpGridItemProps, Breakpoint | 'children'>;

  /**
   * Props for Hint container/offset portion of this element.
   */
  hintGridItemProps?: Omit<OwnUpGridItemProps, Breakpoint | 'children'>;

  /**
   * How big of a container to use for this element. Defaults to 'full'.
   *   Note that the inclusion of the hint will cause this to be ignored.
   *
   * full - Typography uses most of the page width (though not
   *   all on large screens)
   * half - Typography uses half of the page width on large screens,
   *   wrapping with the input containers.
   */
  size?: 'full' | 'half';
}

const HintGridItem = styled(OwnUpGridItem)`
  text-align: right;
`;

/**
 * {@see OwnUpGridItem} wrapped around all Typography components. Takes
 *   up 12 columns with two child elements: one for the Typography
 *   and the other for either a hint or column padding.
 */
export const TypographyContainer = ({
  hint,
  children,
  mainGridItemProps,
  hintGridItemProps,
  size,
  ...props
}: TypographyContainerProps) => {
  // Build the Typography component
  const mainGridItem = useMemo(() => {
    const gridWidth = size === 'half' ? 6 : 10;
    return (
      // Column size is based on screen width and existence of a hint.
      <OwnUpGridItem xs={hint ? 10 : 12} sm={gridWidth} {...mainGridItemProps}>
        {children}
      </OwnUpGridItem>
    );
  }, [children, hint, mainGridItemProps, size]);
  const hintGridItem = useMemo(() => {
    // Hints will get 2 columns of space on mobile and simply occupy
    //   the rest of the space on other screen sizes.
    if (hint) {
      return (
        <HintGridItem xs={2} {...hintGridItemProps}>
          {hint}
        </HintGridItem>
      );
    }
    return <OwnUpGridOffset xs={0} sm={2} {...hintGridItemProps} />;
  }, [hint, hintGridItemProps]);
  return (
    <OwnUpGridContainer variant="slim" spacing={0} {...props}>
      {mainGridItem}
      {hintGridItem}
    </OwnUpGridContainer>
  );
};

export const createContainerWrappedTypography = <T extends CommonProps<any>>(
  className: string,
  Element: React.FC<T>,
  baseProps: TypographyContainerProps = {}
) => {
  // Yes, you can declare type aliases in function scopes! No, this doesn't
  //   do anything at runtime.
  type ContainerProps = TypographyContainerProps & { typographyProps?: T };
  // Produce a version of TypographyContainer with the input element and classnames.
  const CreatedContainer: React.FC<ContainerProps> = ({
    className: containerClassName,
    typographyProps: { className: typographyClassName, ...typographyProps } = {} as T,
    children,
    ...props
  }: ContainerProps) => {
    const combinedClassName = useCombinedClassName(
      className,
      'typography-container',
      containerClassName
    );
    const combinedTypographyClassName = useCombinedClassName(
      className,
      'typography',
      typographyClassName
    );
    return (
      <TypographyContainer className={combinedClassName} {...baseProps} {...props}>
        {/* This cast on the props is necessary because destructuring changes the type of the parameter */}
        <Element className={combinedTypographyClassName} {...(typographyProps as T)}>
          {children}
        </Element>
      </TypographyContainer>
    );
  };
  // Set component name mainly for React dev tools debugging.
  CreatedContainer.displayName = className + 'TypographyContainer';
  return CreatedContainer;
};
