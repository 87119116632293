import { SLATE_100 } from '@rategravity/own-up-component-library';
import React from 'react';
import { HintButton, HintButtonProps } from '../../../shared/hint/hint-button';
import { HintBody } from '../../../shared/hint/typography/hint-body';

export const DownPaymentHint = (props: Omit<HintButtonProps, 'children' | 'title'>) => (
  <HintButton {...props} title="Why do I need a certain down payment amount?">
    <HintBody style={{ color: SLATE_100 }}>
      The amount you need to put down when buying a home depends on factors like the purchase price,
      occupancy, and property type, as well as your eligibility for loan programs.
    </HintBody>
  </HintButton>
);
