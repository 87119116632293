import { PreapprovalAccountNode, preapprovalAccountNodeId } from './account-node';
import {
  PreapprovalApproximateCreditScoreNode,
  preapprovalApproximateCreditScoreNodeId
} from './approximate-credit-score-node';
import { PreapprovalCoborrowerNode, preapprovalCoborrowerNodeId } from './coborrower-node';
import {
  PreapprovalConfirmContactInfoNode,
  preapprovalConfirmContactInfoNodeId
} from './confirm-contact-info-node';
import {
  PreapprovalConsultationNextStepsNode,
  preapprovalConsultationNextStepsNodeId
} from './consultation-next-steps-node';
import { PreapprovalDownPaymentNode, preapprovalDownPaymentNodeId } from './down-payment-node';
import {
  PreapprovalEmailAndPhoneNode,
  preapprovalEmailAndPhoneNodeId
} from './email-and-phone-node';
import {
  PreapprovalInfoNotVerifiedStopNode,
  preapprovalInfoNotVerifiedStopNodeId
} from './info-not-verified-stop-node';
import { PreapprovalLowLoanStopNode, stopPreapprovalLowLoanNodeId } from './low-loan-stop-node';
import { PreapprovalNextStepsNode, preapprovalNextStepsNodeId } from './next-steps-node';
import { PreapprovalNoIncomeStopNode, stopPreapprovalNoIncomeNodeId } from './no-income-stop-node';
import {
  PreapprovalOccupancyTypeNode,
  preapprovalOccupancyTypeNodeId
} from './occupancy-type-node';
import {
  PreapprovalPrimaryBorrowerFthbNode,
  preapprovalPrimaryBorrowerFthbNodeId
} from './primary-borrower-fthb-node';
import {
  PreapprovalIncomeAmountNode,
  preapprovalIncomeAmountNodeId
} from './primary-borrower-income-amount-node';
import {
  PreapprovalIncomeTypeNode,
  preapprovalIncomeTypeNodeId
} from './primary-borrower-income-type-node';
import { PreapprovalPropertyTypeNode, preapprovalPropertyTypeNodeId } from './property-type-node';
import {
  PreapprovalPurchasePriceNode,
  preapprovalPurchasePriceNodeId
} from './purchase-price-node';
import {
  PreapprovalPurchaseTimelineNode,
  preapprovalPurchaseTimelineNodeId
} from './purchase-timeline-node';
import {
  PreapprovalRealEstateAgentNode,
  preapprovalRealEstateAgentNodeId
} from './real-estate-agent-node';
import { PreapprovalStateInfoNode, preapprovalStateInfoNodeId } from './state-info-node';
import { PreapprovalStateNode, preapprovalStateNodeId } from './state-node';
import {
  PreapprovalStepUpConfirmCodeNode,
  preapprovalStepUpConfirmCodeNodeId
} from './step-up-confirm-code-node';
import {
  PreapprovalStepUpConfirmPhoneNode,
  preapprovalStepUpConfirmPhoneNodeId
} from './step-up-confirm-phone-node';
import {
  preapprovalVeteransBenefitsConsentId,
  PreapprovalVeteransBenefitsConsentNode
} from './veterans-benefits-consent-node';

/**
 * Mapping of all nodes to elements.
 */
export const preapprovalNodeMap: Record<string, () => JSX.Element> = {
  [preapprovalStateNodeId]: PreapprovalStateNode,
  [preapprovalStateInfoNodeId]: PreapprovalStateInfoNode,
  [preapprovalAccountNodeId]: PreapprovalAccountNode,
  [preapprovalPropertyTypeNodeId]: PreapprovalPropertyTypeNode,
  [preapprovalOccupancyTypeNodeId]: PreapprovalOccupancyTypeNode,
  [preapprovalPrimaryBorrowerFthbNodeId]: PreapprovalPrimaryBorrowerFthbNode,
  [preapprovalIncomeAmountNodeId]: PreapprovalIncomeAmountNode,
  [preapprovalIncomeTypeNodeId]: PreapprovalIncomeTypeNode,
  [preapprovalApproximateCreditScoreNodeId]: PreapprovalApproximateCreditScoreNode,
  [preapprovalPurchasePriceNodeId]: PreapprovalPurchasePriceNode,
  [preapprovalDownPaymentNodeId]: PreapprovalDownPaymentNode,
  [preapprovalRealEstateAgentNodeId]: PreapprovalRealEstateAgentNode,
  [preapprovalPurchaseTimelineNodeId]: PreapprovalPurchaseTimelineNode,
  [preapprovalNextStepsNodeId]: PreapprovalNextStepsNode,
  [preapprovalConsultationNextStepsNodeId]: PreapprovalConsultationNextStepsNode,
  [stopPreapprovalLowLoanNodeId]: PreapprovalLowLoanStopNode,
  [stopPreapprovalNoIncomeNodeId]: PreapprovalNoIncomeStopNode,
  [preapprovalEmailAndPhoneNodeId]: PreapprovalEmailAndPhoneNode,
  [preapprovalConfirmContactInfoNodeId]: PreapprovalConfirmContactInfoNode,
  [preapprovalInfoNotVerifiedStopNodeId]: PreapprovalInfoNotVerifiedStopNode,
  [preapprovalStepUpConfirmPhoneNodeId]: PreapprovalStepUpConfirmPhoneNode,
  [preapprovalStepUpConfirmCodeNodeId]: PreapprovalStepUpConfirmCodeNode,
  [preapprovalVeteransBenefitsConsentId]: PreapprovalVeteransBenefitsConsentNode,
  [preapprovalCoborrowerNodeId]: PreapprovalCoborrowerNode
};

export const PROPERTY_INFO = 'Property Info';
export const PURCHASE_INFO = 'Purchase Info';
export const FINAL_STEP = 'Final Step';

export const preapprovalSectionTitles = [PROPERTY_INFO, PURCHASE_INFO, FINAL_STEP];

export const preapprovalNodeSectionMapping: Record<string, string> = {
  // Property Info
  [preapprovalPropertyTypeNodeId]: PROPERTY_INFO,
  [preapprovalOccupancyTypeNodeId]: PROPERTY_INFO,
  [preapprovalStateNodeId]: PROPERTY_INFO,
  [preapprovalStateInfoNodeId]: PROPERTY_INFO,
  [preapprovalRealEstateAgentNodeId]: PROPERTY_INFO,

  // Purchase Info
  [preapprovalPurchaseTimelineNodeId]: PURCHASE_INFO,
  [preapprovalPrimaryBorrowerFthbNodeId]: PURCHASE_INFO,
  [preapprovalCoborrowerNodeId]: PURCHASE_INFO,
  [preapprovalVeteransBenefitsConsentId]: PURCHASE_INFO,
  [preapprovalPurchasePriceNodeId]: PURCHASE_INFO,
  [preapprovalDownPaymentNodeId]: PURCHASE_INFO,
  [stopPreapprovalLowLoanNodeId]: PURCHASE_INFO,
  [preapprovalIncomeAmountNodeId]: PURCHASE_INFO,
  [preapprovalIncomeTypeNodeId]: PURCHASE_INFO,
  [stopPreapprovalNoIncomeNodeId]: PURCHASE_INFO,
  [preapprovalApproximateCreditScoreNodeId]: PURCHASE_INFO,

  // Final Step
  [preapprovalEmailAndPhoneNodeId]: FINAL_STEP,
  [preapprovalConfirmContactInfoNodeId]: FINAL_STEP,
  [preapprovalInfoNotVerifiedStopNodeId]: FINAL_STEP,
  [preapprovalStepUpConfirmPhoneNodeId]: FINAL_STEP,
  [preapprovalStepUpConfirmCodeNodeId]: FINAL_STEP,
  [preapprovalAccountNodeId]: FINAL_STEP
};
