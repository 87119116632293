import {
  OwnUpAutocompleteProps,
  OwnUpFilterDropdown,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useClickedSubmitContext } from '../../../hooks/use-clicked-submit-context';

/**
 * Filter Dropdown wrapper that supports error states.
 */

interface FilterDropdownProps extends OwnUpAutocompleteProps {
  /** Text to be passed as helpertext if there is an error */
  errorText?: string;
  filterDropdownPredicate?: boolean;
}

const StyledDropDown = styled(OwnUpFilterDropdown)<PropsWithTheme<OwnUpAutocompleteProps>>`
  margin-bottom: ${({ error }: PropsWithTheme<OwnUpAutocompleteProps>) => (error ? '12px' : '0px')};
`;

export const AdornedFilterDropdown = ({
  errorText = '',
  filterDropdownPredicate = false,
  ...props
}: FilterDropdownProps) => {
  const { hasClickedSubmit } = useClickedSubmitContext();
  /**
   *  defaultState = {showError:props.value !== undefined } allows illegal
   *   pre-filled values to trigger the error state by default.
   */
  const [showError, setShowError] = useState(false);
  const handleBlur = useCallback(() => {
    if (!showError) {
      setShowError(true);
    }
  }, [showError, setShowError]);

  const dropdownErrorTextMemo = useMemo(() => {
    /**
     * This should be triggered by: 1. a user clicking `continue` without making a selection
     *  2. a default filterDropdownPredicate = false, e.g. a defaultValue of 0 in an input that does
     *  not allow zeroes. or 3. a wrong user interaction (tracked by 'onBlur'), e.g interacting
     *  with the input but entering nothing or zero.
     */
    return (!filterDropdownPredicate && hasClickedSubmit) || (showError && !filterDropdownPredicate)
      ? errorText
      : undefined;
  }, [showError, filterDropdownPredicate, errorText, hasClickedSubmit]);

  return (
    <StyledDropDown
      {...props}
      labelPosition="inner"
      helperText={dropdownErrorTextMemo}
      error={dropdownErrorTextMemo}
      onBlur={handleBlur}
    />
  );
};
