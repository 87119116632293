import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { PurchaseTimelineDataModel } from '../../common/purchase-timeline-node/data-model';
import { PurchaseTimelineNodeImpl } from '../../common/purchase-timeline-node/element';
import { purchaseTimelinePredicate } from '../../common/purchase-timeline-node/predicate';

export const preapprovalPurchaseTimelineNodeId = 'purchase-timeline';

export const PreapprovalPurchaseTimelineNode = () => {
  const props = useNodeProperties<PurchaseTimelineDataModel>(
    preapprovalPurchaseTimelineNodeId,
    purchaseTimelinePredicate
  );

  return (
    <Loader>
      <PurchaseTimelineNodeImpl {...props} />
    </Loader>
  );
};
