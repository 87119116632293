import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { StateDataModel } from '../../common/state-node/data-model';
import { StateNodeImpl } from '../../common/state-node/element';
import { statePredicate } from '../../common/state-node/predicate';

export const refiStateNodeId = 'state';
export const RefiStateNode = () => {
  const properties = useNodeProperties<StateDataModel>(refiStateNodeId, statePredicate);
  return (
    <Loader waitingForExperiment={properties.waitingForSplitClient}>
      <StateNodeImpl
        headerText="Let's get started. Tell us about your property."
        subheaderText="What state is your property located in?"
        {...properties}
      />
    </Loader>
  );
};
