import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { PropertyAddressDataModel } from '../../common/property-address-node/data-model';
import { PropertyAddressNodeImpl } from '../../common/property-address-node/element';
import { propertyAddressPredicate } from '../../common/property-address-node/predicate';

export const refiPropertyAddressNodeId = 'property-address';

export const RefiPropertyAddressNode = () => {
  const props = useNodeProperties<PropertyAddressDataModel>(
    refiPropertyAddressNodeId,
    propertyAddressPredicate
  );
  return (
    <Loader>
      <PropertyAddressNodeImpl {...props} header="What's the address of your property?" />
    </Loader>
  );
};
