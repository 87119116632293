import { createContext, useContext } from 'react';

/**
 * Context for when the user clicks the `continue` button
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ClickedSubmitContext = createContext({
  hasClickedSubmit: false,
  setHasClickedSubmit: (_hasClickedSubmit: boolean) => {
    /* No-op */
  }
});

/**
 * Note this context is on the NodeForm and only works for components that
 * are a child of the NodeForm.
 * In other words, useClickedSubmitContext doesn't work if it is called
 * before the NodeForm has been instantiated.
 */
export const useClickedSubmitContext = () => useContext(ClickedSubmitContext);
