import {
  OwnUpBody,
  OwnUpCheckbox,
  OwnUpNumberInput,
  OwnUpRadioButton,
  OwnUpRadioGroup
} from '@rategravity/own-up-component-library';
import { DollarIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/dollar';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { NumberFormatValues } from 'react-number-format';
import styled from 'styled-components';
import { useClickedSubmitContext } from '../../../../hooks/use-clicked-submit-context';
import { getDisplayRanges } from '../../../../modules/get-display-ranges';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { ONBOARDING_SHORTENED_NON_CONCIERGE } from '../../../../modules/split';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Description } from '../../../shared/typography/description';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { IncomeAmountDataModel } from './data-model';

export interface IncomeAmountProps extends NodeProperties<IncomeAmountDataModel> {}

const OrContainer = styled(OwnUpBody)`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  display: flex;
  justify-content: center;
`;

const INCOME_RANGES = [50_000, 100_000, 150_000, 200_000];
export const STEP = 50_000;

const IncomeRangeComponents = ({
  dataModel: {
    write: { incomeAmount }
  },
  onChangeFactory,
  ...props
}: IncomeAmountProps) => {
  const incomeChangeDispatcher = onChangeFactory<number>('incomeAmount', props.nodeId);
  const incomeMinChangeDispatcher = onChangeFactory<number>('incomeAmountMin', props.nodeId);
  const incomeMaxChangeDispatcher = onChangeFactory<number>('incomeAmountMax', props.nodeId);

  const incomeValueToRange = useMemo(
    () => (value: number) =>
      value > INCOME_RANGES[INCOME_RANGES.length - 1] ? [value - 1, value] : [value - STEP, value], // can assume 50k ranges for everything under max range: ;
    []
  );

  const handleIncomeAmountChange = useCallback(
    (_: unknown, newValue?: string) => {
      incomeChangeDispatcher(Number(newValue), incomeAmount);
      incomeMinChangeDispatcher(incomeValueToRange(Number(newValue))[0], incomeAmount);
      incomeMaxChangeDispatcher(Number(newValue), incomeAmount);
    },
    [
      incomeChangeDispatcher,
      incomeMinChangeDispatcher,
      incomeMaxChangeDispatcher,
      incomeAmount,
      incomeValueToRange
    ]
  );

  const mappedIncomeRangeOptions = useMemo(
    () =>
      getDisplayRanges(INCOME_RANGES).map(({ value, label }) => (
        <InputWrapper key={value} size="single">
          <OwnUpRadioButton value={value.toString()}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    []
  );

  return (
    <OwnUpRadioGroup
      name="incomeAmount"
      value={incomeAmount?.toString()}
      onChange={handleIncomeAmountChange}
    >
      <InputGroupWrapper>
        {' '}
        <StandaloneErrorWrapper predicateResult={radioButtonPredicate(incomeAmount)} />
        {mappedIncomeRangeOptions}
      </InputGroupWrapper>
    </OwnUpRadioGroup>
  );
};

/**
 * These are built as a separate component because the ClickedSubmitContext is on the
 * NodeForm, and must be accessed from a child component.
 */
const InputComponents = ({
  dataModel: {
    write: { incomeAmount, noIncome }
  },
  onChangeFactory,
  ...props
}: IncomeAmountProps) => {
  const { hasClickedSubmit } = useClickedSubmitContext();
  const incomeChangeDispatcher = onChangeFactory<number>('incomeAmount', props.nodeId);
  const incomeMinChangeDispatcher = onChangeFactory<number>('incomeAmountMin', props.nodeId);
  const incomeMaxChangeDispatcher = onChangeFactory<number>('incomeAmountMax', props.nodeId);

  const handleIncomeAmountChange = useCallback(
    ({ value }: NumberFormatValues) => {
      const newValue = value === '' ? undefined : Number(value);
      incomeChangeDispatcher(newValue, undefined);
      // min and max are the same for numeric input (no ranges)
      incomeMinChangeDispatcher(newValue, undefined);
      incomeMaxChangeDispatcher(newValue, undefined);
    },
    [incomeChangeDispatcher, incomeMinChangeDispatcher, incomeMaxChangeDispatcher]
  );
  const handleNoIncomeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeFactory<boolean>('noIncome', props.nodeId)(event.target.checked, undefined);
      if (event.target.checked) {
        // Clear out the income field if noIncome is checked
        incomeChangeDispatcher(undefined, undefined);
      }
    },
    [onChangeFactory, incomeChangeDispatcher, props.nodeId]
  );
  const showError = useMemo(
    () => hasClickedSubmit && !incomeAmount && !noIncome,
    [hasClickedSubmit, incomeAmount, noIncome]
  );

  return (
    <InputGroupWrapper>
      <InputWrapper size="single">
        <OwnUpNumberInput
          id="Income Amount"
          label="Income Amount"
          value={incomeAmount || ''}
          $leadingIcon={<DollarIcon />}
          decimalScale={0}
          helperText={showError && 'Please enter an income amount'}
          error={showError}
          disabled={noIncome}
          onValueChange={handleIncomeAmountChange}
          {...props}
        />
      </InputWrapper>
      <InputWrapper size="single">
        <OrContainer variant="body1">or</OrContainer>
      </InputWrapper>
      <InputWrapper size="single">
        <OwnUpCheckbox checked={noIncome} onChange={handleNoIncomeChange}>
          I don&apos;t earn an income
        </OwnUpCheckbox>
      </InputWrapper>
    </InputGroupWrapper>
  );
};

export const IncomeAmountNodeImpl = ({ ...props }: IncomeAmountProps) => {
  const useIncomeRanges = props.questionnaireType !== 'accepted-offer';

  const shortenedQuestionnaireTreatment = props.splitClient.getTreatment(
    ONBOARDING_SHORTENED_NON_CONCIERGE,
    {
      questionnaireType: props.questionnaireType || 'undefined'
    }
  );
  const question = useMemo(() => {
    if (props.dataModel.read.hasCoborrower || shortenedQuestionnaireTreatment === 'on') {
      return 'What is your household income?';
    }
    return 'What is your annual income?';
  }, [props.dataModel.read.hasCoborrower, shortenedQuestionnaireTreatment]);

  return (
    <NodeContainer>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>{question}</Question>
        {!props.dataModel.read.hasCoborrower && shortenedQuestionnaireTreatment !== 'on' && (
          <Description>Please only include your own income for now.</Description>
        )}
        {useIncomeRanges ? <IncomeRangeComponents {...props} /> : <InputComponents {...props} />}
      </NodeForm>
    </NodeContainer>
  );
};
