import { USStateAndTerritoryMapping, USStateMapping } from '@rategravity/core-lib/enums';

export const stateLookUp = USStateAndTerritoryMapping.reduce(
  (acc, { abbreviation, name }) => {
    acc[abbreviation] = name;
    return acc;
  },
  {} as Record<string, string>
);

export const stateLookUpByName = USStateAndTerritoryMapping.reduce(
  (acc, { abbreviation, name }) => {
    acc[name] = abbreviation;
    return acc;
  },
  {} as Record<string, string>
);

const stateMapping = ({ name, abbreviation }: { name: string; abbreviation: string }) => ({
  label: name,
  value: abbreviation
});

export const states =
  process.env.DEPLOY_ENV === 'prod'
    ? USStateMapping.map(stateMapping)
    : USStateAndTerritoryMapping.map(stateMapping);
