import {
  OwnUpBody,
  OwnUpBodyProps,
  OwnUpIcon,
  OwnUpIconProps,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import * as colors from '@rategravity/own-up-component-library/colors';
import { useCombinedClassName } from '@rategravity/own-up-component-library/hooks/use-combined-class-name';
import React from 'react';
import styled from 'styled-components';
import { LightbulbIcon } from '../icons/lightbulb-icon';
import { createContainerWrappedTypography } from './container';

/**
 * Extension of {@see OwnUpBodyProps}
 */
export interface FeedbackProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  iconProps?: OwnUpIconProps;
  typographyProps?: Omit<OwnUpBodyProps, 'variant' | 'children'>;
}

const FeedbackImpl = ({
  children,
  iconProps: {
    className: iconClassName,
    height = '1.5rem',
    color = colors.ALOE_100,
    icon = LightbulbIcon,
    ...iconProps
  } = {} as OwnUpIconProps,
  typographyProps: { className: typographyClassName, ...typographyProps } = {},
  ...props
}: FeedbackProps) => {
  const fullIconClassName = useCombinedClassName('feedback-icon', iconClassName);
  const fullTypographyClassName = useCombinedClassName('feedback-typography', typographyClassName);
  return (
    <div {...props}>
      <OwnUpIcon
        className={fullIconClassName}
        height={height}
        color={color}
        icon={icon}
        {...iconProps}
      />
      <OwnUpBody className={fullTypographyClassName} {...typographyProps} variant="body1">
        {children}
      </OwnUpBody>
    </div>
  );
};

const FeedbackContainer = createContainerWrappedTypography('feedback-container', FeedbackImpl);

/**
 * "Feedback" is typically a response to a user's input. It is the
 *   regular copy typography with a lightbulb icon next to it.
 *
 * Figma: https://www.figma.com/file/xOJwjvBP1RFcnViMvWLKsX/Koala-%2F-System-Skin?node-id=3%3A1532
 */
export const Feedback = styled(FeedbackContainer)`
  /* 32px */
  margin-top: 2rem;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
    /* 24px */
    margin-top: 1.5rem;
  }

  .feedback-container {
    display: flex;

    & .ou-icon {
      margin-right: 1rem;
    }
  }
`;
