import { OwnUpBodyProps, OwnUpLargeBody } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { createContainerWrappedTypography } from './container';

/**
 * Extension of {@see OwnUpBodyProps}
 */
export type QuestionProps = Omit<OwnUpBodyProps, 'variant'>;

const QuestionImpl = (props: QuestionProps) => <OwnUpLargeBody {...props} variant="body1" />;

const QuestionContainer = createContainerWrappedTypography('question', QuestionImpl);

/**
 * The "Question" is body copy that describes what the
 *   user is doing on the page in a more muted way than
 *   a "Headline"
 *
 * Figma: https://www.figma.com/file/xOJwjvBP1RFcnViMvWLKsX/Koala-%2F-System-Skin?node-id=3%3A1532
 */
export const Question = styled(QuestionContainer)`
  /* Wrapping this in a new scope helps prevent css collisions */
  & {
    /* 16px */
    margin-top: 1rem;

    /* If there is a question or description above this question
       we should add extra top padding to indicate a section break. */
    & ~ &,
    .description ~ & {
      /* 48px */
      margin-top: 3rem;
    }
  }
`;
