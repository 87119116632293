import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { infoModalOpenedAction } from '../../../../store/events/actions';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Feedback } from '../../../shared/typography/feedback';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { CoborrowerDataModel } from './data-model';
import { CoborrowerHint } from './hint';

export interface CoborrowerNodeProps extends NodeProperties<CoborrowerDataModel> {}

export const CoborrowerNodeImpl = ({
  dataModel: {
    write: { hasCoborrower },
    read: { options }
  },
  onChangeFactory,
  dispatch,
  questionnaireType,
  splitClient,
  ...props
}: CoborrowerNodeProps) => {
  const scenario = questionnaireType === 'refinance' ? 'refinancing' : 'buying';

  const hasCoborrowerChangeDispatcher = onChangeFactory<boolean>('hasCoborrower', props.nodeId);
  const handleHasCoborrowerChange = useCallback(
    (_: any, newValue?: string) => {
      hasCoborrowerChangeDispatcher(newValue !== 'false', hasCoborrower);
    },
    [hasCoborrower, hasCoborrowerChangeDispatcher]
  );

  const choices = useMemo(
    () =>
      options.map(({ label, value }) => (
        <InputWrapper size="single" aria-label={String(value)} key={String(value)}>
          <OwnUpRadioButton value={String(value)} aria-label={String(value)}>
            {label}
          </OwnUpRadioButton>
        </InputWrapper>
      )),
    [options]
  );

  const clickInfoModal = useCallback(() => {
    dispatch(infoModalOpenedAction({ nodeId: props.nodeId }));
  }, [dispatch, props.nodeId]);

  const predicate = useMemo(() => radioButtonPredicate(hasCoborrower), [hasCoborrower]);

  return (
    <NodeContainer>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question hint={<CoborrowerHint onClick={clickInfoModal} />}>
          Are you {scenario} this property with a co-borrower?
        </Question>
        <OwnUpRadioGroup
          name="Co-borrower"
          value={String(hasCoborrower)}
          onChange={handleHasCoborrowerChange}
        >
          <InputGroupWrapper>
            <StandaloneErrorWrapper predicateResult={predicate} />
            {choices}
          </InputGroupWrapper>
        </OwnUpRadioGroup>
        {!!hasCoborrower && (
          <Feedback>
            Got it! We may ask a few more questions about your co-borrower later in the process if
            they&apos;re relevant for your scenario.
          </Feedback>
        )}
      </NodeForm>
    </NodeContainer>
  );
};
