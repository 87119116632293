import { USStateAndTerritoryMapping, USStateMapping } from '@rategravity/core-lib/enums';
import React, { useCallback } from 'react';
import { AdornedFilterDropdown } from '../../../../components/shared/inputs/adorned-filter-dropdown';
import { stateLookUp, stateLookUpByName } from '../../../../modules/state-helpers';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StateLightbulbText } from '../../../shared/state-lightbulb-text';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { StateDataModel } from './data-model';

interface StateNodeProps extends NodeProperties<StateDataModel> {
  headerText: string;
  subheaderText: string;
}

export const StateNodeImpl = ({
  dataModel: {
    write: { state },
    read: { permittedStates }
  },
  headerText,
  subheaderText,
  questionnaireType,
  onChangeFactory,
  splitClient,
  ...props
}: StateNodeProps) => {
  const handleOnChange = onChangeFactory<string>('state', props.nodeId);
  const stateDisplay = state ? stateLookUp[state] : undefined;

  const handleStateChange = useCallback(
    (_: React.ChangeEvent<{}>, selectedValue: any) => {
      const newValue = String(selectedValue);
      const stateAbbr = newValue ? stateLookUpByName[newValue] : '';

      handleOnChange(stateAbbr, undefined);
    },
    [handleOnChange]
  );

  const stateMapping = ({ name }: { name: string }) => name;

  const stateOptions =
    process.env.DEPLOY_ENV === 'prod'
      ? USStateMapping.map(stateMapping)
      : USStateAndTerritoryMapping.map(stateMapping);

  return (
    <NodeContainer {...props}>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>{subheaderText}</Question>
        <InputGroupWrapper>
          <InputWrapper>
            <AdornedFilterDropdown
              label="State"
              value={stateDisplay}
              onChange={handleStateChange}
              errorText={'Please select a state'}
              filterDropdownPredicate={stateDisplay !== undefined}
              options={stateOptions}
            />
          </InputWrapper>
        </InputGroupWrapper>
        <StateLightbulbText state={state} permittedStates={permittedStates} />
      </NodeForm>
    </NodeContainer>
  );
};
