import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { DollarInput } from '../../../../components/shared/inputs/dollar-input';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { DataModel } from '../../../../store/data-models/slice';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { SecondaryMortgageDataModel } from './data-model';

export interface HelocFollowUpNodeModel extends DataModel {
  write: {
    helocBalance?: number;
    helocRolledIn?: string;
  };
  read: {
    rollInResponses: Array<{ value: string; label: string }>;
  };
}

export const helocBalancePredicate = (helocBalance: number | undefined) =>
  helocBalance !== undefined && helocBalance > 0;

export const rollHelocResponsePredicate = (helocRolledIn: string | undefined) =>
  helocRolledIn !== undefined;

export const helocFollowUpPredicate = (
  helocBalance: number | undefined,
  helocRolledIn: string | undefined
) => {
  return !!helocBalancePredicate(helocBalance) && !!rollHelocResponsePredicate(helocRolledIn);
};

export const HelocFollowUpQuestions = ({
  nodeId,
  dataModel: {
    write: { helocBalance, helocRolledIn },
    read: { rollInResponses }
  },
  onChangeFactory
}: NodeProperties<SecondaryMortgageDataModel>) => {
  const handleHelocChange = onChangeFactory<number>('helocBalance', nodeId);
  const handleHelocChangeWrapper = useCallback(
    ({ floatValue }: NumberFormatValues) => handleHelocChange(floatValue, undefined),
    [handleHelocChange]
  );
  const handleRolledInChange = onChangeFactory<string>('helocRolledIn', nodeId);
  const handleRolledInChangeWrapped = useCallback(
    (_: any, newValue?: string) => handleRolledInChange(newValue, undefined),
    [handleRolledInChange]
  );

  const rollInOptions = useMemo(
    () =>
      rollInResponses.map(({ label, value }) => (
        <InputWrapper key={value} size="single">
          <OwnUpRadioButton value={value}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [rollInResponses]
  );

  return (
    <React.Fragment>
      <Question>
        How much of your <strong>HELOC</strong> have you drawn down?
      </Question>
      <DollarInput
        id="HELOC balance"
        label="HELOC balance"
        value={helocBalance}
        decimalScale={0}
        errorText={'Please enter a valid loan balance.'}
        dollarInputPredicate={helocBalancePredicate(helocBalance)}
        onValueChange={handleHelocChangeWrapper}
      />
      <Question>Would you like to roll it into your new mortgage?</Question>
      <OwnUpRadioGroup
        name="Roll In HELOC"
        value={helocRolledIn}
        onChange={handleRolledInChangeWrapped}
      >
        <InputGroupWrapper>
          <StandaloneErrorWrapper predicateResult={radioButtonPredicate(helocRolledIn)} />
          {rollInOptions}
        </InputGroupWrapper>
      </OwnUpRadioGroup>
    </React.Fragment>
  );
};
