import * as t from 'type-shift';
import { DataModelState } from '../store/data-models/slice';
import { loggedInFetch, RedirectParameters } from './authentication/fetch';
import { QuestionnaireModel } from './questionnaire/questionnaire-model';

export interface CreateQuestionnaireQueryResult {
  model: Partial<QuestionnaireModel>;
  path: string[];
  dataModels?: DataModelState;
}

export const createQuestionnaireQueryResultConverter = t.shape<CreateQuestionnaireQueryResult>({
  model: t.shape({}),
  path: t.array(t.string),
  dataModels: t.optional(t.shape({}))
});

export interface QuestionnaireQueryResult {
  path: string[];
  dataModels?: DataModelState;
  model?: Partial<QuestionnaireModel>;
}

export const questionnaireQueryResultConverter = t.shape<QuestionnaireQueryResult>({
  model: t.optional(t.shape({})),
  path: t.array(t.string),
  dataModels: t.optional(t.shape({}))
});

export interface QueryIncompleteQuestionnairesResult {
  userId: string;
  questionnaireId: string;
  questionnaireType: string;
  lastModifiedTime: number;
  nextNode: string;
  model?: Partial<QuestionnaireModel>;
}

export const queryIncompleteQuestionnairesResultConverter = t.array(
  t.shape<QueryIncompleteQuestionnairesResult>({
    userId: t.string,
    questionnaireId: t.string,
    questionnaireType: t.string,
    lastModifiedTime: t.number,
    nextNode: t.string
  })
);

// The legacy questionnaire uses different questionnaire types so we need a map to go between.
export const questionnaireTypeMap: Record<string, string> = {
  refinance: 'refinance-full'
};

const signInRedirectParameters: RedirectParameters = {
  error: 'Expired',
  statusText: 'You are no longer logged in, redirecting to login page',
  target: 'sign-in'
};

/**
 * Attempt to create a new questionnaire in the backend using the given
 *   model.
 *
 * @param model - Pre-populated questionnaire data. Must at least contain
 *   a questionnaireType.
 */
export const createQuestionnaire = async (
  // Extract ids from the payload. userId will be used in the header (if defined)
  //   questionnaireId will be ignored, as the backend will produce a new one
  //   for the user.
  { userId, questionnaireId: _, ...model }: Partial<QuestionnaireModel>
): Promise<CreateQuestionnaireQueryResult> => {
  if (!model.questionnaireType) {
    throw new Error('Failed to create questionnaire. No questionnaireType is present.');
  }
  const response = await loggedInFetch(
    `${process.env.SITE_ROOT}/api/questionnaire`,
    {
      method: 'POST',
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        ...(userId ? { 'x-ou-auth': userId } : {}),
        'Content-Type': 'application/json'
        /* eslint-enable @typescript-eslint/naming-convention */
      },
      body: JSON.stringify({ model })
    },
    signInRedirectParameters
  );
  if (response.ok) {
    const json = await response.json();
    return createQuestionnaireQueryResultConverter(json);
  }

  throw new Error(`Failed to create Questionnaire: ${response.statusText}`);
};

/**
 * Fires a PUT operation to save your current model state to the backend.
 *
 * @param model - Questionnaire model to save.
 * @param nodeId - id of the current node
 * @param path - Current path.
 */
export const saveQuestionnaire = async (
  // Extract read-only fields from payload
  { userId, questionnaireId, ...model }: Partial<QuestionnaireModel>,
  nodeId: string,
  path: string[]
): Promise<QuestionnaireQueryResult> => {
  if (!userId) {
    throw new Error('Failed to save questionnaire. No userId is present.');
  }
  if (!questionnaireId) {
    throw new Error('Failed to save questionnaire: No questionnaireId is present.');
  }
  if (!path || path.length === 0) {
    throw new Error('Failed to save questionnaire: No path is present.');
  }
  const response = await loggedInFetch(
    `${process.env.SITE_ROOT}/api/questionnaire/${questionnaireId}/node/${nodeId}`,
    {
      method: 'PUT',
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */ 'x-ou-auth': userId,
        'Content-Type': 'application/json'
        /* eslint-enable @typescript-eslint/naming-convention */
      },
      body: JSON.stringify({ model, path })
    },
    signInRedirectParameters
  );
  if (response.ok) {
    const json = await response.json();
    return questionnaireQueryResultConverter(json);
  }

  throw new Error(
    `Failed to save Questionnaire '${questionnaireId}' for UserId '${userId}': ${response.statusText}`
  );
};

export const saveQuestionnaireParams = async (
  // Extract read-only fields from payload
  { userId, questionnaireId, ...model }: Partial<QuestionnaireModel>
): Promise<QuestionnaireQueryResult> => {
  if (!userId) {
    throw new Error('Failed to save questionnaire. No userId is present.');
  }
  if (!questionnaireId) {
    throw new Error('Failed to save questionnaire: No questionnaireId is present.');
  }
  const response = await loggedInFetch(
    `${process.env.SITE_ROOT}/api/questionnaire/${questionnaireId}`,
    {
      method: 'PUT',
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */ 'x-ou-auth': userId,
        'Content-Type': 'application/json'
        /* eslint-enable @typescript-eslint/naming-convention */
      },
      body: JSON.stringify({ model })
    },
    signInRedirectParameters
  );
  if (response.ok) {
    const json = await response.json();
    return questionnaireQueryResultConverter(json);
  }

  throw new Error(
    `Failed to save Questionnaire '${questionnaireId}' for UserId '${userId}': ${response.statusText}`
  );
};

/**
 * Attempt to fetch a questionnaire from the backend using the input ids.
 *
 * @param userId
 * @param questionnaireId
 * @param nodeId - Fetch up to this node (if provided)
 */
export const fetchQuestionnaire = async (
  userId: string,
  questionnaireId: string,
  questionnaireType: string,
  nodeId?: string
): Promise<QuestionnaireQueryResult> => {
  const url = `${process.env.SITE_ROOT}/api/questionnaire/${questionnaireId}${
    nodeId ? `/node/${nodeId}` : ''
  }`;
  const response = await loggedInFetch(
    url,
    {
      method: 'GET',
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */ 'x-ou-auth': userId,
        'Content-Type': 'application/json'
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    },
    signInRedirectParameters
  );
  if (response.ok) {
    const json = await response.json();
    return questionnaireQueryResultConverter(json);
  }

  throw new Error(
    `Failed to fetch Questionnaire '${questionnaireId}' for UserId '${userId}' and NodeId '${nodeId}: ${response.statusText}`
  );
};

/**
 * Attempts to get all incomplete questionnaires related to the given accountId
 * @param accountId
 * @returns An array on incompleted questionnaires from the accountId
 */
export const queryIncompleteQuestionnaires = async ({
  userId
}: {
  userId: string;
}): Promise<QueryIncompleteQuestionnairesResult[]> => {
  const response = await loggedInFetch(
    `${process.env.SITE_ROOT}/api/questionnaire/incomplete`,
    {
      method: 'GET',
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */ 'x-ou-auth': userId,
        'Content-Type': 'application/json'
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    },
    signInRedirectParameters
  );
  if (response.ok) {
    const json = await response.json();
    return queryIncompleteQuestionnairesResultConverter(json);
  }

  throw new Error(`Failed to fetch Incomplete Questionnaires: ${response.statusText}`);
};

const sendCodeResponseConverter = t.shape({ sent: t.boolean, error: t.optional(t.string) });
/**
 * Attempts to send a new verification code for step up authentication.
 * @param userId
 * @param questionnaireId
 */
export const sendVerificationCode = async (userId: string, questionnaireId: string) => {
  const response = await loggedInFetch(
    `${process.env.SITE_ROOT}/api/questionnaire/${questionnaireId}/send-code`,
    {
      method: 'POST',
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */ 'x-ou-auth': userId,
        'Content-Type': 'application/json'
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    },
    signInRedirectParameters
  );
  if (response.ok) {
    const json = await response.json();
    return sendCodeResponseConverter(json);
  }
  console.log(`Failed to send verification code: ${response.statusText}`);
  return { sent: false };
};
