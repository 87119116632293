import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { LenderOfferDataModel } from './data-model';
import { LenderOfferNodeImpl } from './element';
import { lenderOfferPredicate } from './predicate';

export const acceptedOfferLenderOfferNodeId = 'lender-offer';

export const AcceptedOfferLenderOfferNode = () => {
  const props = useNodeProperties<LenderOfferDataModel>(
    acceptedOfferLenderOfferNodeId,
    lenderOfferPredicate
  );

  return (
    <Loader>
      <LenderOfferNodeImpl {...props} />
    </Loader>
  );
};
