import { createSlice } from '@reduxjs/toolkit';

export type PathState = string[];

const pathSlice = createSlice({
  name: 'path',
  initialState: [] as PathState,
  reducers: {
    handleSetPath: (_, action) => [...action.payload]
  }
});

export const {
  actions: { handleSetPath: setPathAction },
  reducer: pathReducer
} = pathSlice;
