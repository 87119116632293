import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuestionnaireModel } from '../../modules/questionnaire/questionnaire-model';
import { defaultQuestionnaireState } from './state';

/**
 * Slice of state for the questionnaire produces the actions and reducers for
 * handling loading and updating the questionnaire.
 */
const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: defaultQuestionnaireState,
  reducers: {
    updateQuestionnaire(state, action: PayloadAction<Partial<QuestionnaireModel>>) {
      return { ...state, ...action.payload };
    },
    loading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    }
  }
});

export const {
  actions: { updateQuestionnaire: updateQuestionnaireAction, loading: loadingAction },
  reducer: questionnaireReducer
} = questionnaireSlice;
