import { SLATE_100 } from '@rategravity/own-up-component-library';
import React from 'react';
import { HintButton, HintButtonProps } from '../../../shared/hint/hint-button';
import { HintBody } from '../../../shared/hint/typography/hint-body';

export const ApproximateCreditScoreHint = (props: Omit<HintButtonProps, 'children' | 'title'>) => (
  <HintButton {...props} title="Why do we need this?">
    <HintBody style={{ color: SLATE_100 }}>
      Since you&apos;re buying with a co-borrower, the lender will only use the
      <strong> lower </strong>
      of your two credit scores.
    </HintBody>
  </HintButton>
);
