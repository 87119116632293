import { SplitFactory } from '@splitsoftware/splitio';

export const ONBOARDING_RANGES_TEST = 'Onboarding_Ranges_Test';
export const ONBOARDING_SHORTENED_NON_CONCIERGE = 'Onboarding_shortened_non_concierge';
export const QUESTIONNAIRE_SOFT_CREDIT_EXPLANATION = 'questionnaire_soft_credit_explanation';

let splitFactoryInstance: SplitIO.ISDK | undefined;
let splitClientInstance: SplitIO.IClient | undefined;

export const getSplitFactory = () => {
  return splitFactoryInstance;
};

export const getSplitClientInstance = () => {
  return splitClientInstance;
};

let lastUserId: string | undefined;

export const initializeSplitClient = (userId: string) => {
  // Do not re-initialize the split client if it exists and the
  //   userId has not changed.
  if (splitFactoryInstance && splitClientInstance && userId === lastUserId) {
    return splitClientInstance;
  }

  const splitUrl = process.env.SPLIT_URL || 'https://staging.split.ownup.com';
  const splitConfig = {
    core: {
      authorizationKey: process.env.SPLIT_API_KEY || 'qdjduua8u5orl12gguii27vam9g73rccr5lf',
      key: userId,
      trafficType: 'Anonymous'
    },
    urls: {
      sdk: `${splitUrl}/sdk-api`,
      events: `${splitUrl}/events-api`,
      auth: `${splitUrl}/auth-api`
    }
  };

  lastUserId = userId;
  splitFactoryInstance = SplitFactory(splitConfig);
  splitClientInstance = splitFactoryInstance.client();
  return splitClientInstance;
};
