import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RouteParams } from '../modules/path-helpers';
import { sendToTrackingEventAction } from '../store/events/actions';
import { userIdSelector } from '../store/questionnaire/selectors';

export const useDebounceTracker = () => {
  const dispatch = useDispatch();

  // Fetch questionnaire type from params, it may be
  //    needed to init the redux store
  const { questionnaireType, nodeId } = useParams<RouteParams>();

  // Get userId
  const userId = useSelector(userIdSelector);

  // Use local state
  const debouncer = useRef<number | null>(null);
  const userStartTime = useRef<number>(0);

  useEffect(() => {
    // Start tracking user duration on a card
    userStartTime.current = Date.now();

    // Fire off a tracking event every 60 seconds or so when a user is sitting on a card
    debouncer.current = setInterval(
      () => {
        dispatch(
          sendToTrackingEventAction({
            method: 'track',
            event: 'Onboarding_Stalled',
            properties: {
              nodeId,
              userId,
              questionnaireType,
              userDuration: (Date.now() - userStartTime.current) / 1000
            }
          })
        );
      },
      60000 // fire every 60s
    ) as unknown as number; // In Node.js, `setInterval` returns a `NodeJS.Timeout` object, but in a browser environment, it returns a number.

    return () => {
      // Clear the interval timer
      if (debouncer.current) {
        clearInterval(debouncer.current);
      }
    };
  }, [dispatch, nodeId, userId, questionnaireType, userStartTime]);
};
