import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { RealEstateAgentDataModel } from '../../common/real-estate-agent-node/data-model';
import { RealEstateAgentNodeImpl } from '../../common/real-estate-agent-node/element';
import { realEstateAgentPredicate } from '../../common/real-estate-agent-node/predicate';

export const purchaseRealEstateAgentNodeId = 'real-estate-agent';

export const PurchaseRealEstateAgentNode = () => {
  const props = useNodeProperties<RealEstateAgentDataModel>(
    purchaseRealEstateAgentNodeId,
    realEstateAgentPredicate
  );

  return (
    <Loader>
      <RealEstateAgentNodeImpl {...props} />
    </Loader>
  );
};
