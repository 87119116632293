import React, { useEffect } from 'react';
import { loadPostQuestionnairePitchAction } from '../../../../store/events/actions';
import { NodeContainer } from '../../../shared/container';
import { Description } from '../../../shared/typography/description';
import { Headline } from '../../../shared/typography/headline';
import { NodeProperties } from '../../properties';
import { NextStepsContact } from '../next-steps/next-steps-contact';
import { NextStepsDataModel } from './data-model';

export interface NextStepsNodeProps extends NodeProperties<NextStepsDataModel> {
  userId?: string;
  questionnaireId?: string;
}

export const NextStepsNodeImpl = ({
  userId,
  questionnaireId,
  dispatch,
  dataModel: {
    read: { advisor, questionnaireType, pitchType }
  }
}: NextStepsNodeProps) => {
  useEffect(() => {
    dispatch(
      loadPostQuestionnairePitchAction({
        userId,
        questionnaireId,
        questionnaireType,
        pitchType
      })
    );
  }, [advisor, userId, questionnaireId, questionnaireType, pitchType, dispatch]);
  return (
    <NodeContainer>
      <Headline>Unfortunately our lenders cannot offer you a loan at this time.</Headline>

      <Description>
        Your responses indicate you have circumstances ineligible for a loan from our marketplace.
      </Description>

      <NextStepsContact advisor={advisor} />
    </NodeContainer>
  );
};
