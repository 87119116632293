import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { CoborrowerDataModel } from '../../common/coborrower-node/data-model';
import { CoborrowerNodeImpl } from '../../common/coborrower-node/element';
import { coborrowerPredicate } from '../../common/coborrower-node/predicate';

export const preapprovalCoborrowerNodeId = 'coborrower';

export const PreapprovalCoborrowerNode = () => {
  const props = useNodeProperties<CoborrowerDataModel>(
    preapprovalCoborrowerNodeId,
    coborrowerPredicate
  );
  return (
    <Loader>
      <CoborrowerNodeImpl {...props} />
    </Loader>
  );
};
