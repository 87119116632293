import React, { ReactNode } from 'react';
import { NodeIndent } from '../../../shared/containers/node-indent';
import { NodeProperties } from '../../properties';
import { SecondaryMortgageDataModel } from './data-model';
import { HelocFollowUpQuestions } from './heloc-follow-up';
import { SecondaryMortgageFollowUpQuestions } from './secondary-mortgage-follow-up';

interface FollowUpNodeProperties<T> extends NodeProperties<T> {
  secondaryMortgageResponse?: string;
}

export const FollowUpQuestions = ({
  secondaryMortgageResponse,
  ...props
}: FollowUpNodeProperties<SecondaryMortgageDataModel>) => {
  let children: ReactNode = null;
  switch (secondaryMortgageResponse) {
    case 'SecondMortgage':
      children = <SecondaryMortgageFollowUpQuestions {...props} />;
      break;
    case 'HELOC':
      children = <HelocFollowUpQuestions {...props} />;
      break;
    case 'MortgageAndHeloc':
      children = (
        <React.Fragment>
          <SecondaryMortgageFollowUpQuestions {...props} />
          <HelocFollowUpQuestions {...props} />
        </React.Fragment>
      );
      break;
  }
  return <NodeIndent>{children}</NodeIndent>;
};
