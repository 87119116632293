import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { VeteransBenefitsConsentDataModel } from '../../common/veterans-benefits-consent-node/data-model';
import { VeteransBenefitsConsentImpl } from '../../common/veterans-benefits-consent-node/element';
import { veteransBenefitsConsentPredicate } from '../../common/veterans-benefits-consent-node/predicate';

export const preapprovalVeteransBenefitsConsentId = 'veterans-benefits-consent';

export const PreapprovalVeteransBenefitsConsentNode = () => {
  const props = useNodeProperties<VeteransBenefitsConsentDataModel>(
    preapprovalVeteransBenefitsConsentId,
    veteransBenefitsConsentPredicate
  );
  return (
    <Loader>
      <VeteransBenefitsConsentImpl {...props} />
    </Loader>
  );
};
