import { OwnUpBody, OwnUpBodyProps, PropsWithTheme } from '@rategravity/own-up-component-library';
import * as colors from '@rategravity/own-up-component-library/colors';
import React from 'react';
import styled from 'styled-components';
import { createContainerWrappedTypography } from './container';

/**
 * Extension of {@see OwnUpBodyProps}
 */
export type DescriptionProps = Omit<OwnUpBodyProps, 'variant'>;

const DescriptionImpl = (props: DescriptionProps) => <OwnUpBody {...props} variant="body2" />;

export const DescriptionContainer = createContainerWrappedTypography(
  'description',
  DescriptionImpl
);

/**
 * The "Description" is small text that provides additional
 *   context.
 *
 * Figma: https://www.figma.com/file/xOJwjvBP1RFcnViMvWLKsX/Koala-%2F-System-Skin?node-id=3%3A1532
 */
export const Description = styled(DescriptionContainer)`
  /* Wrapping this in a new scope helps prevent css collisions */
  & {
    /* 16px */
    margin-top: 1rem;

    .MuiTypography-root {
      color: ${colors.SLATE_100};
    }

    & a.MuiTypography-root {
      color: ${({ theme }: PropsWithTheme<{}>) => theme.linkColors.linkFontColor};
    }
  }
`;
