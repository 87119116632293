import { OwnUpNumberInput, OwnUpNumberInputProps } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo, useState } from 'react';
import { useClickedSubmitContext } from '../../../hooks/use-clicked-submit-context';
import { InputWrapper, InputWrapperSizes } from '../form/inputs/input-wrapper';

/**
 * Number Input wrapper that supports error states.
 */

interface AdornedNumberInputProps extends OwnUpNumberInputProps {
  /** Text to be passed as helpertext if there is an error */
  errorText?: string;
  numberInputPredicate?: boolean;
  wrapperSize?: InputWrapperSizes;
  defaultHelperText?: string;
}

export const AdornedNumberInput = ({
  errorText = '',
  numberInputPredicate = false,
  wrapperSize = 'single',
  defaultHelperText = '',
  ...props
}: AdornedNumberInputProps) => {
  const { hasClickedSubmit } = useClickedSubmitContext();

  /**
   *  defaultState = {showError:props.value !== undefined } allows illegal
   *   pre-filled values to trigger the error state by default.
   */
  const [showError, setShowError] = useState(props.value !== undefined);
  const handleBlur = useCallback(() => {
    if (!showError) {
      setShowError(true);
    }
  }, [showError, setShowError]);

  const adornedNumberInputErrorTextMemo = useMemo(() => {
    /**
     * This should be triggered by: 1. a default numberInputPredicate = false, e.g. a defaultValue
     *  of 0 in an input that does not allow zeroes. or 2. a wrong user interaction
     *  (tracked by 'onBlur'), e.g interacting with the input but entering nothing or zero.
     */
    return (!numberInputPredicate && hasClickedSubmit) || (showError && !numberInputPredicate)
      ? errorText
      : undefined;
  }, [showError, errorText, numberInputPredicate, hasClickedSubmit]);

  return (
    <InputWrapper size={wrapperSize}>
      <OwnUpNumberInput
        {...props}
        labelPosition="inner"
        helperText={adornedNumberInputErrorTextMemo || defaultHelperText}
        allowNegative={false}
        allowLeadingZeros={props.allowLeadingZeros || false}
        fixedDecimalScale={true}
        error={!!adornedNumberInputErrorTextMemo}
        onBlur={handleBlur}
      />
    </InputWrapper>
  );
};
