import { IconLink, OwnUpBodyMedium, OwnUpSmallBody } from '@rategravity/own-up-component-library';
import { EmailIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/email';
import { PhoneIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/phone';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Headline } from '../../../shared/typography/headline';
import { NextStepsDataModel } from '../next-steps-node/data-model';
import { AdvisorImage } from './advisor-image';
import { Advisor, useAdvisor } from './next-steps-helpers';

export type NextStepsContactProps = Pick<NextStepsDataModel['read'], 'advisor'>;

const ContactContainer = styled.div`
  margin-top: 80px;
`;

const AdvisorPictureContainer = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  /* Image sizes are hard-set in the old component library so important tags are needed */
  img {
    height: 78px !important;
    width: 78px !important;
    border-radius: 50%;
    margin-right: 20px;
  }
`;

const ContactInfoContainer = styled.div`
  margin-top: 16px;
  & > div:not(:first-child) {
    margin-top: 8px;
  }
`;

const FeaturedAdvisor = ({ advisor }: { advisor: Advisor }) => (
  <React.Fragment>
    <AdvisorPictureContainer>
      <div>
        <AdvisorImage advisor={advisor} />
      </div>
      <div>
        <OwnUpBodyMedium variant="body1">{advisor.name.full}</OwnUpBodyMedium>
        <OwnUpSmallBody variant="body1">NMLS: {advisor.nmls}</OwnUpSmallBody>
      </div>
    </AdvisorPictureContainer>
    <OwnUpBodyMedium style={{ marginTop: '24px' }} variant="body1">
      Ask a question:
    </OwnUpBodyMedium>
    <OwnUpSmallBody variant="body2">
      Reach out to {advisor.name.first} directly and they will get back to you as soon as possible.
    </OwnUpSmallBody>
  </React.Fragment>
);

export const NextStepsContact = ({ advisor = '' }: NextStepsContactProps) => {
  const featuredAdvisor = useAdvisor(advisor);
  const availableAdvisor = useMemo(
    () => (featuredAdvisor?.deleted ? undefined : featuredAdvisor),
    [featuredAdvisor]
  );
  const header = useMemo(() => {
    return (
      <React.Fragment>
        <Headline>
          {availableAdvisor ? 'Questions? Talk to your advisor.' : "Questions? We're here to help."}
        </Headline>
        {availableAdvisor ? (
          <FeaturedAdvisor advisor={availableAdvisor} />
        ) : (
          <OwnUpSmallBody style={{ marginTop: '16px' }} variant="body2">
            Reach out to a member of our Customer Service team directly.
            <br />
            You will receive a response as soon as possible.
          </OwnUpSmallBody>
        )}
      </React.Fragment>
    );
  }, [availableAdvisor]);
  const contactInfo = useMemo(() => {
    const phone = availableAdvisor?.phone;
    const email = availableAdvisor?.email || 'hello@ownup.com';
    return (
      <ContactInfoContainer>
        {!!phone && (
          <IconLink icon={<PhoneIcon />} iconPlacement="left" link={`tel:${phone}`}>
            {phone}
          </IconLink>
        )}
        <IconLink icon={<EmailIcon />} iconPlacement="left" link={`mailto:${email}`}>
          {email}
        </IconLink>
      </ContactInfoContainer>
    );
  }, [availableAdvisor]);
  return (
    <ContactContainer>
      {header}
      {contactInfo}
    </ContactContainer>
  );
};
