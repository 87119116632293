export const MORTGAGE_AND_HELOC = 'MortgageAndHeloc';
export const HELOC = 'HELOC';
export const SECOND_MORTGAGE = 'SecondMortgage';
export const NO_SECOND_MORTGAGE = 'No';

export const getMortgageResponse = (
  heloc: boolean | undefined,
  secondaryMortgage: boolean | undefined
) => {
  if (heloc && secondaryMortgage) {
    return MORTGAGE_AND_HELOC;
  } else if (heloc) {
    return HELOC;
  } else if (secondaryMortgage) {
    return SECOND_MORTGAGE;
  } else if (heloc === false && secondaryMortgage === false) {
    return NO_SECOND_MORTGAGE;
  } else {
    return undefined;
  }
};
