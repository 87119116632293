/**
 * Assuming the shape /{questionnaireType}/{nodeId}
 * split will save an empty string so the node id
 * will be the third element in the split array
 */
const ID_INDEX = 2;

export const getNodeIdFromPathname = (pathname: string) => {
  const paths = pathname.split('/');
  if (paths.length > ID_INDEX) {
    return paths[ID_INDEX];
  } else {
    return '';
  }
};

export interface RouteParams {
  questionnaireType: string;
  nodeId: string;
}
