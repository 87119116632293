import React, { useMemo } from 'react';
import { stateLookUp } from '../../../../modules/state-helpers';
import { NodeContainer } from '../../../shared/container';
import { NodeForm } from '../../../shared/form/node-form';
import { Description } from '../../../shared/typography/description';
import { Headline } from '../../../shared/typography/headline';
import { Overline } from '../../../shared/typography/overline';
import { NodeProperties } from '../../properties';
import { StateInfoDataModel } from './data-model';

interface StateInfoNodeProps extends NodeProperties<StateInfoDataModel> {}

export const StateInfoNodeImpl = ({
  dataModel: {
    read: { state }
  },
  ...props
}: StateInfoNodeProps) => {
  const stateName = useMemo(() => stateLookUp[state], [state]);
  return (
    <NodeContainer {...props}>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Headline>We&apos;re not licensed in {stateName} quite yet</Headline>
        <Description>
          However, our team is available to have an educational conversation with you about the home
          buying process.
        </Description>
      </NodeForm>
    </NodeContainer>
  );
};
