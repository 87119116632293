import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { DollarInput } from '../../../../components/shared/inputs/dollar-input';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { infoModalOpenedAction, sendToTrackingEventAction } from '../../../../store/events/actions';
import { NodeContainer } from '../../../shared/container';
import { NodeIndent } from '../../../shared/containers/node-indent';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Feedback } from '../../../shared/typography/feedback';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { PropertyTypeDataModel } from './data-model';
import { PropertyTypeHint } from './hint';
import { propertyNodePredicate } from './predicate';

interface PropertyTypeNodeProps extends NodeProperties<PropertyTypeDataModel> {
  /**
   * How to ask for this property type.
   */
  mainText: string;
}

const CONDO = 'Condo';
const TOWNHOME = 'Townhome';

export const PropertyTypeNodeImpl = ({
  dataModel,
  onChangeFactory,
  dispatch,
  mainText,
  questionnaireType,
  splitClient,
  ...props
}: PropertyTypeNodeProps) => {
  const {
    read: { askFees, propertyTypes, permittedTypes },
    write: { propertyType, condoFee }
  } = dataModel;

  const changeWrapper = onChangeFactory<string>('propertyType', props.nodeId);
  const onPropertyTypeChange = (_: any, newValue?: string) => {
    // Only fire event when user hits a hard stop (chooses an unpermitted type).
    if (newValue !== undefined && !permittedTypes.includes(newValue)) {
      dispatch(
        sendToTrackingEventAction({
          method: 'track',
          event: 'Onboarding_Stop_InvalidPropertyType',
          properties: {
            nodeId: props.nodeId,
            questionnaireType,
            propertyType: newValue
          }
        })
      );
    }
    changeWrapper(newValue, undefined);
  };

  const handleCondoFeeChange = useCallback(
    ({ value }: NumberFormatValues) =>
      onChangeFactory<number>('condoFee', props.nodeId)(Number(value), undefined),
    [onChangeFactory, props.nodeId]
  );

  const renderLightbulbContainer = useMemo(() => {
    const propertyTypeLabel = propertyTypes.find(
      (propType) => propType.value === propertyType
    )?.label;
    return propertyType &&
      !permittedTypes.includes(propertyType) &&
      process.env.DEPLOY_ENV !== 'staging' ? (
      <Feedback>Shoot! We don&apos;t offer loans for {propertyTypeLabel}s at this time.</Feedback>
    ) : null;
  }, [permittedTypes, propertyType, propertyTypes]);

  const feeMainText =
    propertyType === CONDO
      ? 'How much is your monthly condo fee?'
      : "How much is your monthly homeowner's association fee?";
  const feeErrorText =
    propertyType === CONDO
      ? 'Please enter a valid monthly condo fee.'
      : "Please enter a valid homeowner's association fee.";
  const feeNameText = propertyType === CONDO ? 'Monthly Condo Fee' : 'Monthly HOA';
  const renderCondoFeeQuestion = useMemo(() => {
    if (askFees && (propertyType === CONDO || propertyType === TOWNHOME)) {
      return (
        <NodeIndent>
          <Question>{feeMainText}</Question>
          <DollarInput
            id={feeNameText}
            label={feeNameText}
            value={condoFee?.toString()}
            decimalScale={0}
            errorText={feeErrorText}
            dollarInputPredicate={propertyNodePredicate(dataModel)}
            onValueChange={handleCondoFeeChange}
            {...props}
          />
        </NodeIndent>
      );
    }
    return <React.Fragment />;
  }, [
    askFees,
    condoFee,
    propertyType,
    handleCondoFeeChange,
    feeErrorText,
    feeMainText,
    feeNameText,
    dataModel,
    props
  ]);

  const propertyTypeOptions = useMemo(
    () =>
      propertyTypes.map(({ label, value }) => (
        <InputWrapper key={value} size="half">
          <OwnUpRadioButton value={value}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [propertyTypes]
  );

  const clickInfoModal = useCallback(() => {
    dispatch(infoModalOpenedAction({ nodeId: props.nodeId }));
  }, [dispatch, props.nodeId]);

  return (
    <NodeContainer {...props}>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question hint={<PropertyTypeHint onClick={clickInfoModal} />}>{mainText}</Question>
        <OwnUpRadioGroup name="Property Type" value={propertyType} onChange={onPropertyTypeChange}>
          <InputGroupWrapper>
            <StandaloneErrorWrapper predicateResult={radioButtonPredicate(propertyType)} />
            {propertyTypeOptions}
          </InputGroupWrapper>
        </OwnUpRadioGroup>
        {renderLightbulbContainer}
        {renderCondoFeeQuestion}
      </NodeForm>
    </NodeContainer>
  );
};
