import { OwnUpNumberInput, OwnUpNumberInputProps } from '@rategravity/own-up-component-library';
import { DollarIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/dollar';
import React, { useCallback, useMemo, useState } from 'react';
import { useClickedSubmitContext } from '../../../hooks/use-clicked-submit-context';
import { InputGroupWrapper } from '../form/inputs/input-group-wrapper';
import { InputWrapper } from '../form/inputs/input-wrapper';

/**
 * Number Input wrapper adorned with cash that supports error states.
 */

interface DollarInputProps extends OwnUpNumberInputProps {
  /** Text to be passed as helpertext if there is an error */
  errorText?: string;
  dollarInputPredicate?: boolean;
}

export const DollarInput = ({
  errorText = '',
  dollarInputPredicate = false,
  ...props
}: DollarInputProps) => {
  const { hasClickedSubmit } = useClickedSubmitContext();
  /**
   *  defaultState = {showError:props.value !== undefined } allows illegal
   *   pre-filled values to trigger the error state by default.
   */
  const [showError, setShowError] = useState(props.value !== undefined && props.value !== null);
  const handleBlur = useCallback(() => {
    if (!showError) {
      setShowError(true);
    }
  }, [showError, setShowError]);

  const dollarInputErrorTextMemo = useMemo(() => {
    /**
     * This should be triggered by: 1. a user clicking `continue` without making a selection
     *  2. a default dollarInputError = true, e.g. a defaultValue of 0 in an input that does
     *  not allow zeroes. or 3. a wrong user interaction (tracked by 'onBlur'), e.g interacting
     *  with the input but entering nothing or zero.
     */
    return (!dollarInputPredicate && hasClickedSubmit) || (showError && !dollarInputPredicate)
      ? errorText
      : undefined;
  }, [showError, dollarInputPredicate, errorText, hasClickedSubmit]);

  return (
    <InputGroupWrapper>
      <InputWrapper size="single">
        <OwnUpNumberInput
          {...props}
          labelPosition="inner"
          $leadingIcon={<DollarIcon />}
          helperText={dollarInputErrorTextMemo}
          allowNegative={false}
          allowLeadingZeros={false}
          fixedDecimalScale={true}
          error={!!dollarInputErrorTextMemo}
          onBlur={handleBlur}
        />
      </InputWrapper>
    </InputGroupWrapper>
  );
};
