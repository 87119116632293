import { FthbDataModel } from './data-model';

export const fthbPredicate = ({
  write: { isFirstTimeBuyer, isPlanningToSell, ownOrRent }
}: FthbDataModel) => {
  return (
    isFirstTimeBuyer ||
    (isFirstTimeBuyer === false &&
      (ownOrRent === 'Rent' || (isPlanningToSell !== undefined && ownOrRent !== undefined)))
  );
};
