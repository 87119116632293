import { currency } from '@rategravity/frontend/modules/numbers';
import React, { useEffect, useMemo } from 'react';
import { buildYCBMLink } from '../../../../modules/build-ycbm-link';
import { loadConsultPitchAction } from '../../../../store/events/actions';
import { NodeContainer } from '../../../shared/container';
import { Description } from '../../../shared/typography/description';
import { Headline } from '../../../shared/typography/headline';
import { NodeProperties } from '../../properties';
import { NextStepsReviewItems } from '../next-steps/next-steps-review-items';
import { NextStepsYcbmPitch } from '../next-steps/next-steps-ycbm-pitch';
import { NextStepsDataModel } from '../next-steps-node/data-model';

export interface ConsultationNextStepsNodeProps extends NodeProperties<NextStepsDataModel> {
  userId?: string;
  questionnaireId?: string;
}

const useConsultationHeader = (
  questionnaireType: NextStepsDataModel['read']['questionnaireType']
) =>
  useMemo(
    () =>
      questionnaireType === 'preapproval' ? (
        <React.Fragment>Your preapproval is processing</React.Fragment>
      ) : (
        <React.Fragment>Let&apos;s walk through your best options together</React.Fragment>
      ),
    [questionnaireType]
  );

const useConsultationDescription = (
  questionnaireType: NextStepsDataModel['read']['questionnaireType'],
  possibleSavings: NextStepsDataModel['read']['possibleSavings']
) =>
  useMemo(
    () =>
      questionnaireType === 'preapproval' || !possibleSavings ? (
        <React.Fragment>Let&apos;s work together to finalize your details.</React.Fragment>
      ) : (
        <React.Fragment>
          Our best offers save customers like you an average of{' '}
          <strong>{currency(possibleSavings, 0)}</strong> per year.
        </React.Fragment>
      ),
    [possibleSavings, questionnaireType]
  );

export const ConsultationNextStepsNodeImpl = ({
  userId,
  questionnaireId,
  dispatch,
  dataModel: {
    read: { advisor, name, email, state, questionnaireType, pitchType, possibleSavings }
  }
}: ConsultationNextStepsNodeProps) => {
  const ycbmLink = useMemo(
    () => buildYCBMLink({ name, email, state, questionnaireType, pitchType, advisor }),
    [name, email, state, questionnaireType, pitchType, advisor]
  );

  const header = useConsultationHeader(questionnaireType);
  const description = useConsultationDescription(questionnaireType, possibleSavings);

  useEffect(() => {
    dispatch(loadConsultPitchAction({ userId, questionnaireId, questionnaireType, pitchType }));
  }, [userId, questionnaireId, questionnaireType, pitchType, dispatch]);

  return (
    <NodeContainer>
      <Headline>{header}</Headline>
      <Description>{description}</Description>
      <NextStepsYcbmPitch ycbmLink={ycbmLink} advisor={advisor} state={state} />

      <NextStepsReviewItems advisor={advisor} questionnaireType={questionnaireType} />
    </NodeContainer>
  );
};
