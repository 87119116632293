import { FINAL_STEP, PROPERTY_INFO, PURCHASE_INFO } from '../preapproval/node-map';
import { AcceptedOfferAccountNode, acceptedOfferAccountNodeId } from './account-node';
import {
  AcceptedOfferApproximateCreditScoreNode,
  acceptedOfferApproximateCreditScoreNodeId
} from './approximate-credit-score-node';
import {
  AcceptedOfferCloseDateOnlyNode,
  acceptedOfferCloseDateOnlyNodeId
} from './close-date-only-node';
import { AcceptedOfferCoborrowerNode, acceptedOfferCoborrowerNodeId } from './coborrower-node';
import {
  AcceptedOfferConfirmContactInfoNode,
  acceptedOfferConfirmContactInfoNodeId
} from './confirm-contact-info-node';
import {
  AcceptedOfferConsultationNextStepsNode,
  acceptedOfferConsultationNextStepsNodeId
} from './consultation-next-steps-node';
import { AcceptedOfferDownPaymentNode, acceptedOfferDownPaymentNodeId } from './down-payment-node';
import {
  AcceptedOfferEmailAndPhoneNode,
  acceptedOfferEmailAndPhoneNodeId
} from './email-and-phone-node';
import {
  AcceptedOfferInfoNotVerifiedStopNode,
  acceptedOfferInfoNotVerifiedStopNodeId
} from './info-not-verified-stop-node';
import { AcceptedOfferLenderOfferNode, acceptedOfferLenderOfferNodeId } from './lender-offer-node';
import { AcceptedOfferLowLoanStopNode, stopAcceptedOfferLowLoanNodeId } from './low-loan-stop-node';
import {
  AcceptedOfferNewConstructionNode,
  acceptedOfferNewConstructionNodeId
} from './new-construction-node';
import {
  AcceptedOfferNewConstructionStopNode,
  acceptedOfferNewConstructionStopNodeId
} from './new-construction-stop-node';
import {
  AcceptedOfferNewPropertyAddressNode,
  acceptedOfferNewPropertyAddressNodeId
} from './new-property-address-node';
import { AcceptedOfferNextStepsNode, acceptedOfferNextStepsNodeId } from './next-steps-node';
import {
  AcceptedOfferNoIncomeStopNode,
  stopAcceptedOfferNoIncomeNodeId
} from './no-income-stop-node';
import {
  AcceptedOfferOccupancyTypeNode,
  acceptedOfferOccupancyTypeNodeId
} from './occupancy-type-node';
import {
  AcceptedOfferPrimaryBorrowerFthbNode,
  acceptedOfferPrimaryBorrowerFthbNodeId
} from './primary-borrower-fthb-node';
import {
  AcceptedOfferIncomeAmountNode,
  acceptedOfferIncomeAmountNodeId
} from './primary-borrower-income-amount-node';
import {
  AcceptedOfferIncomeTypeNode,
  acceptedOfferIncomeTypeNodeId
} from './primary-borrower-income-type-node';
import {
  AcceptedOfferPropertyTypeNode,
  acceptedOfferPropertyTypeNodeId
} from './property-type-node';
import {
  AcceptedOfferPurchasePriceNode,
  acceptedOfferPurchasePriceNodeId
} from './purchase-price-node';
import { AcceptedOfferStateInfoNode, acceptedOfferStateInfoNodeId } from './state-info-node';
import { AcceptedOfferStateNode, acceptedOfferStateNodeId } from './state-node';
import {
  AcceptedOfferStepUpConfirmCodeNode,
  acceptedOfferStepUpConfirmCodeNodeId
} from './step-up-confirm-code-node';
import {
  AcceptedOfferStepUpConfirmPhoneNode,
  acceptedOfferStepUpConfirmPhoneNodeId
} from './step-up-confirm-phone-node';
import {
  acceptedOfferVeteransBenefitsConsentId,
  AcceptedOfferVeteransBenefitsConsentNode
} from './veterans-benefits-consent-node';

/**
 * Mapping of all nodes to elements.
 */
export const acceptedOfferNodeMap: Record<string, () => JSX.Element> = {
  // -- Property Info
  [acceptedOfferPropertyTypeNodeId]: AcceptedOfferPropertyTypeNode,
  [acceptedOfferOccupancyTypeNodeId]: AcceptedOfferOccupancyTypeNode,
  [acceptedOfferStateNodeId]: AcceptedOfferStateNode,
  [acceptedOfferStateInfoNodeId]: AcceptedOfferStateInfoNode,
  [acceptedOfferNewPropertyAddressNodeId]: AcceptedOfferNewPropertyAddressNode,
  [acceptedOfferCloseDateOnlyNodeId]: AcceptedOfferCloseDateOnlyNode,
  [acceptedOfferNewConstructionNodeId]: AcceptedOfferNewConstructionNode,
  [acceptedOfferLenderOfferNodeId]: AcceptedOfferLenderOfferNode,

  // -- Purchase Info
  [acceptedOfferPrimaryBorrowerFthbNodeId]: AcceptedOfferPrimaryBorrowerFthbNode,
  [acceptedOfferCoborrowerNodeId]: AcceptedOfferCoborrowerNode,
  [acceptedOfferVeteransBenefitsConsentId]: AcceptedOfferVeteransBenefitsConsentNode,
  [acceptedOfferPurchasePriceNodeId]: AcceptedOfferPurchasePriceNode,
  [acceptedOfferDownPaymentNodeId]: AcceptedOfferDownPaymentNode,
  [acceptedOfferIncomeAmountNodeId]: AcceptedOfferIncomeAmountNode,
  [acceptedOfferIncomeTypeNodeId]: AcceptedOfferIncomeTypeNode,
  [acceptedOfferApproximateCreditScoreNodeId]: AcceptedOfferApproximateCreditScoreNode,

  // -- Final Step
  [acceptedOfferEmailAndPhoneNodeId]: AcceptedOfferEmailAndPhoneNode,
  [acceptedOfferConfirmContactInfoNodeId]: AcceptedOfferConfirmContactInfoNode,
  [acceptedOfferInfoNotVerifiedStopNodeId]: AcceptedOfferInfoNotVerifiedStopNode,
  [acceptedOfferStepUpConfirmPhoneNodeId]: AcceptedOfferStepUpConfirmPhoneNode,
  [acceptedOfferStepUpConfirmCodeNodeId]: AcceptedOfferStepUpConfirmCodeNode,

  // -- Stop
  [stopAcceptedOfferNoIncomeNodeId]: AcceptedOfferNoIncomeStopNode,
  [stopAcceptedOfferLowLoanNodeId]: AcceptedOfferLowLoanStopNode,
  [acceptedOfferNewConstructionStopNodeId]: AcceptedOfferNewConstructionStopNode,

  // -- End
  [acceptedOfferAccountNodeId]: AcceptedOfferAccountNode,
  [acceptedOfferConsultationNextStepsNodeId]: AcceptedOfferConsultationNextStepsNode,
  [acceptedOfferNextStepsNodeId]: AcceptedOfferNextStepsNode
};

export const acceptedOfferSectionTitles = [PROPERTY_INFO, PURCHASE_INFO, FINAL_STEP];

/**
 * Mapping of all nodes to section titles. The section title appears on the
 *   node itself and the sidebar.
 */
export const acceptedOfferNodeSectionMapping: Record<string, string> = {
  // -- Property Info
  [acceptedOfferPropertyTypeNodeId]: PROPERTY_INFO,
  [acceptedOfferOccupancyTypeNodeId]: PROPERTY_INFO,
  [acceptedOfferStateNodeId]: PROPERTY_INFO,
  [acceptedOfferStateInfoNodeId]: PROPERTY_INFO,
  [acceptedOfferNewPropertyAddressNodeId]: PROPERTY_INFO,
  [acceptedOfferCloseDateOnlyNodeId]: PROPERTY_INFO,
  [acceptedOfferNewConstructionNodeId]: PROPERTY_INFO,
  [acceptedOfferNewConstructionStopNodeId]: PROPERTY_INFO,
  [acceptedOfferLenderOfferNodeId]: PROPERTY_INFO,

  // -- Purchase Info
  [acceptedOfferPrimaryBorrowerFthbNodeId]: PURCHASE_INFO,
  [acceptedOfferCoborrowerNodeId]: PURCHASE_INFO,
  [acceptedOfferVeteransBenefitsConsentId]: PURCHASE_INFO,
  [acceptedOfferPurchasePriceNodeId]: PURCHASE_INFO,
  [acceptedOfferDownPaymentNodeId]: PURCHASE_INFO,
  [stopAcceptedOfferLowLoanNodeId]: PURCHASE_INFO,
  [acceptedOfferIncomeAmountNodeId]: PURCHASE_INFO,
  [acceptedOfferIncomeTypeNodeId]: PURCHASE_INFO,
  [stopAcceptedOfferNoIncomeNodeId]: PURCHASE_INFO,
  [acceptedOfferApproximateCreditScoreNodeId]: PURCHASE_INFO,

  // -- Final Step
  [acceptedOfferEmailAndPhoneNodeId]: FINAL_STEP,
  [acceptedOfferConfirmContactInfoNodeId]: FINAL_STEP,
  [acceptedOfferInfoNotVerifiedStopNodeId]: FINAL_STEP,
  [acceptedOfferStepUpConfirmPhoneNodeId]: FINAL_STEP,
  [acceptedOfferStepUpConfirmCodeNodeId]: FINAL_STEP,
  [acceptedOfferAccountNodeId]: FINAL_STEP
};
