import { createAction } from '@reduxjs/toolkit';
import { ErrorPayload } from '../../modules/segment/types';

/**
 * Dispatch a request to the save the user's questionnaire
 *   data.
 *
 * Questionnaire payload will be pulled from redux store.
 */
export const saveQuestionnaireRequestAction = createAction('qustionnaire/saveRequest');

export type SaveQuestionnaireRequestAction = ReturnType<typeof saveQuestionnaireRequestAction>;

/**
 * Indicate that we've successfully saved the questionnaire.
 */
export const saveQuestionnaireSuccessAction = createAction<{
  path: string[];
  dataModels?: Record<string, Record<string, unknown>>;
  percentComplete?: number;
  isComplete?: boolean;
  advisor?: string;
  pitch?: boolean;
}>('questionnaire/saveSuccess');

export type SaveQuestionnaireSuccessAction = ReturnType<typeof saveQuestionnaireSuccessAction>;

export const saveQuestionnaireParamsRequestAction = createAction<{
  advisor?: string;
  pitch?: boolean;
  questionnaireType?: string;
  jornayaId?: string;
}>('questionnaire/saveQuestionnaireParamsRequestAction');

export type SaveQuestionnaireParamsRequestAction = ReturnType<
  typeof saveQuestionnaireParamsRequestAction
>;

export const saveQuestionnaireParamsSuccessAction = createAction<{
  path: string[];
  dataModels?: Record<string, Record<string, unknown>>;
  percentComplete?: number;
  isComplete?: boolean;
  advisor?: string;
  pitch?: boolean;
  questionnaireType?: string;
}>('questionnaire/saveQuestionnaireParamsSuccess');

export type SaveQuestionnaireParamsSuccessAction = ReturnType<
  typeof saveQuestionnaireParamsSuccessAction
>;

export const saveQuestionnaireParamsFailureAction = createAction<ErrorPayload>(
  'questionnaire/saveParamsFailure'
);
export type SaveQuestionnaireParamsFailureAction = ReturnType<
  typeof saveQuestionnaireParamsFailureAction
>;

export const saveQuestionnaireFailureAction = createAction<ErrorPayload>(
  'questionnaire/saveFailure'
);
export type SaveQuestionnaireFailureAction = ReturnType<typeof saveQuestionnaireFailureAction>;
