import { ErrorBoundary } from '@sentry/react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NodeCompleteContext } from '../../hooks/node-complete-context';
import { NodeConfigurationsContext } from '../../hooks/use-node-configurations';
import { useQuestionnaireInitializer } from '../../hooks/use-questionnaire-initializer';
import { RouteParams } from '../../modules/path-helpers';
import { serializeError } from '../../modules/segment/types';
import { getSplitFactory } from '../../modules/split';
import { dataModelsSelector } from '../../store/data-models/selectors';
import {
  hasIncompleteQuestionnaireSelector,
  oldQuestionnaireTypeSelector
} from '../../store/display/selectors';
import { questionnairePathSelector } from '../../store/path/selectors';
import { fatalQuestionnaireFailureAction } from '../../store/questionnaire/fatal-actions';
import { nodeCompleteSelector } from '../../store/questionnaire/selectors';
import { nodeConfigurations } from '../nodes/node-configurations';
import { Loading } from '../shared/loading';
import { NavBar } from '../shared/nav-bar/nav-bar';
import { ErrorPage } from './error';
import { IncompleteQuestionnairePage } from './incomplete-questionnaire';

/**
 * Main page for all questionnaires
 */
export const QuestionnairePage = () => {
  // Use hook to initialize the questionnaire states
  useQuestionnaireInitializer();

  const dispatch = useDispatch();

  // Get your node id from the route.
  const { nodeId, questionnaireType } = useParams<RouteParams>();

  const dataModels = useSelector(dataModelsSelector);
  const path = useSelector(questionnairePathSelector);
  const nodeReady = path.includes(nodeId) && !!dataModels[nodeId];
  const isComplete = useSelector(nodeCompleteSelector);
  const hasIncompleteQuestionnaire = useSelector(hasIncompleteQuestionnaireSelector);
  const oldQuestionnaireType = useSelector(oldQuestionnaireTypeSelector);

  // Determine what to render based on route parameters.
  const page = useMemo(() => {
    // Create another else if statement for incomplete questionnaires
    if (hasIncompleteQuestionnaire) {
      return (
        <React.Fragment>
          <NavBar />
          <IncompleteQuestionnairePage oldQuestionnaireType={oldQuestionnaireType} />
        </React.Fragment>
      );
    }
    if (nodeReady) {
      const config = nodeConfigurations[questionnaireType];
      const NodeElement = config?.nodeMap[nodeId];

      return (
        <SplitFactory factory={getSplitFactory()}>
          <React.Fragment>
            <NodeConfigurationsContext.Provider value={config}>
              <NavBar />
              <NodeCompleteContext.Provider value={isComplete}>
                <NodeElement />
              </NodeCompleteContext.Provider>
            </NodeConfigurationsContext.Provider>
          </React.Fragment>
        </SplitFactory>
      );
    }

    return <Loading useLoadingSpinnerStyle={true} />;
  }, [
    nodeReady,
    nodeId,
    questionnaireType,
    isComplete,
    hasIncompleteQuestionnaire,
    oldQuestionnaireType
  ]);

  // Render the elements, wrapped in global providers
  return (
    <ErrorBoundary
      onError={(err: Error) => {
        // Error handler for uncaught exceptions
        dispatch(fatalQuestionnaireFailureAction({ error: serializeError(err) }));
      }}
      fallback={
        <ErrorPage
          backButtonText={'Back to Questionnaire'}
          backButtonUrl={`${process.env.SITE_ROOT}/${questionnaireType}`}
        />
      }
      showDialog={false}
    >
      {page}
    </ErrorBoundary>
  );
};
