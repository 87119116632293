import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { OccupancyTypeDataModel } from '../../common/occupancy-type-node/data-model';
import { OccupancyTypeNodeImpl } from '../../common/occupancy-type-node/element';
import { occupancyTypePredicate } from '../../common/occupancy-type-node/predicate';

export const refiOccupancyTypeNodeId = 'occupancy-type';
export const RefiOccupancyTypeNode = () => {
  const props = useNodeProperties<OccupancyTypeDataModel>(
    refiOccupancyTypeNodeId,
    occupancyTypePredicate
  );
  return (
    <Loader>
      <OccupancyTypeNodeImpl subheaderText="How do you use this property?" {...props} />
    </Loader>
  );
};
