import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  createQuestionnaire,
  CreateQuestionnaireQueryResult,
  fetchQuestionnaire,
  QuestionnaireQueryResult,
  questionnaireTypeMap,
  saveQuestionnaire,
  saveQuestionnaireParams
} from '../../modules/api-handlers';
import { piiFilter } from '../../modules/filters';
import { getNodeIdFromPathname } from '../../modules/path-helpers';
import { QuestionnaireModel } from '../../modules/questionnaire/questionnaire-model';
import { serializeError } from '../../modules/segment/types';
import { convertPitchToString } from '../../modules/utils';
import { dataModelSubmitSelector } from '../data-models/selectors';
import { updateDataModelsAction } from '../data-models/slice';
import { showErrorAction } from '../display/slice';
import {
  enteredFunnel,
  identifyUser,
  nodeIncomplete,
  SubmittedNode,
  submittedNode
} from '../events/actions';
import { questionnairePathSelector } from '../path/selectors';
import { routerPathnameSelector } from '../path/selectors';
import { setPathAction } from '../path/slice';
import { questionnaireTypeSelector } from '../questionnaire/selectors';
import {
  createQuestionnaireFailureAction,
  createQuestionnaireRequestAction,
  CreateQuestionnaireRequestAction,
  CreateQuestionnaireSuccessAction,
  createQuestionnaireSuccessAction
} from './create-actions';
import {
  fetchQuestionnaireFailureAction,
  fetchQuestionnaireRequestAction,
  FetchQuestionnaireRequestAction,
  FetchQuestionnaireSuccessAction,
  fetchQuestionnaireSuccessAction
} from './fetch-actions';
import {
  saveQuestionnaireFailureAction,
  saveQuestionnaireParamsFailureAction,
  saveQuestionnaireParamsRequestAction,
  SaveQuestionnaireParamsRequestAction,
  SaveQuestionnaireParamsSuccessAction,
  saveQuestionnaireParamsSuccessAction,
  SaveQuestionnaireRequestAction,
  saveQuestionnaireRequestAction,
  saveQuestionnaireSuccessAction,
  SaveQuestionnaireSuccessAction
} from './save-actions';
import { questionnaireIdSelector, questionnaireStateSelector, userIdSelector } from './selectors';
import { loadingAction, updateQuestionnaireAction } from './slice';
import {
  advisorIdKey,
  pitchKey,
  questionnaireIdKey,
  QuestionnaireState,
  questionnaireTypeKey,
  userIdKey
} from './state';

// Handles a redirect to the legacy questionnaire.
export function* handleCompleteQuestionnaireSuccess() {
  const userId: string | undefined = yield select(userIdSelector);
  const questionnaireId: string | undefined = yield select(questionnaireIdSelector);
  const questionnaireType: QuestionnaireModel['questionnaireType'] =
    yield select(questionnaireTypeSelector);
  const legacyQuestionnaireType = questionnaireTypeMap[questionnaireType!];
  const endpoint = `${
    process.env.LEGACY_QUESTIONNAIRE_URL || 'https://ownup-questionnaire-staging.netlify.app/'
  }${legacyQuestionnaireType}/#userId=${encodeURIComponent(
    userId!
  )}&questionnaireId=${encodeURIComponent(questionnaireId!)}`;
  window.location.href = endpoint;
}

/**
 * Calls {@see createQuestionnaire} API endpoint and reports success/failures.
 */
export function* handleCreateQuestionnaireRequestAction({
  payload
}: CreateQuestionnaireRequestAction) {
  try {
    yield put(loadingAction(true));
    const result: CreateQuestionnaireQueryResult = yield call(createQuestionnaire, payload);
    yield put(createQuestionnaireSuccessAction(result));
    yield put(enteredFunnel(payload.questionnaireType!));
  } catch (err) {
    const error = err instanceof Error ? err : new Error(JSON.stringify(err));

    yield put(
      createQuestionnaireFailureAction({
        error: serializeError(error, {
          questionnaireType: payload.questionnaireType
        })
      })
    );

    yield put(
      showErrorAction({
        message: `Sorry, but we can't find the page you're looking for.`,
        backButtonText: 'Back to OwnUp.com',
        backButtonUrl: `https://www.ownup.com/`
      })
    );
  }
}

/**
 * Calls {@see fetchQuestionnaire} API endpoint and reports success/failures.
 */
export function* handleFetchQuestionnaireRequestAction({
  payload: { nodeId }
}: FetchQuestionnaireRequestAction) {
  yield put(loadingAction(true));
  const userId: string = yield select(userIdSelector);
  const questionnaireId: string = yield select(questionnaireIdSelector);
  const questionnaireType: string = yield select(questionnaireTypeSelector);
  // Ensure that the latest user credentials are being reported to segment.
  //   If the user has navigated to the legacy questionnaire or has lost their
  //   cookies (but not their localStorage) their segment link may have been
  //   disconnected. This will ensure it is still established.
  yield put(identifyUser());
  try {
    const result: QuestionnaireQueryResult = yield call(
      fetchQuestionnaire,
      userId,
      questionnaireId,
      questionnaireType,
      nodeId
    );
    yield put(fetchQuestionnaireSuccessAction(result));
  } catch (err) {
    const error = err instanceof Error ? err : new Error(JSON.stringify(err));
    yield put(
      fetchQuestionnaireFailureAction({
        error: serializeError(error, {
          questionnaireId
        })
      })
    );

    yield put(
      showErrorAction({
        backButtonText: questionnaireType ? 'Back to Questionnaire' : 'Back to OwnUp.com',
        backButtonUrl: questionnaireType
          ? `${process.env.SITE_ROOT}/${questionnaireType}`
          : 'https://www.ownup.com/'
      })
    );
  }
}

/**
 * Calls {@see saveQuestionnaire} API endpoint and reports success/failures.
 */
export function* handleSaveQuestionnaireRequestAction(_: SaveQuestionnaireRequestAction) {
  yield put<PayloadAction<boolean>>(loadingAction(true));
  const pathname: string = yield select(routerPathnameSelector);
  const currentNodeId: string = yield getNodeIdFromPathname(pathname);
  // Track submitted event
  yield put<SubmittedNode>(submittedNode({ nodeId: currentNodeId }));
  const { questionnaireId, userId, questionnaireType }: QuestionnaireState = yield select(
    questionnaireStateSelector
  );
  const model: object = yield select(dataModelSubmitSelector(currentNodeId));
  const payload = { questionnaireId, userId, questionnaireType, ...model };
  const path: string[] = yield select(questionnairePathSelector);
  try {
    const result: QuestionnaireQueryResult = yield call(
      saveQuestionnaire,
      payload,
      currentNodeId,
      path
    );
    yield put<SaveQuestionnaireSuccessAction>(saveQuestionnaireSuccessAction(result));
  } catch (err) {
    const error = err instanceof Error ? err : new Error(JSON.stringify(err));
    yield put(
      saveQuestionnaireFailureAction({
        error: serializeError(error, {
          pathname,
          currentNodeId,
          payload: piiFilter(payload),
          path
        })
      })
    );

    yield put(
      showErrorAction({
        backButtonText: questionnaireType ? 'Back to Questionnaire' : 'Back to OwnUp.com',
        backButtonUrl: questionnaireType
          ? `${process.env.SITE_ROOT}/${questionnaireType}`
          : 'https://www.ownup.com/'
      })
    );
  }
}

export function* handleSaveQuestionnaireParamsRequestAction({
  payload
}: SaveQuestionnaireParamsRequestAction) {
  const { advisor, pitch, questionnaireType } = payload;
  const { questionnaireId, userId } = yield select(questionnaireStateSelector);
  const urlParams = {
    pitch,
    ...(advisor && { advisor })
  };

  const savePayload = {
    questionnaireId,
    userId,
    questionnaireType,
    ...urlParams
  };
  try {
    const result: QuestionnaireQueryResult = yield call(saveQuestionnaireParams, savePayload);
    yield put(saveQuestionnaireParamsSuccessAction({ ...result, ...urlParams }));

    if (advisor) {
      sessionStorage.setItem(advisorIdKey, advisor);
    }

    if (pitch === undefined) {
      sessionStorage.removeItem(pitchKey);
    } else {
      sessionStorage.setItem(pitchKey, convertPitchToString(pitch));
    }
  } catch (err) {
    const error = err instanceof Error ? err : new Error(JSON.stringify(err));
    yield put(
      saveQuestionnaireParamsFailureAction({
        error: serializeError(error, {
          payload: piiFilter(payload)
        })
      })
    );
  }
}

/**
 * Saves ids to session storage
 */
export function* handleCreateQuestionnaireSuccessAction(action: CreateQuestionnaireSuccessAction) {
  const {
    payload: {
      model: { userId, questionnaireId, questionnaireType, advisor, pitch }
    }
  } = action;
  if (userId && questionnaireId) {
    sessionStorage.setItem(userIdKey, userId);
    sessionStorage.setItem(questionnaireIdKey, questionnaireId);
    sessionStorage.setItem(questionnaireTypeKey, questionnaireType!);
    sessionStorage.setItem(advisorIdKey, advisor!);
    sessionStorage.setItem(pitchKey, convertPitchToString(pitch));
    yield put(updateQuestionnaireAction({ userId, questionnaireId }));
    // The user has been credentialed to we can now identify in our analytics
    yield put(identifyUser());
  }
}

/**
 * Loads up your new model, data template, and path data.
 */
export function* handleSuccessfulQueryAction({
  payload: { path, percentComplete, dataModels, isComplete = true }
}:
  | CreateQuestionnaireSuccessAction
  | FetchQuestionnaireSuccessAction
  | SaveQuestionnaireSuccessAction
  | SaveQuestionnaireParamsSuccessAction) {
  yield put(updateQuestionnaireAction({ percentComplete, isComplete }));
  if (dataModels) {
    yield put(updateDataModelsAction(dataModels));
  }
  yield put(setPathAction(path));
  if (isComplete === false) {
    yield put(nodeIncomplete({ nodeId: path[path.length - 1] }));
  }
  yield put(loadingAction(false));
}

export function* questionnaireSaga() {
  yield all([
    // Create Actions
    takeEvery(createQuestionnaireRequestAction.type, handleCreateQuestionnaireRequestAction),
    takeEvery(createQuestionnaireSuccessAction.type, handleCreateQuestionnaireSuccessAction),
    takeEvery(createQuestionnaireSuccessAction.type, handleSuccessfulQueryAction),
    // Fetch Actions
    takeEvery(fetchQuestionnaireRequestAction.type, handleFetchQuestionnaireRequestAction),
    takeEvery(fetchQuestionnaireSuccessAction.type, handleSuccessfulQueryAction),
    // Save Actions
    takeEvery(saveQuestionnaireRequestAction.type, handleSaveQuestionnaireRequestAction),
    takeEvery(saveQuestionnaireSuccessAction.type, handleSuccessfulQueryAction),
    // Param Actions
    takeEvery(
      saveQuestionnaireParamsRequestAction.type,
      handleSaveQuestionnaireParamsRequestAction
    ),
    takeEvery(saveQuestionnaireParamsSuccessAction.type, handleSuccessfulQueryAction)
  ]);
}
