import { useEffect, useState } from 'react';
import { PitchType } from '../../../../modules/questionnaire/pitch-types';

export interface BaseNextStepsReadFields {
  advisor?: string;
  name?: string;
  email?: string;
  questionnaireType?: string;
  pitchType?: PitchType;
}

/**
 * The advisor object has new fields that we find useful
 *   for filtering.
 */
export interface Advisor {
  /* eslint-disable @typescript-eslint/naming-convention */
  /**
   * Advisor name info
   */
  name: {
    /**
     * Combined first and last name
     */
    full: string;
    /**
     * First name
     */
    first: string;
    /**
     * Last name
     */
    last: string;
  };
  /**
   * Absolute path to small photo
   */
  photo: string;
  /**
   * Absolute path to large photo
   */
  big_photo: string;
  /**
   * Absolute path to mini photo
   */
  mini_photo: string;
  /**
   * Advisor's phone number
   */
  phone: string;
  /**
   * Advisor's NMLS number (typically Own Up's)
   */
  nmls: string;
  /**
   * Advisor's work email
   */
  email: string;
  /**
   * List of states that the advisor is licensed in
   */
  licensed: string[];
  /**
   * Whether or not this advisor was deleted
   */
  deleted: boolean;
  /* eslint-enable @typescript-eslint/naming-convention */
}

export type Advisors = Record<string, Advisor>;

let cachedAdvisors: Advisors | undefined;

/**
 * Fetch all advisor data from advisor-static.
 *
 * @param fetchFunction - Overridable fetch function. Meant for testing only
 * @returns Mapping of advisor data
 */
export const getAdvisors = async () => {
  if (!cachedAdvisors) {
    try {
      const result = await fetch('https://advisors-static.ownup.com/index.json');
      if (result.ok) {
        cachedAdvisors = await result.json();
      }
    } catch {
      // ... no-op
    }
  }
  return cachedAdvisors;
};

/**
 * Hook to return advisor data from advisors-static.
 */
export const useAdvisors = () => {
  const [advisors, setAdvisors] = useState<Advisors | undefined>(cachedAdvisors);
  useEffect(() => {
    if (advisors === undefined) {
      void (async () => {
        const foundAdvisors = await getAdvisors();
        setAdvisors(foundAdvisors);
      })();
    }
  }, [advisors, setAdvisors]);
  return advisors;
};

/**
 * Hook to return advisor data for a particular advisor.
 */
export const useAdvisor = (advisorId: string) => useAdvisors()?.[advisorId];
