import { encode } from 'querystring';
import { XRAY_TRACE_ID } from '../../components/sentry';
import { getSplitClientInstance } from '../split';
import { getLoginToken, TOKEN_ID } from '.';

export interface RedirectParameters {
  error?: string;
  statusText: string;
  target: 'sign-in';
}
/**
 * Handles an unauthorized error
 *
 * @param error - Error payload to include at my.ownup.com
 * @param statusText - Message to return to this frontend while the login page loads
 * @param target - Target my.ownup.com/auth/${target} path to redirect to
 * @returns Response
 */
const on401 = async ({ error, statusText, target }: RedirectParameters) => {
  const splitClient = getSplitClientInstance();
  const questionnaireType = window.location.pathname
    .replace(process.env.SITE_ROOT || '', '')
    .substring(1)
    .split('/')[0];
  // Delete the token
  localStorage.removeItem(TOKEN_ID);
  const queryParams = encode({
    redirectPath: window.location.href,
    ...(error ? { error } : {})
  });
  const hashParams = encode({
    questionnaireType
  });

  // Destroy SDK client before redirect.
  if (splitClient) {
    await splitClient.destroy();
  }
  // Redirect the user
  window.location.href = `${process.env.MY_OWNUP_URL}/auth/${target}?${queryParams}#${hashParams}`;
  // Give the login page 5 seconds to load before treating the query like a failure
  await new Promise((resolve) =>
    setTimeout(() => resolve(true), parseInt(process.env.SIGN_IN_REDIRECT_TIMEOUT || '') || 5000)
  );
  return new Response(undefined, {
    status: 401,
    statusText
  });
};

export async function loggedInFetch(
  info: RequestInfo,
  { headers, ...init }: RequestInit = { headers: {} },
  redirectParameters: RedirectParameters
): Promise<Response> {
  const authorizedHeaders = new Headers(headers);
  if (process.env.SENTRY_DSN) {
    authorizedHeaders.set('X-Amzn-Trace-Id', `Root=${XRAY_TRACE_ID}`);
  }

  let token = '';
  try {
    token = await getLoginToken();
  } catch (err) {
    console.log('Error getting login token: ', err);
  }

  if (token) {
    authorizedHeaders.set('Authorization', `Bearer ${token}`);
    authorizedHeaders.append('Accept', 'application/json');
    if (!authorizedHeaders.get('Content-Type')) {
      authorizedHeaders.append('Content-Type', 'application/json');
    }
  }

  const result = await fetch(info, {
    ...init,
    headers: authorizedHeaders
  });

  if (result.status !== 401 && result.status !== 403) {
    return result;
  }

  return on401(redirectParameters);
}
