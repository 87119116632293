import {
  ownUpBodyMediumMixin,
  OwnUpSmallSubheadlineRegular,
  OwnUpSubheadlineProps
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

/**
 * Extension of {@see OwnUpSubheadlineProps}
 */
export type HintHeaderProps = Omit<OwnUpSubheadlineProps, 'variant'>;

const HintHeaderImpl = (props: HintHeaderProps) => (
  <OwnUpSmallSubheadlineRegular {...props} variant="h3" />
);

/**
 * The "HintHeader" is the section header copy for a hint modal.
 *
 * Figma: https://www.figma.com/file/xOJwjvBP1RFcnViMvWLKsX/Koala-%2F-System-Skin?node-id=8%3A3850
 */
export const HintHeader = styled(HintHeaderImpl)`
  /* Wrapping this in a new scope helps prevent css collisions */
  & {
    /* This element is a header but it has the style of bodyMedium */
    ${ownUpBodyMediumMixin}
    font-weight: 700;
    color: #000000;
  }
`;
