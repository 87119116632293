import { currency } from '@rategravity/frontend/modules/numbers';

export const getMinDownPaymentLabelMap = ({
  minReqDownPaymentPercentage,
  purchasePrice
}: {
  minReqDownPaymentPercentage: number;
  purchasePrice?: number;
}) => {
  let minDownPaymentPercentMap = [
    {
      minValue: Math.min(minReqDownPaymentPercentage, 0.05),
      maxValue: 0.05,
      percentLabel: `${+(minReqDownPaymentPercentage * 100).toFixed(1)}-5%`
    },
    { minValue: 0.06, maxValue: 0.09, percentLabel: '6-9%' },
    { minValue: 0.1, maxValue: 0.14, percentLabel: '10-14%' },
    { minValue: 0.15, maxValue: 0.19, percentLabel: '15-19%' },
    { minValue: 0.2, maxValue: 0.24, percentLabel: '20-24%' },
    { minValue: 0.25, maxValue: undefined, percentLabel: '25%+' }
  ];
  minDownPaymentPercentMap = minDownPaymentPercentMap.filter(
    ({ minValue }) => minValue >= minReqDownPaymentPercentage
  );
  return minDownPaymentPercentMap.map(({ minValue, maxValue, percentLabel }) => {
    const minPrice = purchasePrice ? currency(purchasePrice * minValue, 0) : undefined;
    let maxPrice = undefined;
    if (maxValue) {
      maxPrice = purchasePrice ? currency(purchasePrice * maxValue, 0) : undefined;
    }
    return {
      value: maxValue ?? minValue,
      label: `${percentLabel} ${
        minValue === maxValue ? '' : `(${minPrice}${maxPrice ? ` - ${maxPrice}` : ''})`
      }`
    };
  });
};

/** [[min, max], ...] */
const downPaymentRanges = [
  [0, 5],
  [6, 9],
  [10, 14],
  [15, 19],
  [20, 24],
  [25, 100]
];

/**
 * Returns the minimum down payment percent for a given minimum down payment percent
 */
export const getMinDownPaymentPercent = (percent: number, minReqDownPaymentPercentage: number) => {
  for (let i = 0; i < downPaymentRanges.length; i++) {
    const max = downPaymentRanges[i][1];
    if (Math.floor(percent) <= max) {
      if (i === 0) {
        return Math.round(minReqDownPaymentPercentage * 1000) / 10;
      }
      return downPaymentRanges[i][0];
    }
  }
  return 0;
};

/**
 * Returns the down payment dollar amount given the purchase price and down payment percent
 */
export const getDownPayment = (purchasePrice: number, downPaymentPercent: number) =>
  Math.round((downPaymentPercent * purchasePrice) / 100);
