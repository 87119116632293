import React from 'react';
import { HintButton, HintButtonProps } from '../../../shared/hint/hint-button';
import { HintBody } from '../../../shared/hint/typography/hint-body';
import { HintHeader } from '../../../shared/hint/typography/hint-header';

export const OccupancyTypeHint = (props: Omit<HintButtonProps, 'children' | 'title'>) => (
  <HintButton {...props} title="Occupancy types">
    <HintHeader>Primary residence</HintHeader>
    <HintBody>
      A property that the borrower or co-borrower occupies as his or her principal residence.
    </HintBody>
    <br />
    <HintHeader>Secondary home</HintHeader>
    <HintBody>
      <ul style={{ margin: '0' }}>
        <li>Must be occupied by the borrower for some portion of the year</li>
        <li>Is restricted to one-unit dwellings</li>
        <li>Must be suitable for year-round occupancy</li>
        <li>Borrower must have exclusive control over the property</li>
        <li>Must not be a rental property or timeshare arrangement</li>
      </ul>
    </HintBody>
    <br />
    <HintHeader>Investment property</HintHeader>
    <HintBody>A property owned but not occupied by the borrower.</HintBody>
  </HintButton>
);
