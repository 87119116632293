import { QuestionnaireModel } from '../../modules/questionnaire/questionnaire-model';

export type QuestionnaireState = QuestionnaireModel;

export const questionnaireIdKey = 'OU_QUESTIONNAIRE_ID';
export const questionnaireTypeKey = 'OU_QUESTIONNAIRE_TYPE';
export const userIdKey = 'OU_USER_ID';
export const advisorIdKey = 'OU_ADVISOR_ID';
export const pitchKey = 'OU_PITCH';

export const defaultQuestionnaireState: QuestionnaireState = {
  questionnaireId: sessionStorage.getItem(questionnaireIdKey) || undefined,
  userId: sessionStorage.getItem(userIdKey) || undefined,
  questionnaireType: sessionStorage.getItem(questionnaireTypeKey) || undefined,
  advisor: sessionStorage.getItem(advisorIdKey) || undefined,
  pitch: sessionStorage.getItem(pitchKey) || undefined,
  loading: false
};
