import * as emailValidator from '@rategravity/core-lib/validate-email';
import { QuestionnaireModel } from '@rategravity/onboarding-stack-types';
import { validateName } from './name-helpers';

interface EmailModels {
  write: {
    email?: QuestionnaireModel['primaryBorrower']['email'];
  };
}

export const emailPredicate = (dataModel: EmailModels) => {
  if (dataModel.write.email === undefined) {
    return false;
  }
  return emailValidator.validate((dataModel.write.email as string).trim());
};

export const dollarPredicate = (amount: number | undefined, allowZeroDollars: boolean = false) => {
  if (amount === undefined) {
    return false;
  }
  return allowZeroDollars ? amount >= 0 : amount > 0;
};

export const radioButtonPredicate = <T>(value: T) => {
  return value != undefined;
};

export const downPaymentInputPredicate = (
  numericAmount?: number,
  percentAmount?: number,
  minDownPayment?: number
) => {
  if (numericAmount === undefined || percentAmount === undefined) {
    return false;
  } else if (minDownPayment) {
    return minDownPayment <= numericAmount;
  }
  return numericAmount >= 0 && percentAmount >= 0;
};

export const phonePredicate = (phoneNumber?: string): boolean => {
  if (phoneNumber === undefined) {
    return false;
  }
  // Only allow 10 digits in expected format
  const phoneValidator = new RegExp(
    /^\s*(?:\+?(\d{1}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
  );
  // Don't allow repeating numbers for all 10 numbers (111-111-1111 / 222-222-2222, etc)
  // Removes all non-digits before the test to make sure an advisor entered phone number doesn't error
  // (e.g. +16177777777 or (617) 777-7777)
  const numberOfFirstDigit = (
    phoneNumber.match(new RegExp(phoneNumber.replace(/\D/g, '').substring(0, 1), 'g')) || []
  ).length;
  return (
    phoneValidator.test(phoneNumber.replace(/ /g, '')) &&
    numberOfFirstDigit !== 10 &&
    numberOfFirstDigit !== 11 &&
    // Don't allow leading 0
    phoneNumber.substring(0, 1) !== '0' &&
    // Don't allow totally sequential numbers
    phoneNumber !== '123-456-7890' &&
    phoneNumber !== '987-654-3210'
  );
};

export const namePredicate = (name: string | undefined) => !!name && validateName(name);
