import * as Sentry from '@sentry/react';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { trackingInstance } from '../modules/1pt';
import { history } from '../store';
import { QuestionnairePage } from './pages/questionnaire';
import { SENTRY_TRACE_ID, XRAY_TRACE_ID } from './sentry';

const eventProcessor = (event: Sentry.Event): Sentry.Event => {
  // Using Sentry.configureScope doesn't reliable run before the event is sent, so
  // we use an event processor instead to manipulate what is sent to Sentry
  if (event.type === 'transaction') {
    try {
      event.contexts!.trace!.data = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Ray URL': `https://us-east-1.console.aws.amazon.com/xray/home?region=us-east-1#/traces/${XRAY_TRACE_ID}`
      };
      event.contexts!.trace!.trace_id = SENTRY_TRACE_ID;
      (event.spans || []).forEach((span) => {
        (span as any).trace_id = SENTRY_TRACE_ID;
      });
    } catch (err) {
      // We don't want to fail if we can't manipulate the event
      Sentry.captureException(err);
    }
  }
  return event;
};

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.DEPLOY_ENV,
    integrations: [new Sentry.BrowserTracing({ idleTimeout: 3000 })],
    tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE || '0.0')
  });
  Sentry.addGlobalEventProcessor(eventProcessor);
  if (trackingInstance.getAnonymousId()) {
    Sentry.setUser({ id: trackingInstance.getAnonymousId() });
  }
}

export const OnboardingRouter = () => {
  const discoveryUrl = 'https://discover.ownup.com/get-started';
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/:questionnaireType/:nodeId?">
          <QuestionnairePage />
        </Route>
        <Route
          path="/"
          exact
          render={() => {
            window.location.assign(discoveryUrl);
            return <></>;
          }}
        />
      </Switch>
    </ConnectedRouter>
  );
};
