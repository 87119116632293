import {
  OwnUpRadioButton,
  OwnUpRadioGroup,
  OwnUpUnderlineLink
} from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { infoModalOpenedAction } from '../../../../store/events/actions';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Feedback } from '../../../shared/typography/feedback';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { ApproximateCreditScoreDataModel } from './data-model';
import { ApproximateCreditScoreHint } from './hint';

export interface ApproximateCreditScoreNodeProps
  extends NodeProperties<ApproximateCreditScoreDataModel> {}

export const ApproximateCreditScoreNodeImpl = ({
  dataModel: {
    read: { hasCoborrower, primaryBorrowerCreditTiers, coborrowerCreditTiers },
    write: { primaryBorrowerApproximateCreditScore, coborrowerApproximateCreditScore }
  },
  onChangeFactory,
  dispatch,
  splitClient,
  questionnaireType,
  ...props
}: ApproximateCreditScoreNodeProps) => {
  const onPrimaryBorrowerCreditScoreChanged = onChangeFactory<number | boolean>(
    'primaryBorrowerApproximateCreditScore',
    props.nodeId
  );
  // The onChange event returns the value as type string, so we need to convert it back to a number
  const primaryBorrowerCreditScoreChangedWrapper = useCallback(
    (_: any, newValue?: string) => {
      onPrimaryBorrowerCreditScoreChanged(Number(newValue), primaryBorrowerApproximateCreditScore);
    },
    [onPrimaryBorrowerCreditScoreChanged, primaryBorrowerApproximateCreditScore]
  );

  const coborrowerCreditScoreChanged = onChangeFactory<number | boolean>(
    'coborrowerApproximateCreditScore',
    props.nodeId
  );
  const coborrowerCreditScoreChangedWrapper = useCallback(
    (_: any, newValue?: string) => {
      if (newValue === 'false') {
        coborrowerCreditScoreChanged(false, coborrowerApproximateCreditScore);
      } else {
        coborrowerCreditScoreChanged(Number(newValue), coborrowerApproximateCreditScore);
      }
    },
    [coborrowerCreditScoreChanged, coborrowerApproximateCreditScore]
  );

  const mappedPrimaryBorrowerCreditScoreOptions = useMemo(
    () =>
      primaryBorrowerCreditTiers.map(({ value, label }) => (
        <InputWrapper key={value} size="half">
          <OwnUpRadioButton value={value.toString()}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [primaryBorrowerCreditTiers]
  );

  const mappedCoborrowerCreditScoreOptions = useMemo(
    () =>
      coborrowerCreditTiers.map(({ value, label }) => (
        <InputWrapper key={value.toString()} size="half">
          <OwnUpRadioButton value={value.toString()}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [coborrowerCreditTiers]
  );

  const clickInfoModal = useCallback(() => {
    dispatch(infoModalOpenedAction({ nodeId: props.nodeId }));
  }, [dispatch, props.nodeId]);

  const CoborrowerCreditScoreQuestion = useMemo(() => {
    if (hasCoborrower) {
      return (
        <React.Fragment>
          <Question hint={<ApproximateCreditScoreHint onClick={clickInfoModal} />}>
            What&apos;s your co-borrower&apos;s estimated credit score?
          </Question>
          <OwnUpRadioGroup
            name="Estimated Credit Scores Coborrower"
            value={coborrowerApproximateCreditScore?.toString()}
            onChange={coborrowerCreditScoreChangedWrapper}
          >
            <InputGroupWrapper>
              {' '}
              <StandaloneErrorWrapper
                predicateResult={radioButtonPredicate(coborrowerApproximateCreditScore)}
              />
              {mappedCoborrowerCreditScoreOptions}
            </InputGroupWrapper>
          </OwnUpRadioGroup>
        </React.Fragment>
      );
    } else {
      return <React.Fragment />;
    }
  }, [
    hasCoborrower,
    coborrowerApproximateCreditScore,
    coborrowerCreditScoreChangedWrapper,
    mappedCoborrowerCreditScoreOptions,
    clickInfoModal
  ]);

  const LowCreditScoreWarning = useMemo(() => {
    if (
      (primaryBorrowerApproximateCreditScore && primaryBorrowerApproximateCreditScore <= 619) ||
      (hasCoborrower && coborrowerApproximateCreditScore && coborrowerApproximateCreditScore <= 619)
    ) {
      return (
        <Feedback>
          For borrowers seeking a mortgage, there&apos;s a low likelihood of approval with a credit
          score below 620. You can learn more about credit score requirements for mortgages&nbsp;
          <OwnUpUnderlineLink
            link="https://www.ownup.com/learn/credit-scores-for-mortgages-what-you-need-to-get-approved"
            target="_blank"
            rel="noreferrer"
          >
            here
          </OwnUpUnderlineLink>
          .
        </Feedback>
      );
    } else {
      return <React.Fragment />;
    }
  }, [hasCoborrower, primaryBorrowerApproximateCreditScore, coborrowerApproximateCreditScore]);

  return (
    <NodeContainer>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>What&apos;s your estimated credit score?</Question>
        <OwnUpRadioGroup
          name="Estimated Credit Scores"
          value={primaryBorrowerApproximateCreditScore?.toString()}
          onChange={primaryBorrowerCreditScoreChangedWrapper}
        >
          <InputGroupWrapper>
            <StandaloneErrorWrapper
              predicateResult={radioButtonPredicate(primaryBorrowerApproximateCreditScore)}
            />
            {mappedPrimaryBorrowerCreditScoreOptions}
          </InputGroupWrapper>
        </OwnUpRadioGroup>
        {CoborrowerCreditScoreQuestion}
        {LowCreditScoreWarning}
      </NodeForm>
    </NodeContainer>
  );
};
