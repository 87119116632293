import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Description } from '../../../shared/typography/description';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { IncomeTypeDataModel } from './data-model';

export interface IncomeTypeProps extends NodeProperties<IncomeTypeDataModel> {}

export const IncomeTypeNodeImpl = ({
  dataModel: {
    write: { incomeType },
    read: { incomeTypeOptions, hasCoborrower }
  },
  onChangeFactory,
  questionnaireType,
  splitClient,
  ...props
}: IncomeTypeProps) => {
  const handleIncomeTypeChange = useCallback(
    (_: any, newValue?: string) =>
      onChangeFactory<string>('incomeType', props.nodeId)(newValue, undefined),
    [onChangeFactory, props.nodeId]
  );

  const incomeOptions = useMemo(
    () =>
      incomeTypeOptions.map(({ label, value }) => (
        <InputWrapper key={label} size="single">
          <OwnUpRadioButton value={value}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [incomeTypeOptions]
  );

  return (
    <NodeContainer>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>
          What is your primary source of income?
          {hasCoborrower ? " (We'll collect your co-borrower's later.)" : ''}
        </Question>
        {!hasCoborrower && <Description>Please only include your own income for now.</Description>}
        <OwnUpRadioGroup name="Income Type" value={incomeType} onChange={handleIncomeTypeChange}>
          <InputGroupWrapper>
            <StandaloneErrorWrapper predicateResult={radioButtonPredicate(incomeType)} />
            {incomeOptions}
          </InputGroupWrapper>
        </OwnUpRadioGroup>
      </NodeForm>
    </NodeContainer>
  );
};
