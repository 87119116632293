import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { DollarInput } from '../../../../components/shared/inputs/dollar-input';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { dollarPredicate, radioButtonPredicate } from '../../../../modules/shared-predicates';
import { DataModel } from '../../../../store/data-models/slice';
import { Loader, NodeContainer } from '../../../shared/container';
import { NodeIndent } from '../../../shared/containers/node-indent';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';

export interface RefiReasonDataModel extends DataModel {
  write: {
    refiReason?: string;
    cashOutAmount?: number;
  };
  read: {
    refiReasons: Array<{ value: string; label: string }>;
  };
}

// Must return either true or false. Undefined is not a valid response.
export const refiReasonPredicate = (dataModel: RefiReasonDataModel) => {
  if (dataModel.write.refiReason === 'CashOut') {
    if (dataModel.write.cashOutAmount !== undefined && dataModel.write.cashOutAmount >= 0) {
      return true;
    }
  } else if (dataModel.write.refiReason) {
    return true;
  }
  return false;
};

export const refiReasonNodeId = 'refi-reason';
export const RefiReasonNodeImpl = ({
  dataModel: {
    write: { cashOutAmount, refiReason },
    read: { refiReasons }
  },
  onChangeFactory,
  questionnaireType,
  splitClient,
  ...props
}: NodeProperties<RefiReasonDataModel>) => {
  const changeWrapperRefiReason = onChangeFactory<string>('refiReason', props.nodeId);
  const changeWrapperCashOutAmount = onChangeFactory<string>('cashOutAmount', props.nodeId);
  const handleReasonChange = useCallback(
    (_: any, newValue?: string) => changeWrapperRefiReason(newValue, undefined),
    [changeWrapperRefiReason]
  );

  const handleCashOutChange = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      changeWrapperCashOutAmount(floatValue?.toString(), undefined);
    },
    [changeWrapperCashOutAmount]
  );

  const reasonOptions = useMemo(
    () =>
      refiReasons.map(({ label, value }) => (
        <InputWrapper key={value} size="single">
          <OwnUpRadioButton value={value}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [refiReasons]
  );

  const cashOutElement = useMemo(() => {
    if (refiReason === 'CashOut') {
      return (
        <NodeIndent>
          <Question>How much cash are you looking to take out?</Question>
          <DollarInput
            id="Cash out amount"
            label="Cash out amount"
            value={cashOutAmount}
            name="Cash out amount"
            decimalScale={0}
            errorText={'Please enter a valid cash out amount.'}
            dollarInputPredicate={dollarPredicate(cashOutAmount)}
            onValueChange={handleCashOutChange}
          />
        </NodeIndent>
      );
    }
    return <React.Fragment />;
  }, [cashOutAmount, handleCashOutChange, refiReason]);

  return (
    <NodeContainer {...props}>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>What&apos;s your main goal with this refinance?</Question>

        <OwnUpRadioGroup name="Refi Reason" value={refiReason} onChange={handleReasonChange}>
          <InputGroupWrapper>
            <StandaloneErrorWrapper predicateResult={radioButtonPredicate(refiReason)} />
            {reasonOptions}
          </InputGroupWrapper>
        </OwnUpRadioGroup>
        {cashOutElement}
      </NodeForm>
    </NodeContainer>
  );
};

export const RefiReasonNode = () => {
  const props = useNodeProperties<RefiReasonDataModel>(refiReasonNodeId, refiReasonPredicate);
  return (
    <Loader>
      <RefiReasonNodeImpl {...props} />
    </Loader>
  );
};
