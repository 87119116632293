import { FINAL_STEP, PROPERTY_INFO } from '../preapproval/node-map';
import { RefiAccountNode, refiAccountNodeId } from './account-node';
import {
  RefiApproximateCreditScoreNode,
  refiApproximateCreditScoreNodeId
} from './approximate-credit-score-node';
import { RefiCoborrowerNode, refiCoborrowerNodeId } from './coborrower-node';
import {
  RefiConfirmContactInfoNode,
  refiConfirmContactInfoNodeId
} from './confirm-contact-info-node';
import {
  RefiConsultationNextStepsNode,
  refiConsultationNextStepsNodeId
} from './consultation-next-steps-node';
import { CurrentLoanNode, currentLoanNodeId } from './current-loan-node';
import { RefiEmailAndPhoneNode, refiEmailAndPhoneNodeId } from './email-and-phone-node';
import {
  RefiInfoNotVerifiedStopNode,
  refiInfoNotVerifiedStopNodeId
} from './info-not-verified-stop-node';
import { LoanBalanceNode, loanBalanceNodeId } from './loan-balance-node';
import { RefiLowLoanStopNode, stopRefiLowLoanNodeId } from './low-loan-stop-node';
import { RefiNextStepsNode, refiNextStepsNodeId } from './next-steps-node';
import { RefiNoIncomeStopNode, stopRefiNoIncomeNodeId } from './no-income-stop-node';
import { RefiOccupancyTypeNode, refiOccupancyTypeNodeId } from './occupancy-type-node';
import {
  RefiIncomeAmountNode,
  refiIncomeAmountNodeId
} from './primary-borrower-income-amount-node';
import { RefiIncomeTypeNode, refiIncomeTypeNodeId } from './primary-borrower-income-type-node';
import { RefiPropertyAddressNode, refiPropertyAddressNodeId } from './property-address-node';
import { RefiPropertyTypeNode, refiPropertyTypeNodeId } from './property-type-node';
import { PropertyValueNode, propertyValueNodeId } from './property-value-node';
import { RefiReasonNode, refiReasonNodeId } from './refi-reason-node';
import { SecondaryMortgageNode, secondaryMortgageNodeId } from './second-and-heloc-node';
import { RefiStateInfoNode, refiStateInfoNodeId } from './state-info-node';
import { RefiStateNode, refiStateNodeId } from './state-node';
import {
  RefiStepUpConfirmCodeNode,
  refiStepUpConfirmCodeNodeId
} from './step-up-confirm-code-node';
import {
  RefiStepUpConfirmPhoneNode,
  refiStepUpConfirmPhoneNodeId
} from './step-up-confirm-phone-node';
import {
  refiVeteransBenefitsConsentId,
  RefiVeteransBenefitsConsentNode
} from './veterans-benefits-consent-node';

/**
 * Mapping of all nodes to elements.
 */
export const refiNodeMap: Record<string, () => JSX.Element> = {
  // Property Info
  [refiStateNodeId]: RefiStateNode,
  [refiStateInfoNodeId]: RefiStateInfoNode,
  [refiPropertyTypeNodeId]: RefiPropertyTypeNode,
  [refiOccupancyTypeNodeId]: RefiOccupancyTypeNode,
  [refiPropertyAddressNodeId]: RefiPropertyAddressNode,
  [loanBalanceNodeId]: LoanBalanceNode,
  [propertyValueNodeId]: PropertyValueNode,
  [currentLoanNodeId]: CurrentLoanNode,
  // -- Refinance Info
  [refiReasonNodeId]: RefiReasonNode,
  [secondaryMortgageNodeId]: SecondaryMortgageNode,
  [refiCoborrowerNodeId]: RefiCoborrowerNode,
  [refiApproximateCreditScoreNodeId]: RefiApproximateCreditScoreNode,
  [refiVeteransBenefitsConsentId]: RefiVeteransBenefitsConsentNode,
  [refiIncomeAmountNodeId]: RefiIncomeAmountNode,
  [refiIncomeTypeNodeId]: RefiIncomeTypeNode,
  // Stop
  [stopRefiLowLoanNodeId]: RefiLowLoanStopNode,
  [stopRefiNoIncomeNodeId]: RefiNoIncomeStopNode,
  [refiInfoNotVerifiedStopNodeId]: RefiInfoNotVerifiedStopNode,
  // Final Step
  [refiEmailAndPhoneNodeId]: RefiEmailAndPhoneNode,
  [refiConfirmContactInfoNodeId]: RefiConfirmContactInfoNode,
  [refiStepUpConfirmPhoneNodeId]: RefiStepUpConfirmPhoneNode,
  [refiStepUpConfirmCodeNodeId]: RefiStepUpConfirmCodeNode,
  [refiConsultationNextStepsNodeId]: RefiConsultationNextStepsNode,
  [refiNextStepsNodeId]: RefiNextStepsNode,
  [refiAccountNodeId]: RefiAccountNode
};

const REFINANCE_INFO = 'Refinance Info';

export const refiSectionTitles = [PROPERTY_INFO, REFINANCE_INFO, FINAL_STEP];

export const refiNodeSectionMapping: Record<string, string> = {
  // Property Info
  [refiPropertyTypeNodeId]: PROPERTY_INFO,
  [refiOccupancyTypeNodeId]: PROPERTY_INFO,
  [refiStateNodeId]: PROPERTY_INFO,
  [refiStateInfoNodeId]: PROPERTY_INFO,
  [propertyValueNodeId]: PROPERTY_INFO,
  [loanBalanceNodeId]: PROPERTY_INFO,
  [currentLoanNodeId]: PROPERTY_INFO,
  [refiPropertyAddressNodeId]: PROPERTY_INFO,

  // Refinance Info
  [refiReasonNodeId]: REFINANCE_INFO,
  [stopRefiLowLoanNodeId]: REFINANCE_INFO,
  [secondaryMortgageNodeId]: REFINANCE_INFO,
  [refiCoborrowerNodeId]: REFINANCE_INFO,
  [refiVeteransBenefitsConsentId]: REFINANCE_INFO,
  [refiIncomeAmountNodeId]: REFINANCE_INFO,
  [refiIncomeTypeNodeId]: REFINANCE_INFO,
  [stopRefiNoIncomeNodeId]: REFINANCE_INFO,
  [refiApproximateCreditScoreNodeId]: REFINANCE_INFO,

  // Final Step
  [refiEmailAndPhoneNodeId]: FINAL_STEP,
  [refiConfirmContactInfoNodeId]: FINAL_STEP,
  [refiInfoNotVerifiedStopNodeId]: FINAL_STEP,
  [refiStepUpConfirmPhoneNodeId]: FINAL_STEP,
  [refiStepUpConfirmCodeNodeId]: FINAL_STEP,
  [refiAccountNodeId]: FINAL_STEP
};
