import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { IncomeAmountDataModel } from '../../common/income-amount-node/data-model';
import { IncomeAmountNodeImpl } from '../../common/income-amount-node/element';
import { incomeAmountPredicate } from '../../common/income-amount-node/predicate';

export const refiIncomeAmountNodeId = 'primary-income-amount';

export const RefiIncomeAmountNode = () => {
  const props = useNodeProperties<IncomeAmountDataModel>(
    refiIncomeAmountNodeId,
    incomeAmountPredicate
  );
  return (
    <Loader>
      <IncomeAmountNodeImpl {...props} />
    </Loader>
  );
};
