import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { DollarInput } from '../../../../components/shared/inputs/dollar-input';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { DataModel } from '../../../../store/data-models/slice';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { SecondaryMortgageDataModel } from './data-model';

export interface SecondaryMortgageFollowUpNodeModel extends DataModel {
  write: {
    secondaryMortgageBalance?: number | undefined;
    secondaryMortgageRolledIn?: string | undefined;
  };
  read: {
    rollInResponses: Array<{ value: string; label: string }>;
  };
}

const secondaryMortgageBalancePredicate = (secondaryMortgageBalance: number | undefined) =>
  secondaryMortgageBalance !== undefined && secondaryMortgageBalance > 0;

const rollInSecondaryMortgageResponsePredicate = (secondaryMortgageRolledIn: string | undefined) =>
  secondaryMortgageRolledIn !== undefined;

export const secondaryMortgageFollowUpPredicate = (
  secondaryMortgageBalance: number | undefined,
  secondaryMortgageRolledIn: string | undefined
) =>
  !!secondaryMortgageBalancePredicate(secondaryMortgageBalance) &&
  !!rollInSecondaryMortgageResponsePredicate(secondaryMortgageRolledIn);

export const SecondaryMortgageFollowUpQuestions = ({
  nodeId,
  dataModel: {
    write: { secondaryMortgageBalance, secondaryMortgageRolledIn },
    read: { rollInResponses }
  },
  onChangeFactory
}: NodeProperties<SecondaryMortgageDataModel>) => {
  const onSecondaryMortgageBalanceChanged = onChangeFactory<number>(
    'secondaryMortgageBalance',
    nodeId
  );
  const onSecondaryMortgageBalanceChangedWrapper = useCallback(
    ({ floatValue }: NumberFormatValues) =>
      onSecondaryMortgageBalanceChanged(floatValue, undefined),
    [onSecondaryMortgageBalanceChanged]
  );
  const onRollInSecondaryMortgageChanged = onChangeFactory<string>(
    'secondaryMortgageRolledIn',
    nodeId
  );
  const onRollInSecondaryMortgageChangedWrapped = useCallback(
    (_: any, newValue?: string) => onRollInSecondaryMortgageChanged(newValue, undefined),
    [onRollInSecondaryMortgageChanged]
  );

  const rollInOptions = useMemo(
    () =>
      rollInResponses.map(({ label, value }) => (
        <InputWrapper key={value} size="single">
          <OwnUpRadioButton value={value}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [rollInResponses]
  );

  return (
    <React.Fragment>
      <Question>What&#39;s the balance on your second mortgage?</Question>
      <DollarInput
        id="Second mortgage balance"
        label="Second mortgage balance"
        value={secondaryMortgageBalance}
        name="Second mortgage balance"
        decimalScale={2}
        errorText={'Please enter a valid loan balance.'}
        dollarInputPredicate={secondaryMortgageBalancePredicate(secondaryMortgageBalance)}
        onValueChange={onSecondaryMortgageBalanceChangedWrapper}
      />
      <Question>Would you like to roll it into your new mortgage?</Question>
      <OwnUpRadioGroup
        name="Roll in secondary mortgage"
        value={secondaryMortgageRolledIn}
        onChange={onRollInSecondaryMortgageChangedWrapped}
      >
        <InputGroupWrapper>
          <StandaloneErrorWrapper
            predicateResult={radioButtonPredicate(secondaryMortgageRolledIn)}
          />
          {rollInOptions}
        </InputGroupWrapper>
      </OwnUpRadioGroup>
    </React.Fragment>
  );
};
