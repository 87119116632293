import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { PropertyTypeDataModel } from '../../common/property-type-node/data-model';
import { PropertyTypeNodeImpl } from '../../common/property-type-node/element';
import { propertyNodePredicate } from '../../common/property-type-node/predicate';

export const preapprovalPropertyTypeNodeId = 'property-type';

export const PreapprovalPropertyTypeNode = () => {
  const props = useNodeProperties<PropertyTypeDataModel>(
    preapprovalPropertyTypeNodeId,
    propertyNodePredicate
  );

  return (
    <Loader>
      <PropertyTypeNodeImpl
        {...props}
        mainText={'What type of property are you looking to purchase?'}
      />
    </Loader>
  );
};
