import { currency } from '@rategravity/frontend/modules/numbers';
export interface RangeDisplay {
  label: string;
  value: number;
}

/**
 * Given an array of range caps, return an array of ranges for display.
 */
export const getDisplayRanges = (rangeCaps: number[]): RangeDisplay[] => {
  const sortedRangeCaps = rangeCaps.sort((a, b) => a - b).filter((number) => number > 0);

  return sortedRangeCaps.reduce((acc, rangeCap, currentIndex) => {
    if (currentIndex === 0) {
      acc.push({ label: `Less than ${currency(rangeCap, 0)}`, value: rangeCap });
    }
    if (currentIndex === sortedRangeCaps.length - 1) {
      acc.push({
        label: `More than ${currency(rangeCap, 0)}`,
        value: rangeCap + 1
      });
    } else {
      acc.push({
        label: `${currency(rangeCap, 0)} - ${currency(sortedRangeCaps[currentIndex + 1], 0)}`,
        value: sortedRangeCaps[currentIndex + 1]
      });
    }

    return acc;
  }, [] as RangeDisplay[]);
};
