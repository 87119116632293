import Slider from '@material-ui/core/Slider';
import { currency } from '@rategravity/frontend/modules/numbers';
import { ALOE_100, OwnUpSubheadlineRegular, SAGE_100 } from '@rategravity/own-up-component-library';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const DEFAULT_VALUE = 300_000;
const DEFAULT_STEP = 50_000;
const DEFAULT_MAX = 1_000_000;

const StyledSlider = styled(Slider)`
  & .MuiSlider-track {
    color: ${ALOE_100};
    height: ${({ theme }) => theme.spacing(1)}px;
    border-radius: ${({ theme }) => theme.spacing(2)}px;
  }
  & .MuiSlider-rail {
    height: ${({ theme }) => theme.spacing(1)}px;
    border-radius: ${({ theme }) => theme.spacing(2)}px;
    color: ${SAGE_100};
    opacity: 1;
  }
  & .MuiSlider-thumb {
    color: ${ALOE_100};
    height: ${({ theme }) => theme.spacing(3)}px;
    width: ${({ theme }) => theme.spacing(3)}px;
    margin-top: -${({ theme }) => theme.spacing(1)}px;
    margin-left: -12px;
  }
`;

export interface SliderProps {
  handleChange: (value: number[]) => void;
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
}

const formatValue = (value: number | number[], min: number, max: number, step: number) => {
  // not supporting ranges on the slider display at this point
  if (!Array.isArray(value)) {
    if (value === min) {
      return `< ${currency(step, 0)}`;
    }
    if (value >= max) {
      return `> ${currency(max, 0)}`;
    }
    return `${currency(value, 0)} - ${currency(value + step, 0)}`;
  }
};

export const SliderComponent = ({
  handleChange,
  defaultValue,
  min = 0,
  max = DEFAULT_MAX,
  step = DEFAULT_STEP
}: SliderProps) => {
  const sliderProps = { min, max, step };
  const [localValue, setLocalValue] = useState<number | number[] | undefined>(
    defaultValue ? defaultValue - step : undefined
  );

  useEffect(() => {
    if (defaultValue === undefined) {
      return handleChange([DEFAULT_VALUE, DEFAULT_VALUE + step]);
    }
  }, [handleChange, defaultValue, step]);

  // update the data model (driven by mouse up or keyboard events on the slider)
  const handleSchemaChange = useCallback(
    (_: any, value: number | number[]) => {
      // not supporting ranges on the slider display at this point
      if (!Array.isArray(value)) {
        if (value === min) {
          return handleChange([min, step - 1]);
        }
        return handleChange([value, value + step]);
      }
    },
    [handleChange, step, min]
  );

  // update the slider display (driven by moving the slider thumb)
  const handleDisplayChange = (_: any, newValue: number | number[]) => setLocalValue(newValue);

  return (
    <>
      <OwnUpSubheadlineRegular style={{ padding: '48px 0 24px' }} variant="h1">
        {formatValue(localValue === 0 ? 0 : localValue || DEFAULT_VALUE, min, max, step)}
      </OwnUpSubheadlineRegular>
      <StyledSlider
        value={localValue}
        defaultValue={defaultValue || DEFAULT_VALUE}
        onChange={handleDisplayChange}
        onChangeCommitted={handleSchemaChange}
        {...sliderProps}
      />
    </>
  );
};
