import { FINAL_STEP, PROPERTY_INFO, PURCHASE_INFO } from '../preapproval/node-map';
import { PurchaseAccountNode, purchaseAccountNodeId } from './account-node';
import {
  PurchaseApproximateCreditScoreNode,
  purchaseApproximateCreditScoreNodeId
} from './approximate-credit-score-node';
import { PurchaseCoborrowerNode, purchaseCoborrowerNodeId } from './coborrower-node';
import {
  PurchaseConfirmContactInfoNode,
  purchaseConfirmContactInfoNodeId
} from './confirm-contact-info-node';
import {
  PurchaseConsultationNextStepsNode,
  purchaseConsultationNextStepsNodeId
} from './consultation-next-steps-node';
import { PurchaseDownPaymentNode, purchaseDownPaymentNodeId } from './down-payment-node';
import { PurchaseEmailAndPhoneNode, purchaseEmailAndPhoneNodeId } from './email-and-phone-node';
import {
  PurchaseInfoNotVerifiedStopNode,
  purchaseInfoNotVerifiedStopNodeId
} from './info-not-verified-stop-node';
import { PurchaseLowLoanStopNode, stopPurchaseLowLoanNodeId } from './low-loan-stop-node';
import { PurchaseNextStepsNode, purchaseNextStepsNodeId } from './next-steps-node';
import { PurchaseNoIncomeStopNode, stopPurchaseNoIncomeNodeId } from './no-income-stop-node';
import { PurchaseOccupancyTypeNode, purchaseOccupancyTypeNodeId } from './occupancy-type-node';
import {
  PurchasePrimaryBorrowerFthbNode,
  purchasePrimaryBorrowerFthbNodeId
} from './primary-borrower-fthb-node';
import {
  PurchaseIncomeAmountNode,
  purchaseIncomeAmountNodeId
} from './primary-borrower-income-amount-node';
import {
  PurchaseIncomeTypeNode,
  purchaseIncomeTypeNodeId
} from './primary-borrower-income-type-node';
import { PurchasePropertyTypeNode, purchasePropertyTypeNodeId } from './property-type-node';
import { PurchasePurchasePriceNode, purchasePurchasePriceNodeId } from './purchase-price-node';
import {
  PurchasePurchaseTimelineNode,
  purchasePurchaseTimelineNodeId
} from './purchase-timeline-node';
import {
  PurchaseRealEstateAgentNode,
  purchaseRealEstateAgentNodeId
} from './real-estate-agent-node';
import { PurchaseStateInfoNode, purchaseStateInfoNodeId } from './state-info-node';
import { PurchaseStateNode, purchaseStateNodeId } from './state-node';
import {
  PurchaseStepUpConfirmCodeNode,
  purchaseStepUpConfirmCodeNodeId
} from './step-up-confirm-code-node';
import {
  PurchaseStepUpConfirmPhoneNode,
  purchaseStepUpConfirmPhoneNodeId
} from './step-up-confirm-phone-node';
import {
  purchaseVeteransBenefitsConsentId,
  PurchaseVeteransBenefitsConsentNode
} from './veterans-benefits-consent-node';

/**
 * Mapping of all nodes to elements.
 */
export const purchaseNodeMap: Record<string, () => JSX.Element> = {
  [purchaseAccountNodeId]: PurchaseAccountNode,
  [purchaseApproximateCreditScoreNodeId]: PurchaseApproximateCreditScoreNode,
  [purchaseStateNodeId]: PurchaseStateNode,
  [purchaseStateInfoNodeId]: PurchaseStateInfoNode,
  [purchaseConsultationNextStepsNodeId]: PurchaseConsultationNextStepsNode,
  [purchaseIncomeAmountNodeId]: PurchaseIncomeAmountNode,
  [purchaseIncomeTypeNodeId]: PurchaseIncomeTypeNode,
  [purchaseDownPaymentNodeId]: PurchaseDownPaymentNode,
  [purchaseNextStepsNodeId]: PurchaseNextStepsNode,
  [purchasePropertyTypeNodeId]: PurchasePropertyTypeNode,
  [purchaseOccupancyTypeNodeId]: PurchaseOccupancyTypeNode,
  [purchasePurchasePriceNodeId]: PurchasePurchasePriceNode,
  [purchasePurchaseTimelineNodeId]: PurchasePurchaseTimelineNode,
  [purchaseRealEstateAgentNodeId]: PurchaseRealEstateAgentNode,
  [purchasePrimaryBorrowerFthbNodeId]: PurchasePrimaryBorrowerFthbNode,
  [stopPurchaseLowLoanNodeId]: PurchaseLowLoanStopNode,
  [stopPurchaseNoIncomeNodeId]: PurchaseNoIncomeStopNode,
  [purchaseEmailAndPhoneNodeId]: PurchaseEmailAndPhoneNode,
  [purchaseConfirmContactInfoNodeId]: PurchaseConfirmContactInfoNode,
  [purchaseInfoNotVerifiedStopNodeId]: PurchaseInfoNotVerifiedStopNode,
  [purchaseStepUpConfirmPhoneNodeId]: PurchaseStepUpConfirmPhoneNode,
  [purchaseStepUpConfirmCodeNodeId]: PurchaseStepUpConfirmCodeNode,
  [purchaseVeteransBenefitsConsentId]: PurchaseVeteransBenefitsConsentNode,
  [purchaseCoborrowerNodeId]: PurchaseCoborrowerNode
};

export const purchaseSectionTitles = [PROPERTY_INFO, PURCHASE_INFO, FINAL_STEP];

/**
 * Mapping of all nodes to section titles. The section title appears on the
 *   node itself and the sidebar.
 */
export const purchaseNodeSectionMapping: Record<string, string> = {
  // Property Info
  [purchasePropertyTypeNodeId]: PROPERTY_INFO,
  [purchaseOccupancyTypeNodeId]: PROPERTY_INFO,
  [purchaseStateNodeId]: PROPERTY_INFO,
  [purchaseStateInfoNodeId]: PROPERTY_INFO,
  [purchaseRealEstateAgentNodeId]: PROPERTY_INFO,

  // Purchase Info
  [purchasePurchaseTimelineNodeId]: PURCHASE_INFO,
  [purchasePrimaryBorrowerFthbNodeId]: PURCHASE_INFO,
  [purchaseCoborrowerNodeId]: PURCHASE_INFO,
  [purchaseVeteransBenefitsConsentId]: PURCHASE_INFO,
  [purchasePurchasePriceNodeId]: PURCHASE_INFO,
  [purchaseDownPaymentNodeId]: PURCHASE_INFO,
  [stopPurchaseLowLoanNodeId]: PURCHASE_INFO,
  [purchaseIncomeAmountNodeId]: PURCHASE_INFO,
  [purchaseIncomeTypeNodeId]: PURCHASE_INFO,
  [stopPurchaseNoIncomeNodeId]: PURCHASE_INFO,
  [purchaseApproximateCreditScoreNodeId]: PURCHASE_INFO,

  // Final Step
  [purchaseEmailAndPhoneNodeId]: FINAL_STEP,
  [purchaseConfirmContactInfoNodeId]: FINAL_STEP,
  [purchaseInfoNotVerifiedStopNodeId]: FINAL_STEP,
  [purchaseStepUpConfirmPhoneNodeId]: FINAL_STEP,
  [purchaseStepUpConfirmCodeNodeId]: FINAL_STEP,
  [purchaseAccountNodeId]: FINAL_STEP
};
