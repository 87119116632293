import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpNavLink
} from '@rategravity/own-up-component-library';
import { ProfileIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/profile';
import { parseFullName } from 'parse-full-name';
import React from 'react';
import styled from 'styled-components';

export interface NavBarUserMenuProps {
  /**
   * User's accountId if logged in. Will be used to
   *   produce a link to the user's account home.
   */
  accountId?: string;
  /**
   * User's name if logged in. Will be shown in the
   *   navbar.
   */
  name?: string;
}

const NavBarMenu = styled.div`
  /* Wrapping this in a new scope helps prevent css collisions */
  & {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

export const NavBarUserMenu = ({ accountId, name }: NavBarUserMenuProps) => {
  if (accountId && name) {
    const ownUpUrl = process.env.MY_OWNUP_URL || 'https://staging.my.ownup.com';
    const accountHome = `${ownUpUrl}/account/${accountId}`;
    // For Mobile
    const first = parseFullName(name, 'first') as string;
    return (
      <React.Fragment>
        <NavBarMenu>
          <OwnUpGridContainer variant="slim">
            <OwnUpGridItem xs={0} sm={12}>
              <NavBarMenu>
                <OwnUpNavLink size="small" href={accountHome} icon={<ProfileIcon />}>
                  {name}
                </OwnUpNavLink>
              </NavBarMenu>
            </OwnUpGridItem>
            <OwnUpGridItem xs={12} sm={0}>
              <NavBarMenu>
                <OwnUpNavLink size="small" href={accountHome} icon={<ProfileIcon />}>
                  {first}
                </OwnUpNavLink>
              </NavBarMenu>
            </OwnUpGridItem>
          </OwnUpGridContainer>
        </NavBarMenu>
      </React.Fragment>
    );
  }
  return <React.Fragment />;
};
