import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { StopNodeImpl } from '../../common/stop-node';
import { StopNodeDataModel } from '../../common/stop-node/helpers';

export const stopRefiNoIncomeNodeId = 'stop-no-income';

export const RefiNoIncomeStopNode = () => {
  const props = useNodeProperties<StopNodeDataModel>(stopRefiNoIncomeNodeId);
  return <StopNodeImpl {...props} />;
};
