import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { NodeContainer } from '../../../shared/container';
import { NodeIndent } from '../../../shared/containers/node-indent';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { FthbDataModel } from './data-model';

const fthbOptions = [
  // 'Yes I have owned a home before' -> isFirstTimeBuyer=false
  { display: 'Yes', value: false },
  { display: 'No', value: true }
].map(({ display, value }) => (
  <InputWrapper key={display} size="single">
    <OwnUpRadioButton value={String(value)}>{display}</OwnUpRadioButton>
  </InputWrapper>
));

interface FthbProps extends NodeProperties<FthbDataModel> {
  firstPerson: boolean;
}

export const FthbNodeImpl = ({
  dataModel: {
    read: { ownOrRentOptions, isPlanningToSellOptions },
    write: { isFirstTimeBuyer, ownOrRent, isPlanningToSell }
  },
  onChangeFactory,
  dispatch,
  firstPerson,
  ...props
}: FthbProps) => {
  const firstTimeBuyerChangeDispatcher = onChangeFactory<boolean>('isFirstTimeBuyer', props.nodeId);
  const ownOrRentDispatcher = onChangeFactory<string>('ownOrRent', props.nodeId);
  const handleIsPlanningToSellDispatcher = onChangeFactory<boolean>(
    'isPlanningToSell',
    props.nodeId
  );
  const handleIsPlanningToSellChange = useCallback(
    (_: any, newValue?: string) => handleIsPlanningToSellDispatcher(newValue === 'true', undefined),
    [handleIsPlanningToSellDispatcher]
  );

  /**
   * When an option is selected that removes any sub-questions, the previous inputs
   *   to the sub-questions will be cleared.
   */
  const handleOwnOrRentChange = useCallback(
    (_: any, newValue?: string) => {
      if (newValue === 'Rent' || !newValue) {
        handleIsPlanningToSellDispatcher(undefined, undefined);
      }
      ownOrRentDispatcher(newValue, undefined);
    },
    [ownOrRentDispatcher, handleIsPlanningToSellDispatcher]
  );

  const handleFirstTimeBuyerChange = useCallback(
    (_: any, newValue?: string) => {
      if (!newValue || newValue === 'false') {
        handleOwnOrRentChange(undefined);
      }
      firstTimeBuyerChangeDispatcher(newValue === 'true', undefined);
    },
    [firstTimeBuyerChangeDispatcher, handleOwnOrRentChange]
  );

  const ownOrRentRadioOptions = useMemo(
    () =>
      ownOrRentOptions.map(({ label, value }) => (
        <InputWrapper key={label} size="single">
          <OwnUpRadioButton value={value}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [ownOrRentOptions]
  );

  const renderOwnOrRentQuestion = useMemo(() => {
    if (isFirstTimeBuyer === false) {
      return (
        <NodeIndent>
          <Question>
            Do {firstPerson ? 'you' : 'they'} own or rent {firstPerson ? 'your' : 'their'} current
            primary residence?
          </Question>
          <OwnUpRadioGroup name="Own or rent" value={ownOrRent} onChange={handleOwnOrRentChange}>
            <InputGroupWrapper>
              <StandaloneErrorWrapper predicateResult={radioButtonPredicate(ownOrRent)} />
              {ownOrRentRadioOptions}
            </InputGroupWrapper>
          </OwnUpRadioGroup>
        </NodeIndent>
      );
    }
    return <React.Fragment />;
  }, [isFirstTimeBuyer, ownOrRent, ownOrRentRadioOptions, handleOwnOrRentChange, firstPerson]);

  const isPlanningToSellRadioOptions = useMemo(
    () =>
      isPlanningToSellOptions.map(({ label, value }) => (
        <InputWrapper key={label} size="single">
          <OwnUpRadioButton value={String(value)}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [isPlanningToSellOptions]
  );

  const isPlanningToSellQuestion = useMemo(() => {
    if (isFirstTimeBuyer === false && ownOrRent === 'Own') {
      return (
        <NodeIndent>
          <Question>Will this residence be sold prior to purchasing the new property?</Question>
          <OwnUpRadioGroup
            name="Is planning to sell"
            value={String(isPlanningToSell)}
            onChange={handleIsPlanningToSellChange}
          >
            <InputGroupWrapper>
              <StandaloneErrorWrapper predicateResult={radioButtonPredicate(isPlanningToSell)} />
              {isPlanningToSellRadioOptions}
            </InputGroupWrapper>
          </OwnUpRadioGroup>
        </NodeIndent>
      );
    }
    return <React.Fragment />;
  }, [
    isFirstTimeBuyer,
    ownOrRent,
    isPlanningToSell,
    handleIsPlanningToSellChange,
    isPlanningToSellRadioOptions
  ]);

  return (
    <NodeContainer {...props}>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>
          {firstPerson ? 'Have you' : 'Has your co-borrower'} ever owned a home before?
        </Question>
        <OwnUpRadioGroup
          name="first time buyer"
          value={String(isFirstTimeBuyer)}
          onChange={handleFirstTimeBuyerChange}
        >
          <InputGroupWrapper>
            <StandaloneErrorWrapper predicateResult={radioButtonPredicate(isFirstTimeBuyer)} />
            {fthbOptions}
          </InputGroupWrapper>
        </OwnUpRadioGroup>
        {renderOwnOrRentQuestion}
        {isPlanningToSellQuestion}
      </NodeForm>
    </NodeContainer>
  );
};
