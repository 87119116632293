import { VeteransBenefitsConsentDataModel } from './data-model';

export const veteransBenefitsConsentPredicate = ({
  read: {},
  write: { borrowerStatus, borrowerPriorVa }
}: VeteransBenefitsConsentDataModel) => {
  // Veteran status not given
  if (borrowerStatus === undefined) {
    return false;
  }

  // Prior va question not answered on an eligble borrower
  if (borrowerStatus === 'Eligible' && borrowerPriorVa === undefined) {
    return false;
  }

  return true;
};
