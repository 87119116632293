import * as t from 'type-shift';

/**
 * The track method lets you record any actions your users perform.
 */
export interface SegmentTrackPayload {
  method: 'track';
  /**
   * The name of the event you’re tracking.
   */
  event: string;
  /**
   * A dictionary of properties for the event. If the event was 'Added to Cart', it might
   *   have properties like price and productType.
   */
  properties?: Record<string, unknown>;
}

/**
 * The identify method is how you associate your users and their actions to a recognizable userId
 *   and traits.
 */
export interface SegmentIdentifyPayload {
  method: 'identify';
  /**
   * The database ID for the user. If you don’t know who the user is yet, you can
   *   omit the userId and just record traits. If there is a 1PT token available,
   *   this value will be overwritten by it.
   */
  userId: string;
  /**
   * A dictionary of traits you know about the user, like their email or name.
   */
  traits?: object;
}
/**
 * The page method lets you record page views on your website, along with optional
 *   extra information about the page being viewed.
 */
export interface SegmentPagePayload {
  method: 'page';
  /**
   * The url being viewed. If not provided, will automatically be derived.
   */
  url?: string;
  /**
   * A dictionary of properties of the page. Note: url, title, referrer and path
   *   are collected automatically, but you can override them if need be.
   *   Additionally this defaults to a canonical url, if available, and falls back
   *   to document.location.href.
   */
  properties?: object;
}

export type SegmentPayload = SegmentTrackPayload | SegmentIdentifyPayload | SegmentPagePayload;

/**
 * Passing in a raw Error will cause redux to fail because it's not
 *   serializable. This type with its `__serialized` decorator should
 *   guard against passing in raw Errors.
 *
 * You can run your error through {@see serializeError} to convert it.
 */
export type SerializableError = Pick<Error, 'name' | 'message' | 'stack'> & {
  __serialized: true;
} & Record<string, unknown>;

const serializedErrorConverter = t.shape<SerializableError>({
  __serialized: () => true,
  name: t.string.default(() => 'Error'),
  message: t.string.default(() => 'Unknown error occurred'),
  stack: t.string.default(() => 'N/A')
});
/**
 * Convert an error into a serializable version of itself.
 */
export const serializeError = (
  err: Error,
  additionalFields: Record<string, unknown> = {}
): SerializableError => {
  return {
    ...additionalFields,
    ...serializedErrorConverter(err)
  };
};
/**
 * Common error payload typing
 */
export interface ErrorPayload {
  error: SerializableError;
}
