import { OwnUpRadioButton, OwnUpRadioGroup } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo } from 'react';
import { radioButtonPredicate } from '../../../../modules/shared-predicates';
import { NodeContainer } from '../../../shared/container';
import { InputGroupWrapper } from '../../../shared/form/inputs/input-group-wrapper';
import { InputWrapper } from '../../../shared/form/inputs/input-wrapper';
import { NodeForm } from '../../../shared/form/node-form';
import { StandaloneErrorWrapper } from '../../../shared/render-standalone-error';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { PurchaseTimelineDataModel } from './data-model';

export const PurchaseTimelineNodeImpl = ({
  dataModel: {
    read: { purchaseTimelineOptions },
    write: { purchaseTimeline }
  },
  onChangeFactory,
  ...props
}: NodeProperties<PurchaseTimelineDataModel>) => {
  const onPurchaseTimelineChangeHandler = onChangeFactory<string>('purchaseTimeline', props.nodeId);
  const onPurchaseTimelineChangeWrapper = useCallback(
    (_: any, newValue?: string) => onPurchaseTimelineChangeHandler(newValue, undefined),
    [onPurchaseTimelineChangeHandler]
  );

  const timelineOptions = useMemo(
    () =>
      purchaseTimelineOptions.map(({ label, value }) => (
        <InputWrapper key={label} size="single">
          <OwnUpRadioButton value={value}>{label}</OwnUpRadioButton>
        </InputWrapper>
      )),
    [purchaseTimelineOptions]
  );

  return (
    <NodeContainer>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>When would you like to purchase your property?</Question>
        <OwnUpRadioGroup
          name="Purchase Timeline"
          value={purchaseTimeline}
          onChange={onPurchaseTimelineChangeWrapper}
        >
          <InputGroupWrapper>
            <StandaloneErrorWrapper predicateResult={radioButtonPredicate(purchaseTimeline)} />
            {timelineOptions}
          </InputGroupWrapper>
        </OwnUpRadioGroup>
      </NodeForm>
    </NodeContainer>
  );
};
