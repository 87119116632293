import { OwnUpBody, OwnUpBodyProps } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

/**
 * Extension of {@see OwnUpBodyProps}
 */
export type HintBodyProps = Omit<OwnUpBodyProps, 'variant'>;

const HintBodyImpl = (props: HintBodyProps) => <OwnUpBody {...props} variant="body1" />;

/**
 * The "HintBody" is the body copy for a hint modal.
 *
 * Figma: https://www.figma.com/file/xOJwjvBP1RFcnViMvWLKsX/Koala-%2F-System-Skin?node-id=8%3A3850
 */
export const HintBody = styled(HintBodyImpl)`
  /* Wrapping this in a new scope helps prevent css collisions */
  & {
    color: #000000;
    margin-bottom: 1.5rem;
  }
`;
