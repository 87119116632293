import { OwnUpTextInput, OwnUpTextInputProps } from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo, useState } from 'react';
import { useClickedSubmitContext } from '../../../hooks/use-clicked-submit-context';
import { InputWrapper, InputWrapperSizes } from '../form/inputs/input-wrapper';

/**
 * Text Input wrapper that supports error states.
 */

interface AdornedTextInputProps extends OwnUpTextInputProps {
  /** Text to be passed as helpertext if there is an error */
  errorText?: string;
  textInputPredicate?: boolean;
  value?: any;
  label: string;
  wrapperSize: InputWrapperSizes;
}

export const AdornedTextInput = ({
  errorText = '',
  textInputPredicate = false,
  ...props
}: AdornedTextInputProps) => {
  const { hasClickedSubmit } = useClickedSubmitContext();

  /**
   *  defaultState = {showError:props.value !== undefined } allows illegal
   *   pre-filled values to trigger the error state by default.
   */
  const [showError, setShowError] = useState(props.value !== undefined && props.value !== null);

  const handleBlur = useCallback(() => {
    if (!showError) {
      setShowError(true);
    }
  }, [showError, setShowError]);

  const adornedTextInputErrorTextMemo = useMemo(() => {
    /**
     * This should be triggered by: 1. a default textInputPredicate = false, e.g. a defaultValue
     *  of 0 in an input that does not allow zeroes. or 2. a wrong user interaction
     *  (tracked by 'onBlur'), e.g interacting with the input but entering nothing or zero.
     */
    return (!textInputPredicate && hasClickedSubmit) || (showError && !textInputPredicate)
      ? errorText
      : undefined;
  }, [showError, textInputPredicate, errorText, hasClickedSubmit]);

  return (
    <InputWrapper size={props.wrapperSize}>
      <OwnUpTextInput
        {...props}
        label={props.label}
        labelPosition="inner"
        helperText={adornedTextInputErrorTextMemo}
        error={!!adornedTextInputErrorTextMemo}
        onBlur={handleBlur}
        value={props.value || ''}
      />
    </InputWrapper>
  );
};
