import React, { useCallback } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { DollarInput } from '../../../../components/shared/inputs/dollar-input';
import { SliderComponent } from '../../../../components/shared/inputs/slider';
import { dollarPredicate } from '../../../../modules/shared-predicates';
import { ONBOARDING_RANGES_TEST } from '../../../../modules/split';
import { NodeContainer } from '../../../shared/container';
import { NodeForm } from '../../../shared/form/node-form';
import { Overline } from '../../../shared/typography/overline';
import { Question } from '../../../shared/typography/question';
import { NodeProperties } from '../../properties';
import { PurchasePriceDataModel } from './data-model';

export interface PurchasePriceNodeProperties extends NodeProperties<PurchasePriceDataModel> {
  headerText?: string;
  sliderOverride?: boolean;
}

export const PurchasePriceNodeImpl = ({
  dataModel: {
    write: { purchasePrice }
  },
  onChangeFactory,
  dispatch,
  splitClient,
  headerText = "What's the purchase price of the property?",
  questionnaireType,
  sliderOverride,
  ...props
}: PurchasePriceNodeProperties) => {
  const { [ONBOARDING_RANGES_TEST]: sliderTreatment } = splitClient.getTreatments(
    [ONBOARDING_RANGES_TEST],
    {
      questionnaireType: questionnaireType || 'undefined'
    }
  );
  const useSliderTreatment = sliderOverride || sliderTreatment === 'on';

  const handleChange = onChangeFactory<number>('purchasePrice', props.nodeId);
  const handlePurchasePriceMinChange = onChangeFactory<number>('purchasePriceMin', props.nodeId);
  const handlePurchasePriceMaxChange = onChangeFactory<number>('purchasePriceMax', props.nodeId);

  const handlePurchasePriceRangeChange = useCallback(
    (value: number[]) => {
      handleChange(value[1], undefined);
      handlePurchasePriceMinChange(value[0], undefined);
      handlePurchasePriceMaxChange(value[1], undefined);
    },
    [handleChange, handlePurchasePriceMinChange, handlePurchasePriceMaxChange]
  );

  const handlePurchasePriceChange = useCallback(
    ({ value }: NumberFormatValues) => {
      const val = value === '' ? undefined : Number(value);
      handleChange(val, undefined);
      // min and max are the same for numeric input (no ranges)
      handlePurchasePriceMinChange(val, undefined);
      handlePurchasePriceMaxChange(val, undefined);
    },
    [handleChange, handlePurchasePriceMinChange, handlePurchasePriceMaxChange]
  );

  const headerTextOverride = useSliderTreatment
    ? `What is the price range of the property you're looking to ${questionnaireType === 'preapproval' ? 'get pre-approved for' : 'purchase'}?`
    : headerText;

  return (
    <NodeContainer>
      <NodeForm {...props}>
        <Overline>{props.sectionTitle}</Overline>
        <Question>{headerTextOverride + ' (Your best estimate is fine.)'}</Question>
        {useSliderTreatment ? (
          <SliderComponent
            handleChange={handlePurchasePriceRangeChange}
            defaultValue={purchasePrice}
          />
        ) : (
          <DollarInput
            id="Purchase price"
            label="Purchase price"
            value={purchasePrice}
            decimalScale={0}
            errorText={'Please enter a purchase price'}
            dollarInputPredicate={dollarPredicate(purchasePrice)}
            onValueChange={handlePurchasePriceChange}
            {...props}
          />
        )}
      </NodeForm>
    </NodeContainer>
  );
};
