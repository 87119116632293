import React from 'react';
import { useNodeProperties } from '../../../../hooks/use-node-properties';
import { Loader } from '../../../shared/container';
import { ApproximateCreditScoreDataModel } from '../../common/approximate-credit-score-node/data-model';
import { ApproximateCreditScoreNodeImpl } from '../../common/approximate-credit-score-node/element';
import { approximateCreditScorePredicate } from '../../common/approximate-credit-score-node/predicate';

export const refiApproximateCreditScoreNodeId = 'approximate-credit-score';

export const RefiApproximateCreditScoreNode = () => {
  const props = useNodeProperties<ApproximateCreditScoreDataModel>(
    refiApproximateCreditScoreNodeId,
    approximateCreditScorePredicate
  );

  return (
    <Loader>
      <ApproximateCreditScoreNodeImpl {...props} />
    </Loader>
  );
};
